import { PropertyValueMap, html, unsafeCSS } from 'lit';
import { property, query } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { customElement, BaseElement } from '../../base-element';
import { Translate } from '../../base-element/mixins/translation-mixin';
import styles from './upload.scss?inline';
import { EntityTypes } from './filepond.types';

export enum UploadType {
  default = 'default',
  success = 'success',
  error = 'error',
}

@customElement('ps-upload')
export class UploadWC extends Translate(BaseElement) {
  static styles = unsafeCSS(styles);

  @query('.c-upload__input') input: HTMLInputElement;

  /**
   * This attribute is used to specify the name of the control.
   */
  @property({ type: String }) name = '';

  /**
   * The file types the file input should accept.
   */
  @property() accept = '';

  /**
   * Set component type.
   */
  @property() type: keyof typeof UploadType = 'default';

  /**
   * The label text.
   */
  @property({ reflect: true }) label = '';

  /**
   * The title text.
   */
  @property({ reflect: true }) text = 'Upload a file here';

  /**
   * The cta text.
   */
  @property({ reflect: true }) cta = '';

  /**
   * The help text.
   */
  @property() helpText = '';

  /** The current value of the input, submitted as a name/value pair with form data. */
  @property({ type: String }) value: string | undefined = '';

  @property({ type: String, attribute: 'value-id' }) valueId:
    | string
    | undefined = undefined;

  @property({ type: Object }) files: FileList | null = null;

  /**
   * A Boolean attribute indicating that an option with a non-empty string value must be selected.
   */
  @property({ type: Boolean, reflect: true }) required = false;

  /**
   * The Boolean attribute, when present, makes the element not mutable, meaning the user can not edit the control.
   */
  @property({ type: Boolean, reflect: true }) readonly = false;

  @property({ type: String, attribute: 'account-id' }) accountId:
    | string
    | undefined = undefined;

  @property({ type: String, attribute: 'entity-id' }) entityId:
    | string
    | undefined = undefined;

  @property({ type: String, attribute: 'entity-type' })
  entityType: EntityTypes | undefined = undefined;

  private uploadFile = (prop?: FormData) => {
    if (!this.accountId) {
      console.error('account-id is not defined');
      return;
    }
    const url =
      this.entityType && this.entityId
        ? `${import.meta.env.FE_ADMIN_URL}/rest/upload-file-picture/${
            this.accountId
          }/${this.entityType}/${this.entityId}`
        : `${import.meta.env.FE_ADMIN_URL}/rest/upload-file/${this.accountId}`;
    fetch(url, {
      method: 'POST',
      body: prop,
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((data) => {
        this.value = data?.url || undefined;
        this.valueId = data?.id || undefined;
        this.emit('change');
      })
      .catch((err) => {
        this.helpText = prop ? 'Error uploading file' : 'Error deleting file';
        console.error('ERROR', err);
      });
  };

  private _handleChange(e: Event) {
    this.files = this.input.files;

    if (this.files && this.files[0]) {
      const attachedFile = this.files[0];
      const formData = new FormData();
      formData.append('file', attachedFile);
      this.uploadFile(formData);
    }
    this.emit('change');
  }

  private _handleDelete(e: Event) {
    this.uploadFile();
  }

  protected firstUpdated(
    _changedProperties: PropertyValueMap<string> | Map<PropertyKey, unknown>
  ): void {
    if (this.value) {
      fetch(new Request(this.value)).then((response) => {
        response.blob().then((myBlob) => {
          const url = URL.createObjectURL(myBlob);
          this.value = url;
        });
      });
    }
  }

  render() {
    return html`
      <div class="c-upload">
        <input
          type="file"
          accept=${this.accept}
          name=${this.name}
          id="upload-input"
          class="c-upload__input"
          @change=${this._handleChange}
          ?disabled=${this.readonly}
        />
        <label
          for="upload-input"
          class=${classMap({
            'c-upload__control': true,
            'c-upload__control--readonly': this.readonly,
            [`c-upload__control--type-${this.type}`]: this.type,
          })}
        >
          ${this.label
            ? html` <p class="c-upload__label">${this.label}</p> `
            : ''}
          <div class="c-upload__container">
            <div class="c-upload__preview">
              <slot name="preview">
                <ps-icon name="arrow-up" size="small" weight="bold"></ps-icon>
              </slot>
            </div>
            <div class="c-upload__content">
              <p class="c-upload__text">${this.text}</p>
              ${this.cta
                ? html` <p class="c-upload__cta">${this.cta}</p> `
                : ''}
            </div>
          </div>
        </label>
        ${this.helpText
          ? html`
              <p
                class=${classMap({
                  'c-upload__help-text': true,
                  'c-upload__help-text--error': this.type === 'error',
                })}
              >
                ${this.type === 'error'
                  ? html`<ps-icon name="warning" size="auto"></ps-icon>`
                  : null}
                <span> ${this.helpText} </span>
              </p>
            `
          : ''}
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-upload': UploadWC;
  }
}
