/* eslint-disable lit/no-native-attributes */
/* eslint-disable max-classes-per-file */
import {
  DirType,
  i18n,
  Language,
  LanguageCodes,
  TranslationKeys,
  TOptions,
  TranslationData,
} from '@pypestream/translations';
import { LitElement, PropertyValues } from 'lit';
import { property } from 'lit/decorators.js';

type Constructor<
  T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TParams extends any[] = any[],
> = new (...params: TParams) => T;

export declare class TranslateInterface {
  dir?: DirType;

  lang?: Language['code'];

  i18nKey?: TranslationKeys;

  i18nOptions?: TOptions;
}

// keep track of the custom element tag names currently loaded
// used for simplified (optimized) mutation observer when lang attribute updates
const customElementTags = new Set();

export const Translate = <T extends Constructor<LitElement>>(
  parentClass: T
) => {
  class TranslateClass extends parentClass {
    static i18nObserverInitialized = false;

    @property() dir: DirType;

    @property() i18nKey: TranslationKeys;

    @property({ attribute: true, reflect: false }) lang: Language['code'];

    // https://www.i18next.com/translation-function/essentials#overview-options
    @property({ attribute: false }) i18nOptions: TOptions;

    currentLang: keyof typeof LanguageCodes;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(...args: any) {
      super(...args);

      // automaticaly keep track of every custom element name in use (for easy element filtering later on)
      if (!customElementTags.has(this.tagName.toLowerCase())) {
        customElementTags.add(this.tagName.toLowerCase());
      }

      if (!TranslateClass.i18nObserverInitialized) {
        this.setupi18nObserver();
      }

      if (!this.lang) {
        this.lang = i18n.language as keyof typeof LanguageCodes;
      }
    }

    async willUpdate(changedProperties: PropertyValues<this>) {
      // we don't always want to have the lang attribute reflected on every component - just the unique ones!
      if (changedProperties.has('lang')) {
        if (
          this.lang &&
          !this.getAttribute('lang') &&
          this.lang !== this.getDocumentLang() &&
          this.lang !== this.getClosestLangAttribute()
        ) {
          this.setAttribute('lang', this.lang);
        }
        await this.reloadLanguage();
        this.rerenderContent();
      }
    }

    // eslint-disable-next-line class-methods-use-this
    setupi18nObserver() {
      TranslateClass.i18nObserverInitialized = true;

      // Callback function to execute when mutations are observed
      const callback: MutationCallback = (mutationsList) => {
        for (const mutation of mutationsList) {
          if (
            mutation.type === 'childList' ||
            (mutation.type === 'attributes' &&
              (mutation.attributeName === 'lang' ||
                mutation.attributeName === 'dir'))
          ) {
            if (mutation.target instanceof HTMLElement) {
              const parentDir = mutation.target.getAttribute('dir');

              const items = [
                ...mutation.target.getElementsByTagName('*'),
              ].filter((item) =>
                customElementTags.has(item.tagName.toLowerCase())
              ) as TranslateClass[];

              items
                .filter((item) => item.i18nKey !== undefined)
                .map((item) => {
                  // eslint-disable-next-line no-param-reassign
                  item.dir = (parentDir || 'ltr') as DirType;
                  // eslint-disable-next-line no-param-reassign
                  item.lang = item.getClosestLangAttribute();
                  return item;
                });
            }
          }
        }
      };

      // 1. mutation to cause rendering if lang attribute gets changed.
      // Skipping callback for i18n.languageChanged event as i18n change language cause dom update which will invoke following mutation.
      if (typeof window !== 'undefined') {
        // Create an observer instance linked to the callback function
        const observer = new MutationObserver(callback);

        // Start observing the target node for configured mutations
        observer.observe(document, {
          attributeFilter: ['lang', 'dir'],
          childList: false,
          subtree: true,
        } satisfies MutationObserverInit);

        /** demonstrates how server-sent data (in this case, a Vite Web Socket event)
         * could be used to asynchronously trigger translated content to re-render when updated */
      }
    }

    // eslint-disable-next-line class-methods-use-this
    isValidLang(
      lang: null | string | keyof typeof LanguageCodes
    ): lang is keyof typeof LanguageCodes {
      return LanguageCodes[lang as keyof typeof LanguageCodes] !== undefined;
    }

    getClosestLangAttribute(): keyof typeof LanguageCodes {
      const results = [
        this.getAttribute('lang'),
        this.closest('[lang]')?.getAttribute('lang'),
        i18n?.language,
        i18n.options?.fallbackLng,
        'en',
      ]
        .map((result) => {
          if (typeof result !== 'string') return false;
          if (this.isValidLang(result)) return result;
          return false;
        })
        .filter(Boolean);

      return results[0] || LanguageCodes.en;
    }

    getDocumentLang(): keyof typeof LanguageCodes {
      const docLang = document.documentElement.getAttribute('lang');

      if (this.isValidLang(docLang)) {
        return docLang;
      }

      return LanguageCodes.en;
    }

    async reloadLanguage(src: string | undefined = undefined) {
      if (i18n?.isInitialized && this.i18nKey) {
        if (this.lang) {
          await i18n?.loadLanguages(this.lang);
        }

        const ns = this.i18nKey.split(':')[0].split('/')[1];
        if (!i18n.hasResourceBundle(this.lang, ns)) {
          await i18n?.reloadResources([this?.lang]);
        }
      }
    }

    // eslint-disable-next-line class-methods-use-this
    public loadTranslations(translations: TranslationData[] | undefined = []) {
      if (!i18n.addResourceBundle) {
        console.error(
          'i18next: instance is not properly initialized or addResourceBundle method is missing.'
        );
        return;
      }

      translations.forEach((translation) => {
        try {
          i18n.addResourceBundle(
            translation.lang,
            translation.namespace,
            translation.translations,
            true, // true - replace existing resources
            true // true - use immediate loading
          );
          console.log(
            `i18next: loaded translations for ${translation.lang} - ${translation.namespace}`
          );
        } catch (e) {
          console.error(
            'Error loading translations for',
            translation.lang,
            translation.namespace,
            e
          );
        }
      });
    }

    rerenderContent() {
      Array.from(this.childNodes).forEach((child) => {
        if (this.i18nKey) {
          const i18nOptions = this.i18nOptions
            ? {
                defaultValue: child,
                ...this.i18nOptions,
              }
            : child;

          const t = i18n.getFixedT(this.lang || i18n.language);

          // keep track of the current language being used to render (better handle nested langs)
          this.currentLang = this.lang || i18n.language;
          // eslint-disable-next-line no-param-reassign
          child.textContent = t(this.i18nKey, i18nOptions);
        }
      });
      this.requestUpdate();
    }
  }
  return TranslateClass as Constructor<TranslateInterface> & T;
};
