import React from 'react';
import { MenuItem, Select, SelectWC, Stack } from '@pypestream/design-system';
import { PackagesSelect } from './packages-select';
import { ActionsMenu } from './actions-menu';
import { GetPackagesByPackageTypeQuery } from '@pypestream/api-services/dist/generated/graphql.urql';

interface TriggerPlaceholderProps {
  packages: NonNullable<
    NonNullable<GetPackagesByPackageTypeQuery['admin_']>['packages']
  >;
  handleChange: (event: React.ChangeEvent<SelectWC>) => void;
  handleAddTrigger: (index: number) => void;
}

export const TriggerPlaceholder: React.FC<TriggerPlaceholderProps> = ({
  packages,
  handleChange,
  handleAddTrigger,
}) => {
  return (
    <Stack>
      <PackagesSelect
        onChange={handleChange}
        packages={packages}
        id="triggers_select"
        className="mr-2"
      />
      <Select
        style={{ flexGrow: 1 }}
        disabled={true}
        id="triggers_select"
      ></Select>
      <ActionsMenu>
        <MenuItem disabled={true} onClick={() => handleAddTrigger(0)}>
          Add Trigger
        </MenuItem>
      </ActionsMenu>
    </Stack>
  );
};
