import { PropertyValueMap, unsafeCSS } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { html } from 'lit/static-html.js';
import { property, state, query } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { live } from 'lit/directives/live.js';
import { FormControlMixin } from '@open-wc/form-control';
import { attach } from '@frsource/autoresize-textarea';
import {
  customElement,
  FormControl,
  NonCheckableFormControl,
  FormControlController,
  watch,
} from '../../base-element';
import { BufferWC } from '../../buffer';
import { Translate } from '../../base-element/mixins/translation-mixin';
import styles from './textarea.scss?inline';

@customElement('ps-textarea')
export class TextareaWC
  extends Translate(FormControlMixin(BufferWC))
  implements NonCheckableFormControl
{
  static styles = unsafeCSS(styles);

  constructor() {
    super();

    this.onInput = super.onInput.bind(this);
    this.updated = super.updated.bind(this);
  }

  // The following properties and methods aren't strictly required,
  // but browser-level form controls provide them. Providing them helps
  // ensure consistency with browser-provided controls.
  get form() {
    return this.internals.form;
  }

  // without this, we don't get native-like form field submit, reset, or per-input-field validation automatically triggered
  private readonly FormControlController = new FormControlController(this);

  @query('.c-textarea__input') input: HTMLTextAreaElement;

  /**
   * @method
   * @return {boolean} Returns true if internals's target element has no validity
   * problems; otherwise, returns false, fires an invalid event at the element, and (if
   * the event isn't canceled) reports the problem to the user.
   */
  reportValidity() {
    // this is how visible form field show up when a form tries to be submitted
    return this.input?.reportValidity();
  }

  /** The name of textarea, submitted as a name/value pair with form data. */
  @property() name = '';

  /** The current value of the textarea, submitted as a name/value pair with form data. */
  @property() value = '';

  /** Placeholder text to show as a hint when textarea is empty. */
  @property({ reflect: true }) placeholder?: string = '';

  /** The number of rows to display. */
  @property({ type: Number }) rows = 2;

  /** Makes the textarea a required field. */
  @property({ type: Boolean, reflect: true }) required = false;

  /** The minimum length of textarea that will be considered valid. */
  @property({ type: Number }) minlength: number;

  /** The maximum length of textarea that will be considered valid. */
  @property({ type: Number }) maxlength: number;

  /** The textarea's visual variant. */
  @property() variant: 'standard' | 'outlined' = 'standard';

  /** Disables the textarea. */
  @property({ type: Boolean, reflect: true }) disabled = false;

  /** Resets the internal textarea value when pressing the escape key. */
  @property({ type: Boolean, reflect: true }) resetOnEscape = false;

  /**
   * The Boolean attribute, when present, makes the element not mutable, meaning the user can not edit the control.
   */
  @property({ type: Boolean, reflect: true }) readonly = false;

  /** The textarea's error state. */
  @property({ type: Boolean }) hasError = false;

  /**
   * Specifies what permission the browser has to provide assistance in filling out form field values. Refer to
   * [this page on MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete) for available values.
   */
  @property() autocomplete: string;

  /** Indicates that the textarea should receive focus on page load. */
  @property({ type: Boolean }) autofocus: boolean;

  /** The textarea's data-testid. */
  @property({ reflect: true }) 'data-testid'?: string = 'wc-textarea';

  @state() invalid = false;

  @state() _initialValue = '';

  @property() validationMode: FormControl['validationMode'] = 'onSubmit';

  // since the data-user-invalid attribute is added when input is invalid + error shown (via our base component's form.ts file),
  // we can bind to this to automatically display the input's error styles
  // @todo: consider refactoring to be a reactive property (what I'm doing with the new validationMode prop)
  @property({ type: Boolean, reflect: true }) 'data-user-invalid' = false;

  handleInvalid() {
    this.emit('invalid');
  }

  /** Checks for validity but does not show the browser's validation message. */
  checkValidity() {
    return this.input?.checkValidity();
  }

  setCustomValidity(message: string) {
    this.input.setCustomValidity(message);
    this.invalid = !this.checkValidity();
  }

  // this is how all form fields with validation errors get triggered when a form tries to be submitted
  firstUpdated(
    changedProperties: PropertyValueMap<unknown> | Map<PropertyKey, unknown>
  ): void {
    super.firstUpdated(changedProperties);
    this.invalid = !this.checkValidity();

    setTimeout(() => {
      attach(this.input);
    }, 0);
  }

  /** without this, some re-renders might not fully reset the component back to it's original state
   * for example, a native form reset button might correctly clear out the field's input value but not clear out computed validation state,
   * resuting in the field's validation error not showing up when expected */
  @watch('value', { waitUntilFirstUpdate: true })
  async handleValueChange() {
    await this.updateComplete;
    this.invalid = !this.checkValidity();
  }

  @watch('value')
  async onValueChange() {
    if (this.internalValue === undefined) {
      this.internalValue = this.value;
    }
  }

  private handleInput() {
    this.value = this.input.value;
    this.invalid = !this.checkValidity();
    // emitting the change event helps React form hooks expecting an input 'oninput' event to work as expected
    this.emit('input');
    this.onInput();
  }

  connectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.connectedCallback();
    this._initialValue = this.value;
  }

  private handleKeyDown(event: KeyboardEvent) {
    const hasModifier =
      event.metaKey || event.ctrlKey || event.shiftKey || event.altKey;

    if (event.key === 'Escape' && this.resetOnEscape) {
      this.reset();
      event.preventDefault();
      event.stopPropagation();
      this.blur();
    }

    // Pressing enter when focused on an input should submit the form like a native input, but we wait a tick before
    // submitting to allow users to cancel the keydown event if they need to
    if (event.key === 'Enter' && !hasModifier) {
      setTimeout(() => {
        //
        // When using an Input Method Editor (IME), pressing enter will cause the form to submit unexpectedly. One way
        // to check for this is to look at event.isComposing, which will be true when the IME is open.
        //
        // See https://github.com/shoelace-style/shoelace/pull/988
        //
        if (!event.defaultPrevented && !event?.isComposing) {
          this.FormControlController.submit();
        }
      }, 1);
    }
  }

  @state() hasFocus = false;

  reset() {
    this.value = this._initialValue;
    this.input.value = this._initialValue;
  }

  private handleChange() {
    this.value = this.input.value;
    // emitting the change event helps React form hooks expecting an input 'onchange' event work as expected
    this.emit('change');
  }

  private handleFocus() {
    this.hasFocus = true;
    this.emit('focus');
  }

  private handleBlur() {
    this.hasFocus = false;
    this._initialValue = this.value;
    this.emit('blur');
  }

  render() {
    return html`
      <div
        class=${classMap({
          'c-textarea': true,
          'c-textarea--error':
            this.hasError ||
            (this['data-user-invalid'] && this.validationMode === 'onChange'),
        })}
      >
        <textarea
          class=${classMap({
            'c-textarea__input': true,
            'c-textarea__input--disabled': this.disabled,
            'c-textarea__input--readonly': this.readonly,
            'c-textarea__input--has-value': !!this.value,
            [`c-textarea__input--variant-${this.variant}`]: this.variant,
          })}
          part="textarea"
          name=${ifDefined(this.name)}
          .value=${live(this.internalValue)}
          minlength=${ifDefined(this.minlength)}
          maxlength=${ifDefined(this.maxlength)}
          placeholder=${ifDefined(this.placeholder)}
          rows=${ifDefined(this.rows)}
          ?disabled=${this.disabled}
          ?required=${this.required}
          ?readonly=${this.readonly}
          title=""
          autocomplete=${ifDefined(this.autocomplete)}
          ?autofocus=${this.autofocus}
          @input=${this.handleInput}
          @invalid=${this.handleInvalid}
          @change=${this.handleChange}
          @focus=${this.handleFocus}
          @keydown=${this.handleKeyDown}
          @blur=${this.handleBlur}
        ></textarea>
        <div class="c-textarea__line"></div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-textarea': TextareaWC;
  }
}
