import * as React from 'react';
import { type ShiftActionsProps } from 'react-querybuilder';
import { IconButton } from '../../../buttons';

export const ShiftActions = (props: ShiftActionsProps) => {
  const { shiftUp, shiftDown, titles, shiftUpDisabled, shiftDownDisabled } =
    props;

  return (
    <>
      <IconButton
        size="small"
        name="chevron-up"
        onClick={shiftUp}
        title={titles?.shiftUp}
        disabled={shiftUpDisabled}
        className="c-query-builder__shift-up-btn"
      />
      <IconButton
        size="small"
        name="chevron-down"
        onClick={shiftDown}
        title={titles?.shiftDown}
        disabled={shiftDownDisabled}
        className="c-query-builder__shift-down-btn"
      />
    </>
  );
};
