import { FC } from 'react';
import { Stack, Icon, Text } from '@pypestream/design-system';

type DomainFieldPropsType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field: any;
  hasError?: boolean;
};

export const DomainField: FC<DomainFieldPropsType> = ({ field, hasError }) => {
  const removeHttps = (url: string) => url.replace(/^(https?:\/\/)/, '');

  return (
    <Stack justifyContent="start" alignItems="center" gutter="xsmall">
      <Text
        size="2xsmall"
        variant={hasError ? 'danger' : 'primary'}
        truncate
        style={{ width: '100%' }}
      >
        {removeHttps(field.url)}
      </Text>
      {hasError && <Icon name="warning" size="small" color="red" />}
    </Stack>
  );
};
