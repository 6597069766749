import { i18n } from './i18n';
import type { TranslationKeys as TranslationKeysPype } from '../generated/i18nTypes-pype';
import type { TranslationKeys as TranslationKeysStudio } from '../generated/i18nTypes-studio';
import type { TranslationKeys as TranslationKeysManager } from '../generated/i18nTypes-manager';
import type { TranslationKeys as TranslationKeysNavigation } from '../generated/i18nTypes-navigation';
import type { TranslationKeys as TranslationKeysDesignSystem } from '../generated/i18nTypes-designSystem';
export enum LanguageScopes {
  studio = 'studio',
  pype = 'pype',
  manager = 'manager',
  navigation = 'navigation',
  designSystem = 'designSystem',
}

type Dates =
  | 'dates:fullDate'
  | 'dates:localisedDate'
  | 'dates:weekDay'
  | 'dates:postedOn';

export type TranslationKeys =
  | TranslationKeysPype
  | TranslationKeysStudio
  | TranslationKeysManager
  | TranslationKeysNavigation
  | TranslationKeysDesignSystem
  | Dates;

export const LanguageCodes = {
  en: 'en',
  ru: 'ru',
  ar: 'ar',
  hi: 'hi',
  zh: 'zh',
  de: 'de',
} as const;

export type Language = {
  code: keyof typeof LanguageCodes;
  nativeName?: string;
  /**
   * Added this property to load dateFns locales dynamically.
   * Something like,
   * `../../../node_modules/date-fns/esm/locale/${selectLanguage?.dateFnsCode || 'en-US'}/index.js`
   * @TODO - Need to fix dynamic loading of date-fns locale
   */
  dateFnsCode?: string;
};

export type DirType = ReturnType<(typeof i18n)['dir']>;

export const AvailableLanguages: Language[] = [
  {
    code: LanguageCodes.en,
    nativeName: 'English',
    dateFnsCode: 'en-US',
  },
  {
    code: LanguageCodes.zh,
    nativeName: 'Chinese',
    dateFnsCode: 'zh-CN',
  },
  {
    code: LanguageCodes.hi,
    nativeName: 'Hindi',
    dateFnsCode: 'hi',
  },
  {
    code: LanguageCodes.ar,
    nativeName: 'Arabic',
    dateFnsCode: 'ar',
  },
  {
    code: LanguageCodes.ru,
    nativeName: 'Russian',
    dateFnsCode: 'ru',
  },
];

export type TransKeyProp = {
  i18nKey?: TranslationKeys;
};

interface Translations {
  [key: string]: string | Record<string, string>;
}

export interface TranslationData {
  lang: string;
  file: string;
  namespace: string;
  translations: Translations;
}
