import { html, unsafeCSS } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { property, query, state } from 'lit/decorators.js';
import { FormControlMixin } from '@open-wc/form-control';
import {
  BaseElement,
  customElement,
  CheckableFormControl,
  FormControl,
  FormControlController,
  watch,
  customErrorValidityState,
  validValidityState,
  valueMissingValidityState,
} from '../../base-element';
import { HasSlotController } from '../../base-element/controllers/has-slot-controller.js';
import { RadioWC } from './radio.wc';
import styles from './radio-group.scss?inline';

/**
 * @summary Radio groups are used to group multiple Radios so they function as a single form control.
 *
 * @event change - Emitted when the radio group's selected value changes.
 * @event input - Emitted when the radio group receives user input.
 * @event invalid - Emitted when the form control has been checked for validity and its constraints aren't satisfied.
 */
@customElement('ps-radio-group')
export class RadioGroupWC
  extends FormControlMixin(BaseElement)
  implements CheckableFormControl
{
  static styles = unsafeCSS(styles);

  // The following properties and methods aren't strictly required,
  // but browser-level form controls provide them. Providing them helps
  // ensure consistency with browser-provided controls.
  getForm(): HTMLFormElement | null {
    return this.FormControlController.getForm();
  }

  // without this, we don't get native-like form field submit, reset, or per-input-field validation automatically triggered
  private readonly FormControlController = new FormControlController(this);

  private readonly hasSlotController = new HasSlotController(
    this,
    'help-text',
    'label'
  );

  private customValidityMessage = '';

  private validationTimeout: number;

  @query('slot:not([name])') defaultSlot: HTMLSlotElement;

  @query('.c-radio-group__validation-input') validationInput: HTMLInputElement;

  @state() private errorMessage = '';

  @state() defaultValue = '';

  @state() invalid = false;

  /**
   * The radio group's label. Required for proper accessibility. If you need to display HTML, use the `label` slot
   * instead.
   */
  @property() label = '';

  /** The radio groups's help text. If you need to display HTML, use the `help-text` slot instead. */
  @property({ attribute: 'help-text' }) helpText = '';

  /** The name of the radio group, submitted as a name/value pair with form data. */
  @property() name = 'option';

  /** The current value of the radio group, submitted as a name/value pair with form data. */
  @property({ reflect: true }) value = '';

  /** The radio group's size. This size will be applied to all child radios. */
  @property({ reflect: true }) size: 'small' | 'medium' | 'large' = 'medium';

  /** Ensures a child radio is checked before allowing the containing form to submit. */
  @property({ type: Boolean, reflect: true }) required = false;

  /** The radio-group's error state. */
  @property({ type: Boolean, attribute: 'has-error' }) hasError = false;

  @property() validationMode: FormControl['validationMode'] = 'onSubmit';

  /** Gets the validity state object */
  get validity() {
    const isRequiredAndEmpty = this.required && !this.value;
    const hasCustomValidityMessage = this.customValidityMessage !== '';

    if (hasCustomValidityMessage) {
      return customErrorValidityState;
    }

    if (isRequiredAndEmpty) {
      return valueMissingValidityState;
    }

    return validValidityState;
  }

  /** Gets the validation message */
  get validationMessage() {
    const isRequiredAndEmpty = this.required && !this.value;
    const hasCustomValidityMessage = this.customValidityMessage !== '';

    if (hasCustomValidityMessage) {
      return this.customValidityMessage;
    }

    if (isRequiredAndEmpty) {
      return this.validationInput.validationMessage;
    }

    return '';
  }

  connectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.connectedCallback();
    this.defaultValue = this.value;
  }

  firstUpdated() {
    this.FormControlController.updateValidity();
  }

  private getAllRadios() {
    return [...this.querySelectorAll<RadioWC>('ps-radio')];
  }

  private handleRadioClick(event: MouseEvent) {
    const target = (event.target as HTMLElement).closest<RadioWC>('ps-radio')!;
    const radios = this.getAllRadios();
    const oldValue = this.value;

    if (!target || target.disabled) {
      return;
    }

    this.value = target.value;
    // eslint-disable-next-line no-return-assign, no-param-reassign
    radios.forEach((radio) => (radio.checked = radio === target));

    if (this.value !== oldValue) {
      this.emit('change');
      this.emit('input');
    }
  }

  private handleKeyDown(event: KeyboardEvent) {
    if (
      !['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', ' '].includes(
        event.key
      )
    ) {
      return;
    }

    const radios = this.getAllRadios().filter((radio) => !radio.disabled);
    const checkedRadio = radios.find((radio) => radio.checked) ?? radios[0];
    const incr =
      // eslint-disable-next-line no-nested-ternary
      event.key === ' '
        ? 0
        : ['ArrowUp', 'ArrowLeft'].includes(event.key)
          ? -1
          : 1;
    const oldValue = this.value;
    let index = radios.indexOf(checkedRadio) + incr;

    if (index < 0) {
      index = radios.length - 1;
    }

    if (index > radios.length - 1) {
      index = 0;
    }

    this.getAllRadios().forEach((radio) => {
      // eslint-disable-next-line no-param-reassign
      radio.checked = false;
      radio.setAttribute('tabindex', '-1');
    });

    this.value = radios[index].value;
    radios[index].checked = true;

    radios[index].setAttribute('tabindex', '0');
    radios[index].focus();

    if (this.value !== oldValue) {
      this.emit('change');
      this.emit('input');
    }

    event.preventDefault();
  }

  private handleLabelClick() {
    const radios = this.getAllRadios();
    const checked = radios.find((radio) => radio.checked);
    const radioToFocus = checked || radios[0];

    // Move focus to the checked radio (or the first one if none are checked) when clicking the label
    if (radioToFocus) {
      radioToFocus.focus();
    }
  }

  private handleInvalid(event: Event) {
    this.FormControlController.setValidity(false);
    this.FormControlController.emitInvalidEvent(event);
  }

  private async syncRadioElements() {
    const radios = this.getAllRadios();

    await Promise.all(
      // Sync the checked state and size
      radios.map(async (radio) => {
        await radio.updateComplete;
        // eslint-disable-next-line no-param-reassign
        radio.checked = radio.value === this.value;
        // eslint-disable-next-line no-param-reassign
        radio.size = this.size;
      })
    );

    if (radios.length > 0 && !radios.some((radio) => radio.checked)) {
      radios[0].setAttribute('tabindex', '0');
    }
  }

  private syncRadios() {
    if (customElements.get('ps-radio')) {
      this.syncRadioElements();
    } else {
      customElements.whenDefined('ps-radio').then(() => this.syncRadios());
    }
  }

  private updateCheckedRadio() {
    const radios = this.getAllRadios();
    // eslint-disable-next-line no-return-assign, no-param-reassign
    radios.forEach((radio) => (radio.checked = radio.value === this.value));
    this.FormControlController.setValidity(this.validity.valid);
  }

  @watch('size', { waitUntilFirstUpdate: true })
  handleSizeChange() {
    this.syncRadios();
  }

  @watch('value')
  handleValueChange() {
    if (this.hasUpdated) {
      this.updateCheckedRadio();
    }
  }

  /** Checks for validity but does not show a validation message. Returns `true` when valid and `false` when invalid. */
  checkValidity() {
    const isRequiredAndEmpty = this.required && !this.value;
    const hasCustomValidityMessage = this.customValidityMessage !== '';

    if (isRequiredAndEmpty || hasCustomValidityMessage) {
      this.FormControlController.emitInvalidEvent();
      return false;
    }

    return true;
  }

  /** Checks for validity and shows the browser's validation message if the control is invalid. */
  reportValidity(): boolean {
    const isValid = this.validity?.valid;

    this.errorMessage =
      this.customValidityMessage || isValid
        ? ''
        : this.validationInput.validationMessage;
    this.FormControlController.setValidity(isValid);
    this.validationInput.hidden = true;
    clearTimeout(this.validationTimeout);

    if (!isValid) {
      // Show the browser's constraint validation message
      this.validationInput.hidden = false;
      this.validationInput?.reportValidity();
      this.validationTimeout = setTimeout(
        // eslint-disable-next-line no-return-assign
        () => (this.validationInput.hidden = true),
        // Not sure why value of 10000 is needed here, keeping it same as Shoelace does
        10000
      ) as unknown as number;
    }

    return isValid;
  }

  /** Sets a custom validation message. Pass an empty string to restore validity. */
  setCustomValidity(message = '') {
    this.customValidityMessage = message;
    this.errorMessage = message;
    this.validationInput.setCustomValidity(message);
    this.FormControlController.updateValidity();
  }

  render() {
    const hasLabelSlot = this.hasSlotController.test('label');
    const hasHelpTextSlot = this.hasSlotController.test('help-text');
    const hasLabel = this.label ? true : !!hasLabelSlot;
    const hasHelpText = this.helpText ? true : !!hasHelpTextSlot;

    return html`
      <fieldset
        class=${classMap({
          'c-radio-group': true,
          'c-radio-group--has-error': this.hasError,
        })}
        role="radiogroup"
        aria-labelledby="label"
        aria-describedby="help-text"
      >
        ${hasLabel
          ? html`
              <label
                id="label"
                class="c-radio-group__label"
                aria-hidden=${hasLabel ? 'false' : 'true'}
                @click=${this.handleLabelClick}
              >
                <slot name="label">${this.label}</slot>
              </label>
            `
          : ''}

        <div class="radio-group__validation">
          <label>
            <input
              type="text"
              class="c-radio-group__validation-input"
              ?required=${this.required}
              tabindex="-1"
              hidden
              @invalid=${this.handleInvalid}
            />
          </label>
        </div>

        <div
          class=${classMap({
            'c-radio-group__list': true,
            [`c-radio-group__list--size-${this.size}`]: this.size,
          })}
        >
          <slot
            @slotchange=${this.syncRadios}
            @click=${this.handleRadioClick}
            @keydown=${this.handleKeyDown}
          ></slot>
        </div>

        ${hasHelpText
          ? html`
              <div id="help-text" class="c-radio-group__help-text">
                ${this.hasError
                  ? html`<ps-icon name="warning" size="auto"></ps-icon>`
                  : null}
                ${!this.hasError && this.helpText
                  ? html`<ps-icon name="info" size="auto"></ps-icon>`
                  : null}
                <slot name="help-text">${this.helpText}</slot>
              </div>
            `
          : ''}
      </fieldset>
    `;
  }
}
