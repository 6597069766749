/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-use-before-define */
import { Machine, interpret, assign, Sender } from 'xstate';
import { inspect } from '@xstate/inspect';
import { sort, transformTimezonesData } from '@pypestream/utils';
import { ProductName, UserStatus } from '@pypestream/api-services/urql';
import { compact, uniq } from 'lodash';
import { initialize } from 'launchdarkly-js-client-sdk';

import {
  AvailableFeatureFlags,
  FeatureFlagType,
} from '../../feature-flags/feature-flags.types';
import {
  DefaultFeatureFlags,
  WindowWithPypestreamGlobals,
} from '../../feature-flags/feature-flags.default';
import { urqlClient } from '../urql-client';
import {
  SmartContext,
  SmartState,
  SmartEvents,
  SmartTypestates,
  Language,
  Country,
  Timezone,
} from './smart.xstate-utils';
import { getCommonProjects, hasErrors } from '../utils';
import { CommonProject } from '../universal-nav/types';

const win = window as WindowWithPypestreamGlobals;

const isValidFlag = (flagKey: any): boolean => {
  const isValid = flagKey in DefaultFeatureFlags;

  if (!isValid) {
    // @todo - Need to log this with datadog once available.
    // Also, do we need to log app version too?
    console.log('Unhandled feature flag :- ', flagKey);
  }
  return isValid;
};

// @todo: update to support overrides (for debugging) via external super admin controls
const enableStateInspector = false;
if (enableStateInspector) {
  inspect({
    url: 'https://stately.ai/viz?inspect=1',
    iframe: false,
  });
}

const entryLogsAvalable = false;

const logOnEntryState = (stateName: string, info?: unknown) => {
  if (!entryLogsAvalable) return;
  console.log(
    `%csmart-state: ${stateName}`,
    'color: purple; font-size: larger; font-weight: bold',
    info || ''
  );
};

export const smartMachine = Machine<SmartContext, SmartState, SmartEvents>(
  {
    predictableActionArguments: true,
    preserveActionOrder: true,
    id: 'smart-components',
    context: {
      app: undefined,
      accountId: undefined,
      userInfo: undefined,
      userSettings: {},
      projects: undefined,
      commonProjects: undefined,
      userProjects: undefined,
      allProducts: undefined,
      userProductRoles: undefined,
      hasAvailableTools: undefined,
      languages: [],
      countries: [],
      timezones: [],
      featureFlags: undefined,
    },
    type: 'parallel',
    invoke: [
      {
        id: 'initializing-feature-flags',
        src: (ctx) => async (sendEvent: Sender<SmartEvents>) => {
          if (ctx.featureFlags) {
            return Promise.resolve();
          }

          if (Object.keys(DefaultFeatureFlags).length) {
            const ldApiKey =
              win.LAUNCH_DARKLY_API_KEY || LAUNCH_DARKLY_API_KEY || '';
            const client = initialize(
              ldApiKey,
              {
                kind: 'user',
                anonymous: true,
              },
              {
                // This is to prevent sending many events from app to launchDarkly.
                // @todo - Need to check how elegantly we can handle this as w.r.t. launch darkly docs, this might break some features on LD side. (features like, reports, ld logs and all) --> not good for AB testing.
                flushInterval: 900000,
              }
            );

            client.on('change', (updatedFlags) => {
              Object.keys(updatedFlags).forEach((updatedFlagKey) => {
                if (isValidFlag(updatedFlagKey)) {
                  sendEvent({
                    type: 'featureFlags.update',
                    // isValidFlag check is making sure that this typecast is correct
                    // @todo - Ideally we should not need this typecast, need to check for proper fix
                    updatedFlag: updatedFlagKey as AvailableFeatureFlags,
                    updatedValue: updatedFlags[updatedFlagKey].current,
                  });
                }
              });
            });

            return client
              .waitForInitialization()
              .then(() => {
                sendEvent({
                  type: 'featureFlags.initialized',
                  flags: Object.fromEntries(
                    Object.entries(client.allFlags()).filter(([key]) =>
                      isValidFlag(key)
                    )
                  ) as FeatureFlagType,
                });
              })
              .catch(() => {
                sendEvent({
                  type: 'featureFlags.initializedWithDefaultValues',
                });
                // @todo - Need to log this with datadog once available.
                console.log(
                  'Initialized application with build-time/default-flags values in smart-context.'
                );
              });
          }
          // @todo - Need to log this with datadog
          console.warn(
            "Bypassed LD-initialization as we didn't had FFs build time."
          );
          return Promise.resolve();
        },
      },
    ],
    on: {
      updateUserInfo: {
        actions: assign((_ctx, event) => {
          const { userInfo } = event;

          const newContext: SmartContext = {
            ..._ctx,
            userInfo: {
              ..._ctx.userInfo,
              ...userInfo,
              status: userInfo.status || UserStatus.Invited,
              email: _ctx.userInfo?.email || '',
              defaultAccount: {
                ..._ctx.userInfo?.defaultAccount,
                id: userInfo.accountId,
              },
            },
          };

          return newContext;
        }),
      },
      changeApp: {
        actions: assign((ctx, event) => ({
          ...ctx,
          app: event.app,
        })),
      },
      changeOrg: [
        {
          cond: (ctx, event) => !ctx.userInfo,
          actions: assign((ctx, event) => ({
            ...ctx,
            accountId: event.org,
            projects: undefined,
            userProjects: undefined,
            userProductRoles: undefined,
            commonProjects: undefined,
            hasAvailableTools: true,
          })),
          target: 'userInfo.loading',
        },
        {
          cond: (ctx, event) => !!ctx.userInfo,
          actions: assign((ctx, event) => ({
            ...ctx,
            accountId: event.org,
            projects: undefined,
            userProjects: undefined,
            userProductRoles: undefined,
            commonProjects: undefined,
            hasAvailableTools: true,
          })),
          target: ['#projects.loading', '#userProductRoles.loading'],
        },
      ],
      readyForCommonProjects: {
        target: 'commonProjects.loading',
      },
      logout: {
        target: 'logout.loading',
      },
      setManagerToolClick: {
        actions: assign((ctx, event) => ({
          ...ctx,
          onManagerToolClick: event.func,
        })),
      },
      resetHasAvailableTools: {
        actions: assign((ctx) => ({
          ...ctx,
          hasAvailableTools: undefined,
        })),
      },
    },
    states: {
      featureFlags: {
        initial: 'idle',
        states: {
          idle: {
            entry: (ctx, event) =>
              logOnEntryState('featureFlags_idle', { ctx, event }),
            on: {
              'featureFlags.initialized': {
                target: '#smart-components.featureFlags.loaded.withActualValue',
                actions: [
                  assign((ctx, data) => {
                    ctx.featureFlags = data.flags;
                    return ctx;
                  }),
                ],
              },
              'featureFlags.initializedWithDefaultValues': {
                target:
                  '#smart-components.featureFlags.loaded.withDefaultValues',
                actions: [
                  assign((ctx) => {
                    ctx.featureFlags = DefaultFeatureFlags;
                    return ctx;
                  }),
                ],
              },
            },
          },
          loaded: {
            entry: (ctx, event) =>
              logOnEntryState('featureFlags_loaded', { ctx, event }),
            on: {
              'featureFlags.update': {
                actions: assign((ctx, data) => {
                  let updatedContext = ctx;
                  if (
                    ctx.featureFlags &&
                    data.updatedFlag in DefaultFeatureFlags
                  ) {
                    updatedContext = {
                      ...ctx,
                      featureFlags: {
                        ...ctx.featureFlags,
                        [data.updatedFlag]: data.updatedValue,
                      },
                    };
                  }
                  return updatedContext;
                }),
              },
            },
            states: {
              withActualValue: {
                entry: (ctx, event) =>
                  logOnEntryState('featureFlags_withActualValue', {
                    ctx,
                    event,
                  }),
              },
              withDefaultValues: {
                entry: (ctx, event) =>
                  logOnEntryState('featureFlags_withDefaultValues', {
                    ctx,
                    event,
                  }),
              },
            },
          },
        },
      },
      userInfo: {
        initial: 'loading',
        states: {
          idle: {
            entry: (ctx, event) =>
              logOnEntryState('userInfo_idle', { ctx, event }),
            always: {
              target: 'loading',
            },
          },
          loading: {
            entry: (ctx, event) =>
              logOnEntryState('userInfo_loading', { ctx, event }),
            invoke: {
              id: 'getUserInfo',
              src: async (ctx) => {
                const { data: user, error: userInfoError } = await (
                  await urqlClient()
                ).getUserInfoLimited();

                if (hasErrors(user)) {
                  throw new Error(user.errors?.[0]?.message);
                }

                if (userInfoError) {
                  throw new Error(
                    `Failed to fetch user info: ${userInfoError.message}`
                  );
                }

                const userId = user?.admin_?.currentUser?.id;
                const email = user?.admin_?.currentUser?.email;
                const projectIdsAssignedThroughTeams = compact(
                  user?.admin_?.currentUser?.assignedTeams
                    ?.map(({ assignedProjects }) =>
                      assignedProjects?.map(
                        (assignedProject) => assignedProject.id
                      )
                    )
                    .flat()
                );

                if (!userId || !email) {
                  throw new Error(
                    `No user id found: ${userId} or email: ${email}`
                  );
                }

                const { data: userInfo, error: userSettingsError } = await (
                  await urqlClient()
                ).getUserSettingsLimited({ email });

                if (hasErrors(userInfo)) {
                  throw new Error(userInfo.errors?.[0]?.message);
                }

                if (userSettingsError) {
                  throw new Error(
                    `Failed to fetch user settings: ${userSettingsError.message}`
                  );
                }

                return {
                  userInfo: user?.admin_?.currentUser,
                  userSettings: userInfo?.admin_,
                  userProjects: projectIdsAssignedThroughTeams,
                  defaultAccountId:
                    user?.admin_?.currentUser?.defaultAccount?.id,
                };
              },
              onDone: {
                target: [
                  'loaded',
                  '#projects.loading',
                  '#userProductRoles.loading',
                ],
                actions: assign((ctx, event) => {
                  const {
                    userInfo,
                    userSettings,
                    userProjects,
                    defaultAccountId,
                  } = event.data;

                  return {
                    ...ctx,
                    userInfo,
                    userSettings,
                    userProjects: uniq([
                      ...(ctx.userProjects || []),
                      ...userProjects,
                    ]),
                    ...(!ctx.accountId && {
                      accountId: defaultAccountId,
                    }),
                  };
                }),
              },
              onError: {
                target: 'loadError',
              },
            },
          },
          loaded: {
            entry: (ctx, event) =>
              logOnEntryState('userInfo_loaded', { ctx, event }),
            on: {
              updateUser: 'updating',
              loadUser: 'loading',
            },
          },
          loadError: {
            entry: (ctx, event) =>
              logOnEntryState('userInfo_loadError', { ctx, event }),
          },
          updating: {
            entry: (ctx, event) =>
              logOnEntryState('userInfo_updating', { ctx, event }),
            id: 'updatingUser',
            invoke: {
              src: async (
                ctx,
                event
              ): Promise<void | {
                callback: ((res: boolean) => void) | undefined;
                ctx: SmartContext;
              }> => {
                if (event.type !== 'updateUser') {
                  return {
                    callback: undefined,
                    ctx,
                  };
                }

                const { userInfo } = event;
                const { data, error: userUpdateError } = await (
                  await urqlClient()
                ).updateUserLimited(userInfo);

                if (hasErrors(data)) {
                  console.error(data.errors?.[0]?.message);
                  throw new Error(data.errors?.[0]?.message);
                }

                if (userUpdateError) {
                  console.error(userUpdateError);
                  throw new Error(
                    `Failed to update user: ${userUpdateError.message}`
                  );
                }

                const newContext: SmartContext = {
                  ...ctx,
                  userInfo: {
                    ...ctx.userInfo,
                    ...userInfo,
                    status: userInfo.status || UserStatus.Active,
                    email: ctx.userInfo?.email || '',
                    defaultAccount: {
                      ...ctx.userInfo?.defaultAccount,
                      id: userInfo.accountId,
                    },
                  },
                };

                return { callback: event.callback, ctx: newContext };
              },
              onDone: {
                target: 'loaded',
                actions: assign((_ctx, event) => {
                  const { callback, ctx } = event.data;
                  callback(true);
                  return ctx;
                }),
              },
              onError: {
                target: 'loaded',
              },
            },
          },
        },
      },
      projects: {
        id: 'projects',
        initial: 'idle',
        states: {
          idle: {
            entry: (ctx, event) =>
              logOnEntryState('projects_idle', { ctx, event }),
            always: {
              target: 'loading',
              cond: (context) =>
                context.userInfo?.id !== undefined &&
                context.accountId !== undefined,
            },
          },
          loading: {
            entry: (ctx, event) =>
              logOnEntryState('projects_loading', { ctx, event }),
            invoke: {
              id: 'getProjects',
              src: async (ctx) => {
                const userId = ctx.userInfo?.id;
                const { accountId } = ctx;

                if (userId && accountId) {
                  const { data: projects, error: projectsError } = await (
                    await urqlClient()
                  ).getProjectsLimited({
                    userId,
                    accountId,
                  });

                  if (projectsError) {
                    throw new Error(
                      `Failed to fetch projects: ${projectsError.message}`
                    );
                  }

                  return {
                    projects: projects?.admin_?.projects?.rows,
                    userProjects: compact(
                      projects?.admin_?.userProjects?.rows?.map(
                        ({ project }) => project?.id
                      )
                    ),
                  };
                }

                return {
                  projects: [],
                  userProjects: [],
                };
              },
              onDone: {
                target: 'loaded',
                actions: assign((ctx, event) => {
                  const { projects, userProjects } = event.data;

                  return {
                    ...ctx,
                    projects,
                    userProjects: uniq([
                      ...(ctx.userProjects || []),
                      ...userProjects,
                    ]),
                  };
                }),
              },
              onError: {
                target: 'loadError',
              },
            },
          },
          loaded: {
            entry: (ctx, event) =>
              logOnEntryState('projects_loaded', { ctx, event }),
            after: {
              0: {
                actions: 'checkIfReadyForCommonProjects',
              },
            },
          },
          loadError: {
            entry: (ctx, event) =>
              logOnEntryState('projects_loadError', { ctx, event }),
          },
        },
      },
      products: {
        initial: 'idle',
        states: {
          idle: {
            entry: (ctx, event) =>
              logOnEntryState('products_idle', { ctx, event }),
            always: {
              target: 'loading',
              cond: (context) => context.userInfo?.id !== undefined,
            },
          },
          loading: {
            entry: (ctx, event) =>
              logOnEntryState('products_loading', { ctx, event }),
            id: 'productsLoading',
            invoke: {
              id: 'getProducts',
              src: async () => {
                const { data: products, error: productsError } = await (
                  await urqlClient()
                ).getProductsLimited();

                if (productsError) {
                  throw new Error(
                    `Failed to fetch products: ${productsError.message}`
                  );
                }

                return {
                  products: products?.admin_?.allToolProducts,
                };
              },
              onDone: {
                target: 'loaded',
                actions: assign((ctx, event) => {
                  const { products } = event.data;

                  return {
                    ...ctx,
                    allProducts: products,
                  };
                }),
              },
              onError: {
                target: 'loadError',
              },
            },
          },
          loaded: {
            entry: (ctx, event) =>
              logOnEntryState('products_loaded', { ctx, event }),
          },
          loadError: {
            entry: (ctx, event) =>
              logOnEntryState('products_loadError', { ctx, event }),
          },
        },
      },
      userProductRoles: {
        id: 'userProductRoles',
        initial: 'idle',
        states: {
          idle: {
            entry: (ctx, event) =>
              logOnEntryState('userProductRoles_idle', { ctx, event }),
            always: {
              target: 'loading',
              cond: (context) =>
                context.userInfo?.id !== undefined &&
                context.accountId !== undefined,
            },
          },
          loading: {
            entry: (ctx, event) =>
              logOnEntryState('userProductRoles_loading', { ctx, event }),
            invoke: {
              id: 'getUserProductRoles',
              src: async (ctx) => {
                const userId = ctx.userInfo?.id;
                const defaultAccountId = ctx.userInfo?.defaultAccount?.id;
                const { accountId: accId } = ctx;

                const accountId = defaultAccountId || accId;

                if (userId && accountId) {
                  const { data: productRoles, error: productRolesError } =
                    await (
                      await urqlClient()
                    ).getUserProductRolesLimited({
                      userId,
                      accountId,
                    });

                  if (productRolesError) {
                    throw new Error(
                      `Failed to fetch user product roles: ${productRolesError.message}`
                    );
                  }

                  return {
                    userProductRoles: productRoles?.admin_?.userProductRoles,
                  };
                }

                return {
                  userProductRoles: {},
                };
              },
              onDone: {
                target: 'loaded',
                actions: assign((ctx, event) => {
                  const { userProductRoles } = event.data;

                  return {
                    ...ctx,
                    userProductRoles,
                  };
                }),
              },
              onError: {
                target: 'loadError',
              },
            },
          },
          loaded: {
            entry: (ctx, event) =>
              logOnEntryState('userProductRoles_loaded', { ctx, event }),
            after: {
              0: {
                actions: 'checkIfReadyForCommonProjects',
              },
            },
          },
          loadError: {
            entry: (ctx, event) =>
              logOnEntryState('userProductRoles_loadError', { ctx, event }),
          },
        },
      },
      commonProjects: {
        id: 'commonProjects',
        initial: 'idle',
        states: {
          idle: {
            entry: (ctx, event) =>
              logOnEntryState('commonProjects_idle', { ctx, event }),
            always: {
              target: 'loading',
              cond: (context) =>
                context.projects !== undefined &&
                context.userProjects !== undefined &&
                context.userProductRoles !== undefined &&
                context.allProducts !== undefined &&
                !context.commonProjects &&
                context.onManagerToolClick !== undefined,
            },
          },
          loading: {
            entry: (ctx, event) =>
              logOnEntryState('commonProjects_loading', { ctx, event }),
            invoke: {
              id: 'getCommonProjects',
              src: async (ctx) => {
                const commonProjects: CommonProject[] = getCommonProjects({
                  ctx,
                });

                let hasAvailableTools;

                if (ctx.app !== ProductName.Organization) {
                  hasAvailableTools = commonProjects?.some(
                    ({ availableProducts }) =>
                      availableProducts?.some(
                        ({ name, disabled }) => name === ctx.app && !disabled
                      )
                  );
                }

                return {
                  commonProjects,
                  hasAvailableTools,
                };
              },
              onDone: {
                target: 'loaded',
                actions: assign((ctx, event) => {
                  const { commonProjects, hasAvailableTools } = event.data;

                  return {
                    ...ctx,
                    commonProjects,
                    hasAvailableTools,
                  };
                }),
              },
              onError: {
                target: 'loadError',
              },
            },
          },
          loaded: {
            entry: (ctx, event) =>
              logOnEntryState('commonProjects_loaded', { ctx, event }),
          },
          loadError: {
            entry: (ctx, event) =>
              logOnEntryState('commonProjects_loadError', { ctx, event }),
          },
        },
      },
      languages: {
        id: 'languages',
        initial: 'loading',
        states: {
          loading: {
            entry: (ctx, event) =>
              logOnEntryState('languages_loading', { ctx, event }),
            id: 'languagesLoading',
            invoke: {
              id: 'getLanguages',
              src: async () => {
                const { data: languages, error: languagesError } = await (
                  await urqlClient()
                ).getLanguagesLimited();

                if (hasErrors(languages)) {
                  throw new Error(languages.errors?.[0]?.message);
                }

                if (languagesError) {
                  throw new Error(
                    `Failed to fetch languages: ${languagesError.message}`
                  );
                }

                return {
                  locales: languages?.admin_?.locales || [],
                  localizationSettingsConfig:
                    languages?.admin_?.localizationSettingsConfig,
                };
              },
              onDone: {
                target: 'loaded',
                actions: assign((ctx, event) => {
                  const { locales, localizationSettingsConfig } = event.data;
                  const languages: Language[] = sort<Language>(locales)
                    .asc('name')
                    // .filter(
                    //   ({ languageCode }) =>
                    //     !languageCode ||
                    //     (
                    //       localizationSettingsConfig?.user
                    //         .supportedLanguageCodes || []
                    //     ).includes(languageCode)
                    // )
                    .filter(
                      ({ languageCode }) =>
                        !languageCode ||
                        // ctx.localizationSettingsConfig?.user.supportedLanguageCodes || []
                        // @TODO: Need this for November's 2024 demo (Arabic and English US only)
                        ['en', 'ar'].includes(languageCode)
                    )
                    // @TODO: Need this for November's 2024 demo (Arabic and English US only)
                    .filter(
                      ({ locale }) =>
                        locale === 'ar-AE' ||
                        locale === 'en-US' ||
                        locale === ''
                    );

                  return {
                    ...ctx,
                    languages,
                  };
                }),
              },
              onError: {
                target: 'loadError',
              },
            },
          },
          loaded: {
            entry: (ctx, event) =>
              logOnEntryState('languages_loaded', { ctx, event }),
          },
          loadError: {
            entry: (ctx, event) =>
              logOnEntryState('languages_loadError', { ctx, event }),
          },
        },
      },
      countries: {
        id: 'countries',
        initial: 'loading',
        states: {
          loading: {
            entry: (ctx, event) =>
              logOnEntryState('countries_loading', { ctx, event }),
            id: 'countriesLoading',
            invoke: {
              id: 'getCountries',
              src: async () => {
                const { data: countries, error: countriesError } = await (
                  await urqlClient()
                ).getCountriesLimited();

                if (hasErrors(countries)) {
                  throw new Error(countries.errors?.[0]?.message);
                }

                if (countriesError) {
                  throw new Error(
                    `Failed to fetch countries: ${countriesError.message}`
                  );
                }

                return countries?.admin_?.countries || [];
              },
              onDone: {
                target: 'loaded',
                actions: assign((ctx, event) => {
                  // as in manager.xstate
                  const unsupportedCountryCodes = [
                    'RU',
                    'SA',
                    'CA',
                    'CN',
                    'AU',
                    'AE',
                  ];

                  const countries = sort<Country>(event.data)
                    .asc('name')
                    .filter(
                      ({ code }) => !unsupportedCountryCodes.includes(code)
                    );

                  return {
                    ...ctx,
                    countries,
                  };
                }),
              },
              onError: {
                target: 'loadError',
              },
            },
          },
          loaded: {
            entry: (ctx, event) =>
              logOnEntryState('countries_loaded', { ctx, event }),
          },
          loadError: {
            entry: (ctx, event) =>
              logOnEntryState('countries_loadError', { ctx, event }),
          },
        },
      },
      timezones: {
        id: 'timezones',
        initial: 'loading',
        states: {
          loading: {
            entry: (ctx, event) =>
              logOnEntryState('timezones_loading', { ctx, event }),
            id: 'timezonesLoading',
            invoke: {
              id: 'getTimezones',
              src: async () => {
                const { data: timezones, error: timezonesError } = await (
                  await urqlClient()
                ).getTimeZonesLimited();

                if (hasErrors(timezones)) {
                  throw new Error(timezones.errors?.[0]?.message);
                }

                if (timezonesError) {
                  throw new Error(
                    `Failed to fetch timezones: ${timezonesError.message}`
                  );
                }

                const timeZones = timezones?.admin_?.timeZones || [];

                return transformTimezonesData<Timezone>(timeZones);
              },
              onDone: {
                target: 'loaded',
                actions: assign((ctx, event) => {
                  const timezones = sort<Timezone>(event.data).asc('label');

                  return {
                    ...ctx,
                    timezones,
                  };
                }),
              },
              onError: {
                target: 'loadError',
              },
            },
          },
          loaded: {
            entry: (ctx, event) =>
              logOnEntryState('timezones_loaded', { ctx, event }),
          },
          loadError: {
            entry: (ctx, event) =>
              logOnEntryState('timezones_loadError', { ctx, event }),
          },
        },
      },
      logout: {
        id: 'logout',
        initial: 'idle',
        states: {
          idle: {
            entry: (ctx, event) =>
              logOnEntryState('logout_idle', { ctx, event }),
          },
          loading: {
            entry: (ctx, event) =>
              logOnEntryState('logout_loading', { ctx, event }),
            invoke: {
              src: async (ctx, event) => {
                if (event.type !== 'logout') {
                  return;
                }

                const { data, error } = await (
                  await urqlClient()
                ).falsifyUserActiveSessionsLimited();

                if (hasErrors(data)) {
                  throw new Error(data.errors?.[0]?.message);
                }

                if (error) {
                  throw new Error(
                    `Failed to perform logout hook: ${error.message}`
                  );
                }

                event.callback();
              },
              onDone: {
                target: 'loaded',
              },
              onError: {
                target: 'error',
              },
            },
          },
          loaded: {
            entry: (ctx, event) =>
              logOnEntryState('logout_loaded', { ctx, event }),
          },
          error: {
            entry: (ctx, event) =>
              logOnEntryState('logout_error', { ctx, event }),
          },
        },
      },
    },
  },
  {
    actions: {
      checkIfReadyForCommonProjects: (ctx) => {
        if (
          ctx.projects &&
          ctx.userProjects &&
          ctx.userProductRoles &&
          ctx.allProducts &&
          !ctx.commonProjects
        ) {
          smartService.send('readyForCommonProjects');
        }
      },
    },
  }
);

export const smartService = interpret<
  SmartContext,
  SmartState,
  SmartEvents,
  SmartTypestates,
  any
>(smartMachine, {
  devTools: enableStateInspector,
});

smartService.start();

win.pypestream = {
  smartService,
};
