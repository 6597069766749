import * as React from 'react';
import { createComponent } from '@lit/react';
import { PaginationWC } from './pagination.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type PaginationProps =
  GenerateComponentTypeWithChildren<PaginationWC> & {
    onChange?: ({
      detail: { page, pageSize },
    }: {
      detail: { page: number; pageSize: number };
    }) => void;
    onShowSizeChange?: ({
      detail: { page, pageSize },
    }: {
      detail: { page: number; pageSize: number };
    }) => void;
  };

export type PaginationPropsWithRef = GeneratePropsWithRef<PaginationProps>;

export const Pagination = createComponent({
  tagName: PaginationWC.tagname,
  elementClass: PaginationWC,
  react: React,
  events: {
    onChange: 'change',
    onShowSizeChange: 'show-size-change',
  },
}) as PaginationPropsWithRef;
