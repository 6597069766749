import { unsafeCSS } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { html } from 'lit/static-html.js';
import { property, state, query } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { live } from 'lit/directives/live.js';
import { FormControlMixin } from '@open-wc/form-control';
import {
  FormControlController,
  BaseElement,
  customElement,
  CheckableFormControl,
  FormControl,
  watch,
} from '../base-element';
import styles from './checkbox.scss?inline';

@customElement('ps-checkbox')
export class CheckboxWC
  extends FormControlMixin(BaseElement)
  implements CheckableFormControl
{
  static styles = unsafeCSS(styles);

  @property() validationMode: FormControl['validationMode'] = 'onSubmit';

  private readonly FormControlController = new FormControlController(this, {
    value: (input: CheckableFormControl) =>
      input.checked ? input.value || 'on' : (undefined as unknown),
    defaultValue: (input: CheckableFormControl) => input.defaultChecked,
    setValue: (input: CheckableFormControl, value) => {
      // eslint-disable-next-line no-param-reassign
      input.checked = value as boolean;
    },
  });

  // The following properties and methods aren't strictly required,
  // but browser-level form controls provide them. Providing them helps
  // ensure consistency with browser-provided controls.
  get form() {
    return this.internals.form;
  }

  @query('input[type="checkbox"]') input: HTMLInputElement;

  @state() invalid = false;

  /** The name of the checkbox, submitted as a name/value pair with form data. */
  @property() name = '';

  /** The current value of the checkbox, submitted as a name/value pair with form data. */
  @property() value: string;

  /** The checkbox's size. */
  @property({ reflect: true }) size: 'small' | 'medium' | 'large' = 'medium';

  /** Disables the checkbox. */
  @property({ type: Boolean, reflect: true }) disabled = false;

  /** Makes the checkbox a required field. */
  @property({ type: Boolean, reflect: true }) required = false;

  /** Draws the checkbox in a checked state. */
  @property({ type: Boolean, reflect: true }) checked = false;

  /**
   * Renders the checkbox in an indeterminate state; usually used
   * for checkboxes handling "select all / none" behavior
   */
  @property({ type: Boolean, reflect: true }) indeterminate = false;

  /** The default value of the form control. Primarily used for resetting the form control. */
  @property({ type: Boolean, reflect: true }) defaultChecked = false;

  /** The checkbox's data-testid. */
  @property({ reflect: true }) 'data-testid' = 'wc-checkbox';

  /** The checbox's shapes: square or circle */
  @property({ reflect: true }) shape: 'square' | 'circle' = 'square';

  /** Provide extra context associated with component */
  @property({ type: Object }) context: object;

  firstUpdated() {
    this.invalid = !this.input?.checkValidity();

    if (this.defaultChecked !== this.checked) {
      this.checked = this.defaultChecked;
    }
  }

  /** Checks for validity but does not show a validation message. Returns true when valid and false when invalid. */
  checkValidity() {
    return this.input?.checkValidity();
  }

  /** Checks for validity and shows a validation message if the control is invalid. */
  reportValidity() {
    return this.input?.reportValidity();
  }

  @watch(['checked', 'indeterminate', 'value'], { waitUntilFirstUpdate: true })
  handleStateChange() {
    this.input.checked = this.checked; // force a sync update
    this.input.indeterminate = this.indeterminate; // force a sync update
    this.FormControlController.updateValidity();
  }

  /**
   * Sets a custom validation message. The value provided will be shown to the user when the form is submitted. To clear
   * the custom validation message, call this method with an empty string.
   */
  setCustomValidity(message: string) {
    this.input.setCustomValidity(message);
    this.invalid = !this.input?.checkValidity();
  }

  handleClick() {
    this.checked = !this.checked;
    this.indeterminate = false;
    this.emit('change');
  }

  render() {
    return html`
      <label
        data-cy="label"
        class=${classMap({
          'c-checkbox': true,
          'c-checkbox--checked': this.checked,
          'c-checkbox--indeterminate': this.indeterminate,
          'c-checkbox--disabled': this.disabled,
          [`c-checkbox--size-${this.size}`]: this.size,
        })}
      >
        <input
          data-cy="checkbox"
          class="c-checkbox__input"
          type="checkbox"
          name=${this.name}
          .value=${this.value}
          .checked=${live(this.checked)}
          .indeterminate=${live(this.indeterminate)}
          .disabled=${this.disabled}
          .required=${this.required}
          aria-checked=${this.checked ? 'true' : 'false'}
          @click=${this.handleClick}
        />
        <span
          class=${classMap({
            'c-checkbox__control': true,
            'c-checkbox__control--circle': this.shape === 'circle',
          })}
        ></span>
        ${this.slotted()
          ? html` <slot class="c-checkbox__label"></slot> `
          : null}
      </label>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-checkbox': CheckboxWC;
  }
}
