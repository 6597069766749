import { Org } from '../universal-nav/types';

const compareByField =
  <T, K extends keyof T>(field: K) =>
  (a: T, b: T): number => {
    const fieldA = String(a[field] ?? '').toLowerCase();
    const fieldB = String(b[field] ?? '').toLowerCase();

    if (fieldA < fieldB) return -1;
    if (fieldA > fieldB) return 1;
    return 0;
  };

export const sortByKeyRecursively = <T extends keyof Org>(
  orgs: Org[],
  field: T
): Org[] =>
  orgs
    .sort(compareByField(field))

    .map((org) => ({
      ...org,
      ...(org.children && {
        children: sortByKeyRecursively(org.children, field),
      }),
    }));
