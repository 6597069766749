import { IconButtonProps } from '@rjsf/utils';
import { Button, Icon } from '@pypestream/design-system';
import React from 'react';

export const AddButton = (props: IconButtonProps) => {
  const { onClick, disabled } = props;

  const uiSchema = props.uiSchema?.items || props.uiSchema?.condtions;
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      size="medium"
      width="full"
      className="u-margin-top-medium"
    >
      {uiSchema?.['ui:options']?.addText || 'Add Element'}
      <Icon name="plus" size="small" slot="suffix" />
    </Button>
  );
};
