import React from 'react';
import { WidgetProps } from '@rjsf/utils';
import { RadioGroup, Radio } from '@pypestream/design-system';

export const RadioGroupWidget = function (props: WidgetProps) {
  const value = props.value?.toString() ? props.value.toString() : undefined;
  return (
    <RadioGroup
      name={props.schema.$id}
      onChange={(e) => {
        props.onChange(e.target.value === 'true');
      }}
      value={value}
    >
      {props.schema.oneOf?.map((item, index) => {
        if (
          typeof item === 'object' &&
          'const' in item &&
          typeof item.const !== 'undefined' &&
          item.const !== null
        ) {
          return (
            <Radio
              key={index}
              checked={props.value === item.const}
              value={item.const.toString()}
            >
              {item.title}
            </Radio>
          );
        }
        return null;
      })}
    </RadioGroup>
  );
};
