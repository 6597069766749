import { Variable } from '@pypestream/app-loader-shared';
import { Divider, Input, MenuItem, TextBody } from '@pypestream/design-system';
import { WidgetProps } from '@rjsf/utils';
import { nanoid } from 'nanoid';
import React, { useCallback } from 'react';
import { ActionsMenu } from './triggers-widget/actions-menu';

type InputVariableType =
  | 'truthyVarKey'
  | 'truthyVarValue'
  | 'falsyVarKey'
  | 'falsyVarValue';

export const VariablesWidget: React.FunctionComponent<
  Omit<WidgetProps, 'value'> & { value: Variable[] }
> = (props) => {
  const { value: variables, onChange } = props;

  const handleRemoveVariable = useCallback(
    (variableId: string) => {
      onChange(variables.filter((val) => val.id !== variableId));
    },
    [onChange, variables]
  );

  const handleAddVariable = useCallback(
    (index: number) => {
      const newValues = variables.reduce(
        (
          updatedVariables: Variable[],
          currentVariable: Variable,
          currentIndex: number
        ) => {
          updatedVariables.push(currentVariable);

          if (currentIndex === index) {
            updatedVariables.push({
              truthyVars: {
                key: '',
                value: '',
              },
              falsyVars: {
                key: '',
                value: '',
              },
              id: nanoid(),
            });
          }
          return updatedVariables;
        },
        []
      );

      onChange(newValues);
    },
    [onChange, variables]
  );

  const handleVariableChange = useCallback(
    ({
      event,
      variableIndex,
      changeType,
    }: {
      event: React.ChangeEvent<HTMLInputElement>;
      variableIndex: number;
      changeType: InputVariableType;
    }) => {
      const updatedVariables = [...variables];
      if (
        updatedVariables[variableIndex]?.truthyVars &&
        updatedVariables[variableIndex]?.falsyVars
      ) {
        switch (changeType) {
          case 'truthyVarKey':
            updatedVariables[variableIndex].truthyVars!.key =
              event.target.value;
            break;
          case 'truthyVarValue':
            updatedVariables[variableIndex].truthyVars!.value =
              event.target.value;
            break;
          case 'falsyVarKey':
            updatedVariables[variableIndex].falsyVars!.key = event.target.value;
            break;
          case 'falsyVarValue':
            updatedVariables[variableIndex].falsyVars!.value =
              event.target.value;
            break;
        }
      }

      onChange(updatedVariables);
    },
    [onChange, variables]
  );

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <TextBody size="small" style={{ width: '50%' }}>
          True
        </TextBody>
        <TextBody size="small">False</TextBody>
      </div>
      {variables.map((variable, index: number) => (
        <div key={variable.id}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-around',
              gap: '8px',
            }}
          >
            <Input
              placeholder="Key"
              variant="outlined"
              onChange={(event) =>
                handleVariableChange({
                  event,
                  variableIndex: index,
                  changeType: 'truthyVarKey',
                })
              }
              value={variable.truthyVars?.key}
            />
            <Input
              placeholder="Value"
              variant="outlined"
              onChange={(event) =>
                handleVariableChange({
                  event,
                  variableIndex: index,
                  changeType: 'truthyVarValue',
                })
              }
              value={variable.truthyVars?.value.toString()}
            />
            <Divider vertical style={{ height: 36, margin: '0 8px' }} />
            <Input
              placeholder="Key"
              variant="outlined"
              onChange={(event) =>
                handleVariableChange({
                  event,
                  variableIndex: index,
                  changeType: 'falsyVarKey',
                })
              }
              value={variable.falsyVars?.key}
            />
            <Input
              placeholder="Value"
              variant="outlined"
              onChange={(event) =>
                handleVariableChange({
                  event,
                  variableIndex: index,
                  changeType: 'falsyVarValue',
                })
              }
              value={variable.falsyVars?.value.toString()}
            />
            <ActionsMenu>
              <MenuItem
                disabled={false}
                onClick={() => handleAddVariable(index)}
              >
                Add Variables
              </MenuItem>
              <MenuItem onClick={() => handleRemoveVariable(variable.id)}>
                Remove Variables
              </MenuItem>
            </ActionsMenu>
          </div>
        </div>
      ))}
    </div>
  );
};
