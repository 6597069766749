import {
  Button,
  Spacer,
  Stack,
  TextBody,
  TextTitle,
} from '@pypestream/design-system';
import { useFormContext } from 'react-hook-form';

import { OutputProjectFormType } from '../../../../../components';
import { AppletDescriptionField, AppletNameField } from '../applet-modal';
import { TranslationComponent } from '@pypestream/translations';

export type ProjectInfoPropsType = {
  nextStep: (formData?: Partial<OutputProjectFormType>) => void;
};

export const AppletInfo = ({ nextStep }: ProjectInfoPropsType) => {
  const form = useFormContext();

  const { handleSubmit, register, control } = form;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <TextTitle size="small" textAlign="center">
        <TranslationComponent i18nKey="manager/projects:projectDetails.createAppletModal.step1.title">
          Create an applet
        </TranslationComponent>
      </TextTitle>
      <Spacer size="small"></Spacer>
      <TextBody variant="secondary" textAlign="center">
        <TranslationComponent i18nKey="manager/projects:projectDetails.createAppletModal.step1.subTitle">
          The Pypestream Applet is the runtime environment for Pypestream
          platform functionality on a given website. The App Loader code snippet
          calls the Pypestream platform and retrieves and executes any
          additional code needed on the page assuming certain conditions are
          met. This includes Analytics tracking code, chat widget code, and
          Elements from the automation engine.
        </TranslationComponent>
      </TextBody>
      <Spacer size="xlarge"></Spacer>
      <AppletNameField control={control} />
      <Spacer size="xlarge"></Spacer>
      <AppletDescriptionField {...register('description')} />
      <Spacer size="xlarge"></Spacer>
      <Stack justifyContent="end">
        <Button
          variant="primary"
          size="large"
          type="button"
          onClick={handleSubmit(nextStep)}
        >
          <TranslationComponent i18nKey="manager/projects:projectDetails.createAppletModal.nextStep">
            Next
          </TranslationComponent>
        </Button>
      </Stack>
    </form>
  );
};
