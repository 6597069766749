import { Rule } from './rule';
import { ShiftActions } from './shift-actions';
import { AddGroupAction } from './add-group-action';
import { AddRuleAction } from './add-rule-action';
import { RemoveGroupAction } from './remove-group-action';
import { RemoveRuleAction } from './remove-rule-action';
import { ValueSelector } from './value-selector';

export const controlElements = {
  rule: Rule,
  shiftActions: ShiftActions,
  addGroupAction: AddGroupAction,
  addRuleAction: AddRuleAction,
  removeGroupAction: RemoveGroupAction,
  removeRuleAction: RemoveRuleAction,
  valueSelector: ValueSelector,
};
