import React, { ReactNode } from 'react';
import { nanoid } from 'nanoid';
import { Dropdown, IconButton, Menu } from '@pypestream/design-system';

interface ActionsMenuProps {
  children: ReactNode;
}
export const ActionsMenu = ({ children }: ActionsMenuProps) => {
  const buttonUUID = React.useRef(`icon-button-${nanoid()}`);

  return (
    <>
      <IconButton name="meatballs" id={buttonUUID.current} size="large" />
      <Dropdown
        trigger={buttonUUID.current}
        placement="bottom-end"
        width="auto"
      >
        <Menu>{children}</Menu>
      </Dropdown>
    </>
  );
};
