import { html, unsafeCSS, PropertyValueMap, TemplateResult } from 'lit';
import { property, state, query } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { live } from 'lit/directives/live.js';
import { FormControlMixin } from '@open-wc/form-control';
import '../../icon';
import {
  FormControlController,
  BaseElement,
  customElement,
  NonCheckableFormControl,
  FormControl,
  watch,
} from '../../base-element';
import styles from './simple-select.scss?inline';

@customElement('ps-simple-select')
export class SimpleSelectWC
  extends FormControlMixin(BaseElement)
  implements NonCheckableFormControl
{
  invalid: boolean;

  reportValidity: () => boolean;

  setCustomValidity: (message: string) => void;

  static styles = unsafeCSS(styles);

  @property() validationMode: FormControl['validationMode'] = 'onSubmit';

  /** The default value of the form control. Primarily used for resetting the form control. */
  @property({ type: String, reflect: true }) defaultValue = '';

  private readonly FormControlController = new FormControlController(this);

  @watch(['value'], { waitUntilFirstUpdate: true })
  handleStateChange() {
    this.input.value = this.value; // force a sync update
    this.FormControlController.updateValidity();
  }

  @query('select') input: HTMLSelectElement;

  @query('slot') defaultSlot: HTMLSlotElement;

  /** Select visual variant. */
  @property() variant: 'standard' | 'outlined' = 'standard';

  /**
   * This attribute is used to specify the name of the control.
   */
  @property() name = '';

  /**
   * The value of the select; reflected by the selected option.
   */
  @property() value = '';

  /**
   * Text that appears in the select when it has no value.
   */
  @property({ reflect: true }) placeholder = '';

  /**
   * Controls the disabled state of the select.
   */
  @property({ type: Boolean, reflect: true }) disabled = false;

  /**
   * A Boolean attribute indicating that an option with a non-empty string value must be selected.
   */
  @property({ type: Boolean, reflect: true }) required = false;

  protected firstUpdated(
    _changedProperties: PropertyValueMap<unknown> | Map<PropertyKey, unknown>
  ): void {
    super.firstUpdated(_changedProperties);

    this.invalid = !this.input?.checkValidity();

    if (this.defaultValue !== this.value) {
      this.value = this.defaultValue;
    }
  }

  @state() options: TemplateResult[] = [];

  // add a slight rendering delay to prevent the main thread from being blocked
  async handleSlottedContent() {
    // eslint-disable-next-line no-promise-executor-return
    const options = [
      ...this.defaultSlot.assignedElements({
        flatten: true,
      }),
    ].filter((el) => {
      if (
        el.tagName?.toLowerCase() === 'ps-select-option' ||
        el.tagName?.toLowerCase() === 'option'
      ) {
        return true;
      }
      return false;
    });

    const opts: TemplateResult[] = [];
    Array.from(options as unknown as HTMLCollection).forEach((option) => {
      const disabled = option.hasAttribute('disabled');
      const v = option.getAttribute('value') || '';
      const selected = this.value === v;

      opts.push(
        html`<option ?disabled=${disabled} ?selected=${selected} value=${v}>
          ${option.textContent}
        </option>`
      );
    });

    requestIdleCallback(() => {
      this.options = opts;
    });
  }

  private _handleInput(e: Event) {
    const { value } = e.target as HTMLSelectElement;
    this.value = value;
    this.emit('change');
  }

  render() {
    return html`
      <div class="c-simple-select">
        <select
          class=${classMap({
            'c-simple-select__control': true,
            'c-simple-select__control--placeholder': !this.value,
            [`c-simple-select__control--variant-${this.variant}`]: this.variant,
          })}
          @input=${this._handleInput}
          @select=${this._handleInput}
          @change=${this._handleInput}
          ?disabled=${this.disabled}
          ?required=${this.required}
          name=${this.name}
          .value=${live(this.value)}
        >
          ${!this.placeholder || this.value
            ? undefined
            : html`
                <option disabled hidden value="option-placeholder" selected>
                  ${this.placeholder}
                </option>
              `}
          ${this.options}
        </select>
        <div class="c-simple-select__line"></div>
        <div class="c-simple-select__arrow">
          <ps-icon name="chevron-down" size="xsmall"></ps-icon>
        </div>

        <slot
          @slotchange=${this.handleSlottedContent}
          style="display: none;"
        ></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-simple-select': SimpleSelectWC;
  }
}
