import { AppletInstance, AppletStatusEnum } from '@pypestream/api-services';
import {
  AppletCard,
  AppletCardAvatar,
  AppletCardInfo,
  AppletCardTitle,
  Button,
  Icon,
  Modal,
  ModalIcon,
  ModalProps,
  Spacer,
  Stack,
  TextBody,
  TextTitle,
  Tag,
} from '@pypestream/design-system';
import {
  dateFnsLocales,
  fallbackLanguage,
  TranslationComponent,
  useTranslation,
} from '@pypestream/translations';
import { formatDistance, parseISO } from 'date-fns';
import React, { useRef, useState } from 'react';

import {
  sendManagerEvent,
  useManagerCtxSelector,
} from '../../../../xstate/app.xstate';
import { AppletModal } from './applet-modal';

export const EnvironmentApplets = ({
  environmentId,
  applets,
  accountId,
  projectId,
  disabled,
}: {
  environmentId: string;
  applets: Array<AppletInstance>;
  accountId: string | undefined;
  projectId: string | undefined;
  disabled?: boolean;
}) => {
  const [t] = useTranslation();
  const modalRef = useRef<ModalProps>(null);
  const [selectedAppletId, setSelectedAppletId] =
    useState<AppletInstance['id']>();

  const { defaultLanguage } = useManagerCtxSelector((ctx) => ({
    defaultLanguage: ctx.userInfo.defaultLanguage,
  }));

  const isModalOpened = !!selectedAppletId;
  const selectedApplet = applets.find((a) => a.id === selectedAppletId);
  const closeModal = () => setSelectedAppletId(undefined);

  return (
    <>
      {environmentId && (
        <AppletModal modalRef={modalRef} environmentId={environmentId} />
      )}
      <Spacer size="small" />

      <Stack alignItems="center" justifyContent="space-between">
        <TextTitle size="xsmall">
          <Icon
            name="tag"
            size="large"
            color="purple"
            variant="filled"
            slot="prefix"
          />
          <TranslationComponent i18nKey="manager/projects:projectDetails.environmentsTab.appletsSection.title">
            Web Applets
          </TranslationComponent>
          <Icon name="info" color="gray" size="small" slot="suffix" />
        </TextTitle>
        <Button
          disabled={disabled}
          variant="ghost"
          size="large"
          onClick={(e) => {
            if (modalRef.current) {
              modalRef.current.open = true;
            }
          }}
        >
          <TranslationComponent i18nKey="manager/projects:projectDetails.environmentsTab.appletsSection.addButton">
            Add Applet
          </TranslationComponent>
          <Icon slot="prefix" name="add" />
        </Button>
      </Stack>
      {(applets || []).length > 0 && <Spacer size="xlarge" />}
      <Modal
        open={isModalOpened}
        size="medium"
        stayOnClickOutside
        stayOnEsc
        onClose={closeModal}
      >
        <ModalIcon
          name="error"
          slot="header"
          style={{ display: 'flex', justifyContent: 'center' }}
        />
        <Stack slot="header" alignItems="center" direction="column">
          <TextTitle size="small">
            <TranslationComponent i18nKey="manager/projects:projectDetails.updateAppletInstanceStatusModal.title">
              Are you sure?
            </TranslationComponent>
          </TextTitle>
          <TextBody variant="secondary">
            <TranslationComponent i18nKey="manager/projects:projectDetails.updateAppletInstanceStatusModal.description">
              Update Applet Status Description
            </TranslationComponent>
          </TextBody>
        </Stack>
        <Stack slot="footer" justifyContent="end">
          <Button
            variant="ghost"
            size="large"
            type="button"
            onClick={closeModal}
          >
            <TranslationComponent i18nKey="manager/projects:projectDetails.updateAppletInstanceStatusModal.cancelButton">
              Cancel
            </TranslationComponent>
          </Button>
          <Button
            size="large"
            variant="warning"
            data-cy="delete-project"
            type="button"
            onClick={() => {
              if (!selectedAppletId || !selectedApplet) {
                closeModal();
                return;
              }
              sendManagerEvent({
                type: 'manager.updateApplet',
                status:
                  selectedApplet?.status === AppletStatusEnum.Active
                    ? AppletStatusEnum.Inactive
                    : AppletStatusEnum.Active,
                updateAppletInstanceId: selectedAppletId,
              });
              closeModal();
            }}
          >
            <TranslationComponent i18nKey="manager/projects:projectDetails.updateAppletInstanceStatusModal.confirmButton">
              Update Applet
            </TranslationComponent>
          </Button>
        </Stack>
      </Modal>

      <Stack gutter="small" direction="column" alignItems="stretch">
        {applets?.map(
          ({
            id,
            status,
            triggers,
            actions,
            name,
            updatedByUser,
            updatedAt,
          }) => {
            return (
              <AppletCard
                key={id}
                interactive
                checked={status === AppletStatusEnum.Active}
                onToggleClick={() => setSelectedAppletId(id)}
                href={`/organization/${accountId}/projects/${projectId}/environments/${environmentId}/applets/${id}`}
              >
                <AppletCardTitle label={name || 'Untitled'}>
                  <Tag minimal slot="suffix">
                    <TranslationComponent i18nKey="manager/projects:projectDetails.environmentsTab.appletsSection.status">
                      Cloud
                    </TranslationComponent>
                  </Tag>
                </AppletCardTitle>
                <AppletCardInfo
                  label={
                    t(
                      'manager/projects:projectDetails.environmentsTab.appletsSection.trigger.title',
                      {
                        defaultValue: 'Trigger',
                      }
                    ) || ''
                  }
                >
                  {triggers.length
                    ? triggers
                        .map((tr) => tr?.packageVersion?.package?.displayName)
                        .join(', ')
                    : t(
                        'manager/projects:projectDetails.environmentsTab.appletsSection.trigger.label',
                        {
                          defaultValue: "You haven't set a triggers",
                        }
                      ) || ''}
                </AppletCardInfo>
                <AppletCardInfo
                  label={
                    t(
                      'manager/projects:projectDetails.environmentsTab.appletsSection.actions.title',
                      {
                        defaultValue: 'Actions',
                      }
                    ) || ''
                  }
                >
                  {actions.length
                    ? actions
                        .map((a) => a?.packageVersion?.package?.displayName)
                        .join(', ')
                    : t(
                        'manager/projects:projectDetails.environmentsTab.appletsSection.actions.label',
                        {
                          defaultValue: "You haven't set an actions",
                        }
                      ) || ''}
                </AppletCardInfo>
                <AppletCardAvatar
                  date={formatDistance(
                    parseISO(updatedAt || new Date().toISOString()),
                    new Date(),
                    {
                      addSuffix: true,
                      locale:
                        dateFnsLocales[defaultLanguage || fallbackLanguage],
                    }
                  )}
                  slot="footer"
                  label={`${updatedByUser?.firstName} ${updatedByUser?.lastName}`}
                  src={updatedByUser?.picture || ''}
                />
              </AppletCard>
            );
          }
        )}
      </Stack>
    </>
  );
};
