/* eslint-disable wc/no-self-class */
import { html, unsafeCSS, PropertyValues } from 'lit';
import { query, property } from 'lit/decorators.js';
import { Swiper } from 'swiper';
import { Manipulation } from 'swiper/modules';
import { BaseElement, customElement, watch } from '../base-element';
import { StepperItemWC } from './stepper-item.wc';
import styles from './stepper.scss?inline';

@customElement('ps-stepper')
export class StepperWC extends BaseElement {
  static styles = unsafeCSS(styles);

  @query('.swiper')
  private swiperContainer?: HTMLDivElement;

  /**
   * Index number of initial slide.
   */
  @property({ reflect: true, type: Number, attribute: 'initial-slide' })
  initialSlide: number = 0;

  /**
   * Number of slides that show up at the same time
   */
  @property({ reflect: true, type: Number, attribute: 'slides-per-view' })
  slidesPerView?: number = 1;

  swiper?: Swiper;

  @property({ type: String }) private _screenName: string = '';

  @property({ type: Boolean }) private _isValidSlide: boolean | null = null;

  @watch('_isValidSlide')
  _isValidSlideChanged() {
    if (!this._screenName || this._isValidSlide === null) {
      return;
    }

    // eslint-disable-next-line no-console
    console.log(
      `%cCurrent screen "${this._screenName}" is ${this._isValidSlide ? 'valid' : 'invalid'}`,
      `color: ${this._isValidSlide ? 'green' : 'red'}; font-size: larger; font-weight: bold`
    );

    this._isValidSlide = null;
  }

  private initSwiper(): void {
    if (!this.swiper) {
      this.swiper = new Swiper(this.swiperContainer as HTMLElement, {
        loop: false,
        autoplay: false,
        slidesPerView: this.slidesPerView,
        cssMode: false,
        keyboard: false,
        mousewheel: false,
        uniqueNavElements: true,
        // this custom swiperElementNodeName prop is a temp patch / workaround until PR to swiper (removing the hard-coded `SWIPER-CONTAINER` tagname) is merged
        swiperElementNodeName: 'PS-STEPPER',
        observer: true,
        observeParents: true,
        resistanceRatio: 0.5,
        spaceBetween: 0,
        centeredSlides: true,
        centeredSlidesBounds: true,
        autoHeight: true,
        resizeObserver: false,
        allowTouchMove: false,
        initialSlide: this.initialSlide,
        modules: [Manipulation],
      });

      this.swiper.on(
        'slideChangeTransitionStart',
        this.onSlideChangeTransitionStart
      );
      this.swiper.on(
        'slideChangeTransitionEnd',
        this.onSlideChangeTransitionEnd
      );
    }
  }

  firstUpdated(changedProperties: PropertyValues) {
    super.firstUpdated(changedProperties);
    setTimeout(() => {
      this.initSwiper();
    }, 50);

    setTimeout(() => {
      if (this.swiper?.slides && this.swiper?.slides[this.initialSlide]) {
        (this.swiper?.slides[this.initialSlide] as StepperItemWC).active = true;
      }
    }, 750);

    this.childNodes.forEach((node) => {
      if (node) {
        const stepperItem = node as StepperItemWC;

        ['blur', 'focus', 'ps-blur', 'ps-focus', 'ps-error'].forEach(
          (event) => {
            stepperItem.addEventListener(event, () => {
              if (!stepperItem.active) {
                return;
              }
              setTimeout(() => {
                this._isValidSlide = stepperItem.validateStep();
                this._screenName = stepperItem.name;
              }, 0);
            });
          }
        );
      }
    });
  }

  disconnectedCallback() {
    if (this.swiper) {
      this.swiper.destroy();
      this.swiper = undefined;
    }
    // eslint-disable-next-line wc/guard-super-call
    super.disconnectedCallback();
  }

  onSlideChangeTransitionStart = (swiper: Swiper) => {
    const slides = this.swiper?.slides;
    if (slides) {
      (slides[swiper.previousIndex] as StepperItemWC).active = false;
    }
  };

  onSlideChangeTransitionEnd = (swiper: Swiper) => {
    const slides = this.swiper?.slides;
    if (slides) {
      (slides[swiper.activeIndex] as StepperItemWC).active = true;
    }
  };

  render() {
    return html`
      <div class="c-stepper swiper">
        <div class="c-stepper__wrapper swiper-wrapper"><slot></slot></div>
      </div>
    `;
  }

  /**
   * Run transition to next step.
   */
  public nextStep() {
    this.swiper?.slideNext();
  }

  /**
   * Run transition to previous step.
   */
  public backStep() {
    this.swiper?.slidePrev();
  }

  /**
   * Run transition to the step with index number equal to 'index' parameter.
   */
  public goToStep(index: number) {
    this.swiper?.slideTo(index);
  }

  /**
   * Add new steps to the required index.
   */
  public addStep(
    index: number,
    steps: HTMLElement | HTMLElement[] | string | string[]
  ) {
    this.swiper?.addSlide(index, steps);
  }

  /**
   * Add new steps to the end.
   */
  public appendStep(steps: HTMLElement | HTMLElement[] | string | string[]) {
    this.swiper?.appendSlide(steps);
  }

  /**
   * Add new steps to the beginning.
   */
  public prependStep(steps: HTMLElement | HTMLElement[] | string | string[]) {
    this.swiper?.prependSlide(steps);
  }

  /**
   * Remove all slides.
   */
  public removeAllSteps() {
    this.swiper?.removeAllSlides();
  }

  /**
   * Remove selected slides.
   */
  public removeStep(index: number) {
    this.swiper?.removeSlide(index);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-stepper': StepperWC;
  }
  enum PSElementTagNameMap {
    'ps-stepper' = 'ps-stepper',
  }
}
