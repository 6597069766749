import { RJSFSchema } from '@rjsf/utils';

export const AppBuilderSchema: RJSFSchema = {
  title: 'Applets',
  type: 'array',
  minItems: 1,
  description:
    'The Pypestream Applet is the runtime environment for Pypestream platform functionality on a given website. The App Loader code snippet calls the Pypestream platform and retrieves and executes any additional code needed on the page assuming certain conditions are met. This includes Analytics tracking code, chat widget code, and Elements from the automation engine',
  items: {
    type: 'object',
    title: '',
    properties: {
      name: {
        title: 'Name',
        type: 'string',
      },
      description: {
        title: 'Description',
        type: 'string',
      },
      status: {
        type: 'string',
        enum: ['ACTIVE', 'INACTIVE'],
      },
      triggers: {
        type: 'array',
        items: {
          type: 'object',
          additionalProperties: true,
          properties: {},
        },
      },
      options: {
        type: 'object',
        properties: {
          keepAlive: {
            type: 'boolean',
            title: 'Keep Alive',
            default: false,
          },
        },
      },
      condition: {
        type: 'object',
        properties: {
          query: {
            type: 'object',
            title: 'Conditions-Query',
            description: '',
            additionalProperties: true,
          },
          advanceSettings: {
            title: 'Advance settings',
            type: 'object',
            properties: {
              checkImmediately: {
                type: 'boolean',
                oneOf: [
                  {
                    title: 'Check Immediately',
                    const: true,
                  },
                  {
                    title: 'Check Every',
                    const: false,
                  },
                ],
                default: true,
              },
            },
            allOf: [
              {
                if: {
                  properties: {
                    checkImmediately: {
                      const: true,
                    },
                  },
                },
                then: {
                  properties: {},
                },
                else: {
                  properties: {
                    timeInterval: {
                      type: 'number',
                      readOnly: true,
                      title:
                        'Time interval between condition recheck! (for now its hardcoded to 1000ms)',
                    },
                    noOfAttempts: {
                      type: 'number',
                      minimum: 1,
                      title:
                        'No of attempts to recheck conditions after trigger',
                    },
                  },
                  required: ['noOfAttempts'],
                },
              },
            ],
          },
        },
      },
      variables: {
        type: 'array',
        title: 'Set Variable(s)',
        items: {
          additionalProperties: true,
          properties: {},
        },
      },
      actions: {
        type: 'array',
        title: 'Actions',
        items: {
          type: 'object',
          additionalProperties: true,
          properties: {},
        },
      },
    },
    required: ['triggers', 'actions'],
  },
};
