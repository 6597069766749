import React from 'react';
import {
  BlockList,
  Search,
  Spacer,
  Card,
  Text,
  Stack,
  Image,
} from '@pypestream/design-system';
import { useTranslation } from '@pypestream/translations';

interface DataItem {
  id: string;
  image: string;
  label: string;
}

const fallback =
  'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMwLjQ5NTkgMEgxLjUwNDA5QzAuNjczNDA4IDAgMCAwLjY3MzQwMiAwIDEuNTA0MDlWMzAuNDk1OUMwIDMxLjMyNjYgMC42NzM0MDggMzIgMS41MDQwOSAzMkgzMC40OTU5QzMxLjMyNjYgMzIgMzIgMzEuMzI2NiAzMiAzMC40OTU5VjEuNTA0MDlDMzIgMC42NzM0MDIgMzEuMzI2NiAwIDMwLjQ5NTkgMFoiIGZpbGw9IiNFRkYwRjIiLz4KPC9zdmc+Cg==';

const certificates: DataItem[] = [
  {
    id: '1',
    image: 'https://images.pexels.com/photos/247676/pexels-photo-247676.jpeg',
    label: 'Execute Workflow',
  },
  {
    id: '2',
    image: '',
    label: 'Respond to Webhook',
  },
  {
    id: '3',
    image: '',
    label: 'AWS Certificate Manager',
  },
  {
    id: '4',
    image: '',
    label: 'AWS Certificate Manager',
  },
  {
    id: '5',
    image: '',
    label: 'AWS Certificate Manager',
  },
  {
    id: '6',
    image: '',
    label: 'AWS Certificate Manager',
  },
  {
    id: '7',
    image: '',
    label: 'AWS Certificate Manager',
  },
  {
    id: '8',
    image: '',
    label: 'AWS Certificate Manager',
  },
];

export const EnvironmentCertificates = () => {
  const [t] = useTranslation();
  return (
    <>
      <Search
        variant="filled"
        size="large"
        placeholder={
          t('manager/common:search', { defaultValue: 'Search!' }) || 'Search'
        }
      ></Search>
      <Spacer size="large" />
      <BlockList gutter="small">
        {certificates.map((certificate) => (
          <Card key={certificate.id}>
            <Stack gutter="large" alignItems="center" nowrap>
              <Image
                src={certificate.image || fallback}
                width="32px"
                height="32px"
              ></Image>
              <Text
                size="5xsmall"
                truncate
                truncate-lines="2"
                fontWeight="medium"
              >
                {certificate.label}
              </Text>
            </Stack>
          </Card>
        ))}
      </BlockList>
    </>
  );
};
