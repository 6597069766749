import React from 'react';
import ReactDOM from 'react-dom/client';
import { sentryInit, showGitInfo } from '@pypestream/utils';
import { init as initI18n, LanguageScopes } from '@pypestream/translations';
import { PostHogProvider } from 'posthog-js/react';
import posthog from 'posthog-js';

import { RouterProvider } from './router';
import { AuthProvider } from './global-provider';
import { XstateDevLogger } from './xstate';
import { datadogInit } from './datadog-init';

import './index.scss';

let container: HTMLElement | null = null;

posthog.init(
  // new
  import.meta.env.FE_POSTHOG_API_KEY,
  { api_host: import.meta.env.FE_POSTHOG_API_HOST }
);

showGitInfo({
  appName: 'Manager',
  commitHash: process.env.GIT_COMMIT_HASH,
  branchName: process.env.GIT_BRANCH_NAME,
  tagName: process.env.GIT_TAG_NAME,
});

posthog.register({
  'Pypestream Product': 'Manager',
  // https://pypestream.atlassian.net/wiki/spaces/analytics/pages/3632398424/Analytics+Dogfooding+-+Implementation+Details#Pypestream-Environment-Property
  'Pypestream Environment': import.meta.env.ANALYTICS_CAPTURE_ENVIRONMENT,
  'Git Commit': import.meta.env.ANALYTICS_CAPTURE_GIT_COMMIT,
  'Git Tags': import.meta.env.ANALYTICS_CAPTURE_GIT_TAGS
    ? import.meta.env.ANALYTICS_CAPTURE_GIT_TAGS.split(',')
    : null,
  'GitHub PR ID': import.meta.env.ANALYTICS_CAPTURE_GITHUB_PR_ID,
  'GitHub PR URL': import.meta.env.ANALYTICS_CAPTURE_GITHUB_PR_URL,
});

// auto-opt out until we know more about the user
posthog.opt_out_capturing();

document.addEventListener('DOMContentLoaded', async function () {
  if (!container) {
    container = document.getElementById('root') as HTMLElement;
    const root = ReactDOM.createRoot(container);
    await initI18n(LanguageScopes.manager);
    datadogInit();
    const managerSentryDSN = import.meta.env.FE_MANAGER_SENTRY_DSN;
    sentryInit(managerSentryDSN);

    // <React.StrictMode>
    // <React.Suspense
    //   fallback={
    //     <TextTitle i18nKey="studio/common:loading">Loading ...</TextTitle>
    //   }
    // >
    root.render(
      <XstateDevLogger>
        <PostHogProvider client={posthog}>
          <AuthProvider>
            <RouterProvider />
          </AuthProvider>
        </PostHogProvider>
      </XstateDevLogger>
    );
    // </React.Suspense>
    // </React.StrictMode>
  }
});
