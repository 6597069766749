import { html, unsafeCSS } from 'lit';
import { property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { BaseElement, customElement } from '../../base-element';
import pageSectionStyles from './page-section.scss?inline';

@customElement('ps-page-section')
export class PageSectionWC extends BaseElement {
  static styles = unsafeCSS(pageSectionStyles);

  @property({ reflect: true }) size: 'small' | 'medium' = 'medium';

  render() {
    return html`
      <div
        class="${classMap({
          'c-page-section': true,
          [`c-page-section--${this.size}`]: this.size,
        })}"
        part="page-section"
      >
        <div class="c-page-section__inner">
          <slot></slot>
        </div>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-page-section': PageSectionWC;
  }
}
