import Fuse, { FuseOptionKey, IFuseOptions } from 'fuse.js';
import { html } from 'lit';
import { property, query, state } from 'lit/decorators.js';
import {
  BaseElement,
  customElement,
  PSCustomEvent,
  watch,
} from '../base-element';

export type TypeaheadOptions = {
  id: string;
  [key: string]: unknown;
};

@customElement('ps-typeahead')
export class TypeaheadWC<T = TypeaheadOptions> extends BaseElement {
  /**
   * Array of data to search through
   */
  @property({ type: Array }) data: T[] = [];

  /**
   * Array of keys to search through
   */
  @property({ type: Array, attribute: 'search-keys' })
  searchKeys: FuseOptionKey<T>[] = [];

  /**
   * Fuse.js options (https://www.fusejs.io/api/options.html)
   */
  @property({ type: Object }) fuseOptions: IFuseOptions<T> = {};

  @query('ps-search') private input: HTMLInputElement;

  @state() private _inputValue: string = '';

  @watch(['data', 'searchKeys'])
  handleDataChange() {
    if (this.data.length && this.searchKeys.length) {
      this.initializeFuse();
    }
  }

  private fuse: Fuse<T>;

  private initializeFuse() {
    this.fuse = new Fuse(this.data, {
      keys: this.searchKeys,
      threshold: 0.2,
      location: 15,
      includeScore: true,
      ...this.fuseOptions,
    });
  }

  private handleInputChange(event: InputEvent) {
    event.stopPropagation();
    const { value } = event.target as HTMLInputElement;
    this._inputValue = value;

    const filteredOptions = value
      ? this.fuse.search(value).map((result) => result.item)
      : this.data;

    this.emit('input', {
      detail: {
        filtered: filteredOptions,
        value,
      },
    });

    this.requestUpdate();
  }

  clear() {
    this.input.value = '';
  }

  focus() {
    this.input.focus();
  }

  blur() {
    this.input.blur();
  }

  render() {
    return html`
      <ps-search
        autocomplete="off"
        variant="outlined"
        class="c-typeahead"
        name="typeahead-input"
        placeholder="Search"
        size="large"
        data-cy="typeahead"
        .value=${this._inputValue}
        @input=${this.handleInputChange}
      ></ps-search>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-typeahead': TypeaheadWC;
  }
  enum PSElementTagNameMap {
    'ps-typeahead' = 'ps-typeahead',
  }
}

export type TypeaheadChangeEvent<T> = PSCustomEvent<
  TypeaheadWC<T>,
  { filtered: T[]; value: string }
>;
