import {
  create,
  supported,
  registerPlugin,
  FileStatus,
  FilePond as FilePondType,
  FilePondOptions,
  CaptureAttribute,
  FilePondServerConfigProps,
} from 'filepond';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import { PropertyValues } from 'lit';
import { property } from 'lit/decorators.js';
import { BaseElement, customElement } from '../../base-element';

// We need to be able to call the registerPlugin method directly so we can add plugins
export { registerPlugin, FileStatus };

registerPlugin(
  FilePondPluginFilePoster,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageResize,
  FilePondPluginImageCrop,
  FilePondPluginFileValidateType
);

// Do this once
const isSupported = supported();

// filtered methods
const filteredMethods = [
  'setOptions',
  'on',
  'off',
  'onOnce',
  'appendTo',
  'insertAfter',
  'insertBefore',
  'isAttachedTo',
  'replaceElement',
  'restoreElement',
  'destroy',
];

export enum EntityTypes {
  ACCOUNT = 'account',
  PROJECT = 'project',
  USER = 'user',
}

export type UploadedFile = Blob & {
  readonly lastModified: number;
  readonly name: string;
};

@customElement('ps-filepond-wrapper')
export class FilepondWrapperWC extends BaseElement {
  private _element: Element;

  private _input: Element;

  private _inputClone: Node | null | undefined;

  private _pond: FilePondType | undefined;

  private _allowFilesSync = true;

  @property() name?: string;

  @property({ type: Array }) acceptedFileTypes?: string[] = [];

  @property() captureMethod?: CaptureAttribute;

  @property() labelIdle?: string;

  @property() labelFileProcessingComplete?: string;

  @property() labelFileProcessing?: string;

  @property({ type: Boolean }) allowMultiple?: boolean;

  @property({ type: Boolean }) required?: boolean;

  @property() onupdatefiles?: FilePondOptions['onupdatefiles'];

  @property() onremovefile?: FilePondOptions['onremovefile'];

  @property({ type: Array }) files?: UploadedFile[] = [];

  @property({ type: Object }) server: FilePondServerConfigProps['server'] = {};

  @property({ type: Boolean }) disabled?: boolean;

  constructor() {
    super();

    const div = document.createElement('div');
    div.setAttribute('class', 'c-fp-upload-wrapper');

    const input = document.createElement('input');
    input.setAttribute('type', 'file');

    div.appendChild(input);
    this._element = div;
    this._input = input;
  }

  connectedCallback() {
    super.connectedCallback?.();
    this._inputClone = this._input.cloneNode();

    // exit here if not supported
    if (!isSupported) return;

    const {
      id,
      name,
      className,
      allowMultiple,
      required,
      captureMethod,
      acceptedFileTypes,
      labelIdle,
      onupdatefiles,
      onremovefile,
      files,
      server,
      disabled,
    } = this;

    this._input.setAttribute('id', id || '');
    this._input.setAttribute('name', name || '');
    this._input.setAttribute('class', className || '');
    // this._input.setAttribute('accept', acceptedFileTypes || '');

    if (captureMethod) {
      this._input.setAttribute('capture', captureMethod);
    }

    if (allowMultiple) {
      this._input.setAttribute('multiple', 'multiple');
    }

    if (required) {
      this._input.setAttribute('required', 'required');
    }

    const options: FilePondOptions = {
      name,
      className,
      allowMultiple,
      labelIdle,
      onupdatefiles,
      onremovefile,
      captureMethod: null,
      files,
      server,
      disabled,
      imagePreviewHeight: 56,
      stylePanelLayout: 'integrated',
      styleLoadIndicatorPosition: 'left',
      styleProgressIndicatorPosition: 'left',
      styleButtonRemoveItemPosition: 'right',
    };

    // if onupdate files is defined, make sure setFiles does not cause race condition
    if (options.onupdatefiles) {
      const cb = options.onupdatefiles;
      options.onupdatefiles = (items) => {
        this._allowFilesSync = false;
        cb(items);
      };
    }

    // Create our pond
    // https://github.com/pqina/pintura-example-filepond/blob/main/index.html
    this._pond = create(this._input, {
      ...options,

      allowRevert: true,
      allowProcess: true,
      allowRemove: true,
      allowReplace: true,
      filePosterMaxHeight: 56,
      imagePreviewHeight: 56,
      imageResizeTargetWidth: 400,
      imageResizeTargetHeight: 400,
      imageCropAspectRatio: '1:1',
      acceptedFileTypes: this.acceptedFileTypes,
      iconRemove: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
          <path d="m5 19 7-7m7-7-7 7m0 0 7 7L5 5" stroke="currentColor" stroke-width="1"></path>
        </svg>
      `,
    });

    // Reference pond methods to FilePond component instance
    Object.keys(this._pond)
      .filter((key) => !filteredMethods.includes(key))
      .forEach((key) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this[key] = this._pond?.[key];
      });
  }

  disconnectedCallback() {
    super.disconnectedCallback?.();

    // exit when no pond defined
    if (!this._pond) return;

    // This fixed <Strict> errors

    // FilePond destroy is async so we have to move FilePond to a bin element so it can no longer affect current element tree as React unmount / mount is sync
    const bin = document.createElement('div');

    if (this._pond.element) {
      bin.append(this._pond.element);
    }

    bin.id = 'bin';

    // now we call destroy so FilePond can start it's destroy logic
    this._pond.destroy();
    this._pond = undefined;

    // we re-add the original file input element so everything is as it was before
    if (this._inputClone) {
      this._element.append(this._inputClone);
    }
  }

  protected shouldUpdate(_changedProperties: PropertyValues): boolean {
    if (!this._allowFilesSync) {
      this._allowFilesSync = true;
      return false;
    }
    return true;
  }

  onProcessFile = () => {
    this.querySelector('.filepond--file-status-sub')?.addEventListener(
      'click',
      () => this._pond?.browse()
    );
  };

  protected updated(_changedProperties: PropertyValues) {
    // exit when no pond defined
    if (!this._pond) return;

    const {
      name,
      className,
      allowMultiple,
      captureMethod = null,
      acceptedFileTypes,
      labelIdle,
      labelFileProcessingComplete,
      labelFileProcessing,
      onupdatefiles,
      onremovefile,
      files,
      server,
      disabled,
    } = this;

    const options: FilePondOptions = {
      name,
      className,
      allowMultiple,
      captureMethod,
      acceptedFileTypes,
      files,
      server,
      onupdatefiles,
      onremovefile,
      labelIdle,
      labelFileProcessingComplete,
      labelFileProcessing,
      labelTapToUndo: 'Replace image',
      disabled,
      labelButtonRemoveItem: 'Replace image',
      onprocessfile: this.onProcessFile,
    };

    // update pond options based on new props
    this._pond.setOptions(options);
  }

  protected render() {
    return this._element;
  }

  createRenderRoot() {
    return this;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-filepond-wrapper': FilepondWrapperWC;
  }
  enum PSElementTagNameMap {
    'ps-filepond-wrapper' = 'ps-filepond-wrapper',
  }
}
