import { Modal, TextTitle, Stack } from '@pypestream/design-system';
import { FC } from 'react';

import { ProductCTA } from '../../product-cta';
import { ManagerContext } from '../../../xstate/manager.xstate';
import { ProductName } from '@pypestream/api-services';

interface ProductCardsModalProps {
  open: boolean;
  project?: NonNullable<ManagerContext['projects']>[number];
  onClose: () => void;
}

export const ProductCardsModal: FC<ProductCardsModalProps> = ({
  open,
  project,
  onClose,
}) => {
  return (
    <Modal open={open} size="large" onClose={onClose}>
      <TextTitle slot="header" size="small" textAlign="center">
        Select a tool to view <strong>{project?.name || ''}</strong>
      </TextTitle>
      <Stack justifyContent="center" gutter="large">
        {project?.conditionalProducts
          ?.filter(
            ({ name }: { name: ProductName | 'Studio' }) => name !== 'Studio'
          )
          .map(({ url, logo, label, productId, disabled }, index) => {
            return (
              <ProductCTA
                url={url}
                logo={logo}
                name={label}
                key={productId || index}
                disabled={disabled}
                // style={{ maxWidth: 146 }}
              />
            );
          })}
      </Stack>
    </Modal>
  );
};
