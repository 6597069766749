/* eslint-disable no-nested-ternary */
import React, { type ComponentPropsWithoutRef } from 'react';
import {
  BaseOption,
  FullOptionList,
  isOptionGroupArray,
  useValueSelector,
  type VersatileSelectorProps,
} from 'react-querybuilder';
import { Select, SelectOption } from '../../../select';

export type ValueSelectorProps = VersatileSelectorProps &
  Omit<ComponentPropsWithoutRef<typeof Select>, 'onChange' | 'defaultValue'>;

const toOptions = (arr?: FullOptionList<BaseOption>) =>
  isOptionGroupArray(arr)
    ? arr.map((og) =>
        og.options.map((opt) => (
          <SelectOption key={opt.name} value={opt.name}>
            {opt.label}
          </SelectOption>
        ))
      )
    : Array.isArray(arr)
      ? arr.map((opt) => (
          <SelectOption key={opt.name} value={opt.name}>
            {opt.label}
          </SelectOption>
        ))
      : null;

export const ValueSelector = ({
  className,
  handleOnChange,
  options,
  value,
  title,
  disabled,
  multiple,
  listsAsArrays,
  // Props that should not be in extraProps
  testID: _testID,
  rule: _rule,
  rules: _rules,
  level: _level,
  path: _path,
  context: _context,
  validation: _validation,
  operator: _operator,
  field: _field,
  fieldData: _fieldData,
  schema: _schema,
  ...extraProps
}: ValueSelectorProps) => {
  const { onChange, val } = useValueSelector({
    handleOnChange,
    listsAsArrays,
    multiple,
    value,
  });

  const modeObj = multiple ? { mode: 'multiple' as const } : {};

  return <span title={title} className={className}></span>;
};
