/* eslint-disable import/no-cycle */
import { css, html } from 'lit';
import { property, query } from 'lit/decorators.js';
import { ifDefined } from 'lit-html/directives/if-defined.js';
import { BaseElement } from '../../components/base-element/base-element';
import { customElement } from '../../components/base-element/decorators/customElement';
import { TreeItemWC } from '../../components/tree-item/tree-item.wc';
import { Org, OrgWithChildren } from './types';

import '../../components/icon/icon.wc';

import { orgImageFallback } from './org-image-fallback';

@customElement('ps-universal-nav-tree-child')
export class ChildTreeItemWC extends BaseElement {
  static styles = css`
    :host {
      display: contents;
    }
  `;

  @property({ type: Object }) child: OrgWithChildren | Org;

  @property({ type: String }) org: string | undefined;

  @query('ps-tree-item') treeItem: TreeItemWC;

  createRenderRoot() {
    return this;
  }

  render() {
    const childImage = this.child.picture;

    return html`
      <ps-tree-item
        level=${ifDefined(this.child.depth)}
        ?selected=${this.child.id === this.org}
        data-org-id=${ifDefined(this.child.id)}
        @click=${() => this.emit('menu-item-selected')}
        expanded
      >
        ${childImage
          ? html`<ps-image
              width="20"
              height="20"
              src=${childImage}
              fallback=${orgImageFallback}
              radius="small"
              slot="prefix"
            ></ps-image>`
          : html`<ps-icon name="org" slot="prefix"></ps-icon>`}
        ${this.child.name}
        ${(this.child.children || []).map(
          (item: OrgWithChildren | Org) =>
            html`<ps-universal-nav-tree-child
              .child=${item}
              slot="children"
              org=${ifDefined(this.org)}
            ></ps-universal-nav-tree-child>`
        )}
      </ps-tree-item>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-universal-nav-tree-child': ChildTreeItemWC;
  }
  enum PSElementTagNameMap {
    'ps-universal-nav-tree-child' = 'ps-universal-nav-tree-child',
  }
}
