import semver from 'semver';

export const includePrereleaseFilter = (
  version: string,
  includePrerelease: boolean
): boolean => {
  if (includePrerelease) {
    return semver.prerelease(version) !== null;
  } else {
    // production (default) only stable versions
    return semver.prerelease(version) === null;
  }
};
