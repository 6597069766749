import { arrow, computePosition, flip, offset, shift } from '@floating-ui/dom';
import { autoUpdate } from '@pypestream/floating-ui-dom';
import { property, query, state } from 'lit/decorators.js';
import { nanoid } from 'nanoid';
import { signal, Signal } from '@lit-labs/preact-signals';
import { ClickAwayController, FocusTrapController } from '../../../internal';
import { BaseElement, PSCustomEvent } from '../../base-element';
import { MenuItemSelectedEvent, MenuItemWC } from '../../menu/src/menu-item.wc';
import {
  PortalDestinationWC,
  PortalReadyEvent,
} from '../../portal/portal-destination.wc';

export type PopoverAutoCloseEvent = PSCustomEvent<
  // eslint-disable-next-line no-use-before-define
  PopoverBaseWC,
  MouseEvent
>;

declare global {
  interface GlobalEventHandlersEventMap {
    'popover-autoclose': PopoverAutoCloseEvent;
  }
}

export const popoverItemsSignal: Signal<string[]> = signal<string[]>([]);

export class PopoverBaseWC extends BaseElement {
  onClickAway = new ClickAwayController({
    host: this,
  });

  focusTrapController = new FocusTrapController(this);

  private triggerEl: Element | null;

  private parentEl: Element | null;

  private cleanup: ReturnType<typeof autoUpdate> | undefined;

  private mutationObserver: MutationObserver;

  @query('#popover-container') containerEl: HTMLElement;

  @query('#popover-arrow') arrowEl: HTMLElement;

  /**
   * The element id the popover will be anchored to
   */
  @property() trigger: string | Element;

  /**
   * Activates the positioning logic and shows the popover. When this attribute is removed, the positioning logic is torn
   * down and the popup will be hidden.
   */
  @property({ type: Boolean, reflect: true }) active = false;

  /**
   * The preferred UI width
   */
  @property({ reflect: true }) width: 'default' | 'auto' = 'default';

  /**
   * The preferred placement of the popup. Note that the actual placement will vary as configured to keep the
   * panel inside of the viewport.
   */
  @property({ reflect: true }) placement:
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'left'
    | 'left-start'
    | 'left-end' = 'top';

  /** Attaches an arrow to the popup. */
  @property({ type: Boolean }) hasArrow = false;

  /**
   * The amount of padding between the arrow and the edges of the popup. If the popup has a border-radius, for example,
   * this will prevent it from overflowing the corners.
   */
  @property({ attribute: 'arrow-padding', type: Number }) arrowPadding = 10;

  /** The distance in pixels from which to offset the panel away from its anchor. */
  @property({ type: Number }) distance = 0;

  /** The distance in pixels from which to offset the panel along its anchor. */
  @property({ type: Number }) skidding = 0;

  /**
   * When set, placement of the popup will flip to the opposite site to keep it in view.
   */
  @property({ type: Boolean }) flip = true;

  /** Moves the popup along the axis to keep it in view when clipped. */
  @property({ type: Boolean }) shift = true;

  /**
   * Hides popover on click outside
   */
  @property({ type: Boolean, reflect: true }) closeOnClickOutside = true;

  /**
   * Hides popover on esc btn click
   */
  @property({ type: Boolean, reflect: true }) closeOnEscClick = true;

  /**
   * Skips using the built-in portal and instead
   */
  @property({ type: Boolean, reflect: true, attribute: 'omit-portal' })
  omitPortal = false;

  /**
   * Enables / disables automatically closing a Popover when a nested menu item has been selected
   */
  @property({ type: Boolean, reflect: true }) stayOpenOnSelect = false;

  @property({ reflect: false }) projectedContent = this;

  /**
   * Rendering performance improvement. When enabled, the Popover will immediately move to the projected portal as soon as possible (even when closed) + not move back to the original container when closed
   */
  @property({ type: Boolean, reflect: true, attribute: 'keep-mounted' })
  keepMounted = false;

  /** Computed position of poopver container */
  @state() transform: string;

  constructor() {
    super();
    this.onPortalReady = this.onPortalReady.bind(this);
  }

  async connectedCallback() {
    super.connectedCallback();

    if (this.parentElement && !this.parentEl) {
      // Keep the reference of parent element to append `this` instance of popover once portal is closed.
      this.parentEl = this.parentElement;
    }

    this.focusTrapController.hostConnected();
    this.handleKeyDown = this.handleKeyDown.bind(this);

    document.addEventListener('portal-destination-ready', this.onPortalReady);
  }

  // eslint-disable-next-line class-methods-use-this
  onPortalReady(e: PortalReadyEvent) {
    if (e.detail.name && e.detail.name === this.popoverId) {
      // console.log(
      //   'this component portal is ready',
      //   e.detail.name,
      //   this.popoverId,
      //   this.keepMounted
      // );

      if (!this.omitPortal && this.keepMounted) {
        this.handleProjectContent(true);
      }
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.focusTrapController?.hostDisconnected();
    this.stop();
    this.removeOpenListeners();
    this.mutationObserver?.disconnect();

    document.removeEventListener(
      'portal-destination-ready',
      this.onPortalReady
    );
  }

  firstUpdated() {
    this.onClickAway.element = this.containerEl;
    this.onClickAway.callback = (e) => this.handleClickOutside(e);

    const idAttribute = this.getAttribute('id');
    if (idAttribute && !this.popoverId) {
      this.popoverId = idAttribute;
    } else if (!this.popoverId) {
      this.popoverId = `popover-${nanoid()}`;
    }

    // if (this.projectionTrigger === 'first-updated') {
    //   console.log('first-updated', this.popoverId);
    //   this.handleProjectContent(true);
    // }
  }

  private handleItemSelected = (event: MenuItemSelectedEvent) => {
    event.stopPropagation();
    const target = event.target as MenuItemWC;
    const activeElId = popoverItemsSignal.value.at(-1);

    // Hide the dropdown when a menu item is selected
    if (
      !this.stayOpenOnSelect &&
      target.tagName.toLowerCase().includes('ps-menu') &&
      this.popoverId === activeElId
    ) {
      this.hide();
    }
  };

  addOpenListeners() {
    if (this.closeOnEscClick) {
      document.addEventListener('keydown', this.handleKeyDown);
    }

    this.addEventListener('menu-item-selected', this.handleItemSelected);
  }

  removeOpenListeners() {
    if (this.closeOnEscClick) {
      document.removeEventListener('keydown', this.handleKeyDown);
    }

    this.removeEventListener('menu-item-selected', this.handleItemSelected);
  }

  handleKeyDown(event: KeyboardEvent) {
    // eslint-disable-next-line no-console
    console.log('handleKeyDown', this.active, event.key, this.closeOnEscClick);
    // Close when escape is pressed inside an open dropdown.
    if (this.active && event.key === 'Escape' && this.closeOnEscClick) {
      // if (this.contains(document.activeElement)) {
      event.stopPropagation();
      this.hide();
      // }
    }
  }

  handleClickOutside(e: MouseEvent) {
    if (!this.active) return;

    this.emit('popover-autoclose', {
      detail: e,
    }) as PopoverAutoCloseEvent;

    const activeElId = popoverItemsSignal.value.at(-1);

    if (this.closeOnClickOutside && this.id === activeElId) {
      this.hide();
    }
  }

  handleProjectContent(project: boolean) {
    if (project) {
      this.emit('update-portal-content', {
        detail: {
          destination: this.popoverId,
          content: this.projectedContent,
        },
      });
    } else {
      // console.log('close popover', this.parentEl);
      // Close projecting popover.
      this.emit('update-portal-content', {
        detail: {
          content: '',
          destination: this.popoverId,
        },
      });
      // Append this instance back to parent element.
      if (this.parentEl && !this.parentEl.contains(this.projectedContent)) {
        this.parentEl.append(this.projectedContent);
      }
    }
  }

  hide() {
    if (this.active) {
      this.active = false;
      this.emit('close');
    }
  }

  async updated(changedProps: Map<string, unknown>) {
    super.updated(changedProps);

    // Update the anchor when trigger changes
    if (changedProps.has('trigger')) {
      await this.handleTriggerChange();
    }

    // Start or stop the positioner when active changes
    if (changedProps.has('active')) {
      if (this.active) {
        if (!this.omitPortal && !this.keepMounted) {
          this.handleProjectContent(true);
        }

        setTimeout(() => {
          this.focusTrapController.activate();
        }, 10);
        this.start();
        this.addOpenListeners();
        popoverItemsSignal.value = [
          ...popoverItemsSignal.value,
          this.popoverId,
        ];

        if (this.triggerEl?.parentNode) {
          this.mutationObserver = new MutationObserver(
            this.handleTriggerMutation
          );
          this.mutationObserver.observe(this.triggerEl.parentNode, {
            childList: true,
            subtree: true,
          });
        }
      } else if (
        popoverItemsSignal.value.at(-1) === this.popoverId &&
        !this.active
      ) {
        this.focusTrapController.deactivate();
        if (!this.omitPortal && !this.keepMounted) {
          this.handleProjectContent(false);
        }
        this.stop();
        this.removeOpenListeners();
        this.mutationObserver?.disconnect();
        popoverItemsSignal.value = popoverItemsSignal.value.filter(
          (id) => id !== this.popoverId
        );
      }
    }

    // If we don't have parentElement available/connected, we should toggle popover back to disabled state.
    // This is to account route change or scenario, where parent element gets removed from html tree.
    // if (!this.parentEl?.isConnected || !this.parentEl?.checkVisibility()) {
    //   this.active = false;
    // }
  }

  @property({ type: String, reflect: true, attribute: 'popover-id' })
  popoverId: string;

  private handleTriggerMutation = (mutations: MutationRecord[]) => {
    for (const mutation of mutations) {
      if ([...mutation.removedNodes].includes(this.triggerEl as Node)) {
        this.hide();
        this.focusTrapController?.hostDisconnected();
        this.stop();
        this.removeOpenListeners();
      }
    }
  };

  private async handleTriggerChange(): Promise<void> {
    return new Promise((resolve) => {
      this.stop().then(() => {
        // temp workaround till we can tighten this up
        try {
          if (this.trigger && typeof this.trigger === 'string') {
            // Locate the anchor by id
            this.triggerEl = document.getElementById(this.trigger);

            if (!this.triggerEl) {
              this.triggerEl = (
                this.getRootNode() as ShadowRoot
              )?.getElementById(this.trigger);
            }
          } else if (this.trigger instanceof Element) {
            // Use the anchor's reference
            this.triggerEl = this.trigger;
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.warn(
            'Invalid anchor element: no anchor could be found using the anchor slot or the anchor attribute.'
          );
        }

        if (!this.triggerEl) {
          // throw new Error(
          //   'Invalid anchor element: no anchor could be found using the anchor slot or the anchor attribute.'
          // );
        } else {
          const switchActiveValue = (isActive: boolean) => {
            if (!isActive) {
              this.active = true;
              this.emit('popover-open');
            } else {
              this.active = false;
            }
          };

          // automatically add click event bindings to trigger
          this.triggerEl?.addEventListener('click', () => {
            switchActiveValue(this.active);
          });

          this.triggerEl?.addEventListener('keydown', (e) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (e.code === 32 || e.which === 13 || e.key === 'Enter') {
              e.preventDefault();
              e.stopImmediatePropagation();
              switchActiveValue(this.active);
            }
          });

          this.onClickAway.excludeElement = this.triggerEl as HTMLElement;

          this.popoverId =
            this.popoverId || this.getAttribute('id') || `popover-${nanoid()}`;
          this.setAttribute('id', this.popoverId);
          this.triggerEl.setAttribute('data-popover-id', this.popoverId);

          if (this.omitPortal) return;

          const portalDestinationEl = document.querySelector(
            `${PortalDestinationWC.tagname}[name="${this.popoverId}"]`
          );

          if (!portalDestinationEl) {
            const destinationEl = document.createElement(
              PortalDestinationWC.tagname
            );

            destinationEl.setAttribute('name', this.popoverId);
            document.body.appendChild(destinationEl);
          }
        }

        resolve();
      });
    });
  }

  private start() {
    // We can't start the positioner without an anchor
    if (!this.triggerEl) {
      return;
    }

    this.cleanup = autoUpdate(this.triggerEl, this.containerEl, () => {
      this.reposition();
    });
  }

  private async stop(): Promise<void> {
    return new Promise((resolve) => {
      if (this.cleanup) {
        this.cleanup();
        this.cleanup = undefined;
        this.removeAttribute('data-current-placement');
        requestAnimationFrame(() => resolve());
      } else {
        resolve();
      }
    });
  }

  /** Forces the popover to recalculate and reposition itself. */
  reposition() {
    // Nothing to do if the popover is inactive or the trigger doesn't exist
    if (!this.active) return;

    if (!this.triggerEl || !this.triggerEl?.isConnected) {
      setTimeout(() => {
        if (!this.triggerEl || !this.triggerEl?.isConnected) {
          this.hide();
        }
      }, 100);

      return;
    }

    //
    // NOTE: Floating UI middlewares are order dependent: https://floating-ui.com/docs/middleware
    //
    const middleware = [
      // The offset middleware goes first
      offset({ mainAxis: this.distance, crossAxis: this.skidding }),
    ];

    if (this.flip) {
      middleware.push(
        flip({
          fallbackAxisSideDirection: 'start',
          crossAxis: !this.shift,
        })
      );
    }

    if (this.shift) {
      middleware.push(
        shift({
          padding: 16,
        })
      );
    }

    if (this.hasArrow) {
      middleware.push(
        arrow({
          element: this.arrowEl,
          padding: this.arrowPadding,
        })
      );
    }

    computePosition(this.triggerEl, this.containerEl, {
      placement: this.placement,
      middleware,
      strategy: 'fixed',
    }).then(({ x, y, middlewareData, placement }) => {
      this.setAttribute('data-current-placement', placement);

      this.transform = `translate(${Math.round(x)}px, ${Math.round(y)}px)`;

      if (this.hasArrow && middlewareData.arrow) {
        const { x: arrowX, y: arrowY } = middlewareData.arrow;

        const staticSide: string = {
          top: 'bottom',
          right: 'left',
          bottom: 'top',
          left: 'right',
        }[placement.split('-')[0]] as string;

        Object.assign(this.arrowEl.style, {
          left: arrowX != null ? `${arrowX}px` : '',
          top: arrowY != null ? `${arrowY}px` : '',
          right: '',
          bottom: '',
          [staticSide]: '-4px',
        });
      }
    });
  }
}
