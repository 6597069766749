import { html, unsafeCSS } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { property } from 'lit/decorators.js';
import { BaseElement, customElement } from '../../base-element';
import pageBodyStyles from './page-body.scss?inline';

@customElement('ps-page-body')
export class PageBodyWC extends BaseElement {
  static styles = unsafeCSS(pageBodyStyles);

  @property({ reflect: true }) background: 'default' | 'none' = 'default';

  @property({ reflect: true }) spacing: 'large' | 'small' | 'none' = 'large';

  @property({ reflect: true }) width: 'xlarge' | 'medium' | 'large' = 'medium';

  @property({ reflect: true, type: Boolean, attribute: 'with-sidebar' })
  withSidebar = false;

  render() {
    return html`
      <div
        class="${classMap({
          'c-page-body': true,
          [`c-page-body--bg-${this.background}`]: this.background,
          [`c-page-body--${this.spacing}`]: this.spacing,
          [`c-page-body--with-sidebar`]: this.withSidebar,
        })}"
        part="page-body"
      >
        <ps-wrapper width=${this.width}>
          <slot></slot>
        </ps-wrapper>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-page-body': PageBodyWC;
  }
}
