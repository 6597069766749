import React from 'react';
import { Select, SelectOption, SelectWC } from '@pypestream/design-system';
import { GetPackagesByPackageTypeQuery } from '@pypestream/api-services/dist/generated/graphql.urql';

interface PackagesSelectProps {
  versions: NonNullable<
    NonNullable<
      NonNullable<GetPackagesByPackageTypeQuery['admin_']>['packages']
    >[number]['versions']
  >;
  onChange: React.ChangeEventHandler<SelectWC> | undefined;
  value?: string | undefined;
  id?: string;
  className?: string;
}

export const PackageVersionsSelect: React.FC<PackagesSelectProps> = ({
  versions,
  onChange,
  value,
  className,
}) => {
  return (
    <Select
      className={className}
      style={{ flexGrow: 1 }}
      id="triggers_select"
      onChange={onChange}
      defaultValue="latest"
      value={value}
    >
      <SelectOption value="latest">Latest</SelectOption>
      {versions
        ?.filter((v) => !!v)
        .map((item) => (
          <SelectOption key={item?.version} value={item?.id as string}>
            {item?.version}
          </SelectOption>
        ))}
    </Select>
  );
};
