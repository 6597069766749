const timezoneAbbreviationsToGMT: { [key: string]: string } = {
  EST: 'GMT-05:00', // Eastern Standard Time (USA, Canada)
  // EDT: 'GMT-04:00', // Eastern Daylight Time (USA, Canada)
  CST: 'GMT-06:00', // Central Standard Time (USA, Canada)
  // CDT: 'GMT-05:00', // Central Daylight Time (USA, Canada)
  MST: 'GMT-07:00', // Mountain Standard Time (USA, Canada)
  // MDT: 'GMT-06:00', // Mountain Daylight Time (USA, Canada)
  PST: 'GMT-08:00', // Pacific Standard Time (USA, Canada)
  // PDT: 'GMT-07:00', // Pacific Daylight Time (USA, Canada)
  AKST: 'GMT-09:00', // Alaska Standard Time (USA)
  // AKDT: 'GMT-08:00', // Alaska Daylight Time (USA)
  HST: 'GMT-10:00', // Hawaii Standard Time (USA)
  NST: 'GMT-03:30', // Newfoundland Standard Time (Canada)
  // NDT: 'GMT-02:30', // Newfoundland Daylight Time (Canada)
  GMT: 'GMT+00:00', // Greenwich Mean Time
  BST: 'GMT+01:00', // British Summer Time
  CET: 'GMT+01:00', // Central European Time
  CEST: 'GMT+02:00', // Central European Summer Time
  EET: 'GMT+02:00', // Eastern European Time
  EEST: 'GMT+03:00', // Eastern European Summer Time
  SAST: 'GMT+02:00', // South Africa Standard Time
  WET: 'GMT+00:00', // Western European Time
  WEST: 'GMT+01:00', // Western European Summer Time
  IST: 'GMT+05:30', // Indian Standard Time
  PKT: 'GMT+05:00', // Pakistan Standard Time
  ICT: 'GMT+07:00', // Indochina Time
  CST_CHINA: 'GMT+08:00', // China Standard Time
  JST: 'GMT+09:00', // Japan Standard Time
  KST: 'GMT+09:00', // Korea Standard Time
  AEST: 'GMT+10:00', // Australian Eastern Standard Time
  // AEDT: 'GMT+11:00', // Australian Eastern Daylight Time
  ACST: 'GMT+09:30', // Australian Central Standard Time
  // ACDT: 'GMT+10:30', // Australian Central Daylight Time
  AWST: 'GMT+08:00', // Australian Western Standard Time
  NZST: 'GMT+12:00', // New Zealand Standard Time
  // NZDT: 'GMT+13:00', // New Zealand Daylight Time
  ART: 'GMT-03:00', // Argentina Time
  BRT: 'GMT-03:00', // Brasilia Standard Time
  BRST: 'GMT-02:00', // Brasilia Summer Time
  CLT: 'GMT-04:00', // Chile Standard Time
  CLST: 'GMT-03:00', // Chile Summer Time
  AST: 'GMT+03:00', // Arabia Standard Time
  IRST: 'GMT+03:30', // Iran Standard Time
  // IRDT: 'GMT+04:30', // Iran Daylight Time
  GST: 'GMT+04:00', // Gulf Standard Time
  AZOT: 'GMT-01:00', // Azores Time
  CVT: 'GMT-01:00', // Cape Verde Time
  // UTC: 'GMT+00:00', // Coordinated Universal Time
};

export const transformTimezonesData = <
  T extends { identifier: string; label: string },
>(
  timeZones: T[]
): T[] => {
  const processIdentifier = (identifier: string): string => {
    const parts = identifier.split('/');
    const firstPart = parts[0];
    const secondPart = parts[1]?.replace(/_/g, ' ') || '';
    return `${firstPart}/${secondPart}`;
  };

  const mergedTimeZones = Object.values(
    timeZones.reduce(
      (acc, current) => {
        const processedIdentifier = processIdentifier(current.identifier);

        if (!acc[current.label]) {
          acc[current.label] = {
            ...current,
            identifier: processedIdentifier,
          };
        } else {
          acc[current.label].identifier +=
            `, ${processedIdentifier.split('/')[1]}`;
        }

        return acc;
      },
      {} as Record<string, T>
    )
  );

  const processLabel = (label: string): string[] => {
    const regex =
      /\((GMT[+-]\d{2}:\d{2})\)\s*([A-Za-z\s]+(?:Standard Time|Time)?)\s*(?:-\s*(.*))?/;
    const match = label.match(regex);

    if (match) {
      const gmt = match[1];
      const timeType = match[2];
      const location = match[3] ? match[3] : '';
      return location ? [gmt, timeType, location] : [gmt, timeType];
    }

    return [];
  };

  const processIdentifierForSearchKeys = (identifier: string): string[] => {
    const parts = identifier.split('/');
    const firstPart = parts[0];
    const remainingParts =
      parts[1]?.split(',').map((part) => part.trim()) || [];
    const result = [firstPart, ...remainingParts];

    return result;
  };

  const updatedTimeZones = mergedTimeZones.map((timeZone) => {
    const labelParts = processLabel(timeZone.label);
    const identifierParts = processIdentifierForSearchKeys(timeZone.identifier);
    let searchKeys = [...labelParts, ...identifierParts];

    labelParts.forEach((gmt, index) => {
      if (index === 0) {
        const matchingAbbreviations = Object.keys(
          timezoneAbbreviationsToGMT
        ).filter((key) => timezoneAbbreviationsToGMT[key] === gmt);

        if (matchingAbbreviations.length > 0) {
          searchKeys = [...searchKeys, ...matchingAbbreviations];
        }
      }
    });

    return {
      ...timeZone,
      searchKeys,
    };
  });

  return updatedTimeZones;
};
