type UrqlGqlLimitedCandidateModule =
  typeof import('@pypestream/api-services/urql.limited-access.candidate').urqlGqlLimitedCandidate;

type UrqlGqlLimitedProdModule =
  typeof import('@pypestream/api-services/urql.limited-access.prod').urqlGqlLimitedProd;

type UrqlGqlLimitedNonProdModule =
  typeof import('@pypestream/api-services/urql.limited-access.nonprod').urqlGqlLimitedNonProd;

type UrqlGqlLimitedMainModule =
  typeof import('@pypestream/api-services/urql.limited-access.main').urqlGqlLimitedMain;

type UrqlGqlLimitedClient = Promise<
  | UrqlGqlLimitedCandidateModule
  | UrqlGqlLimitedProdModule
  | UrqlGqlLimitedNonProdModule
  | UrqlGqlLimitedMainModule
>;

export const urqlClient = async (): UrqlGqlLimitedClient => {
  let env = '';
  if (window.location.host.endsWith('pypestream.com')) {
    env = 'prod';
  } else if (window.location.host.endsWith('nonprod.pypestream.dev')) {
    env = 'nonprod';
  } else if (env === undefined) {
    env = 'candidate';
  }

  if (env === 'prod') {
    const module = await import(
      '@pypestream/api-services/urql.limited-access.prod'
    );
    return module.urqlGqlLimitedProd;
  }
  if (env === 'nonprod' || env === 'uat') {
    const module = await import(
      '@pypestream/api-services/urql.limited-access.nonprod'
    );
    return module.urqlGqlLimitedNonProd;
  }
  if (env === 'main') {
    const module = await import(
      '@pypestream/api-services/urql.limited-access.main'
    );
    return module.urqlGqlLimitedMain;
  }

  const module = await import(
    '@pypestream/api-services/urql.limited-access.candidate'
  );
  return module.urqlGqlLimitedCandidate;
};
