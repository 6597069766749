import {
  AppletInstance,
  DomainTypeEnum,
  RoleSource,
} from '@pypestream/api-services';
import {
  Account,
  CreateAppletInstanceMutation,
  CreateAppletInstanceMutationVariables,
  CreateProjectMutationVariables,
  GetAccountsQuery,
  GetAppletTemplatesQuery,
  GetCountriesQuery,
  GetLanguagesQuery,
  GetProductsQuery,
  GetProjectsQuery,
  GetTimeZonesQuery,
  GetUserSettingsQuery,
  UpdateAppletInstanceMutation,
  UpdateAppletInstanceMutationVariables,
  UpdateUserMutation,
  urqlGql,
  User,
  UserProject,
  UserStatus,
  Team,
  GetAppletInstancesByProjectIdQuery,
  GetAuditLogsQuery,
  GetChildAccountsQuery,
  GetUsersQuery,
  QueryMetadata,
  CreateAccountMutationVariables,
  CreateAccountMutation,
  UpdateAccountMutationVariables,
  UpdateAccountMutation,
} from '@pypestream/api-services/urql';
import { changeLanguage } from '@pypestream/translations';
import {
  sort,
  transformProfilePicture,
  transformTimezonesData,
} from '@pypestream/utils';
import { compact, flatten, uniq } from 'lodash';
import { TreeUtils } from 'simple-tree-utils';
import { assign, Interpreter, Machine, Sender, Typestate } from 'xstate';
import { AutoSaveReturnValue } from '@pypestream/design-system';

import { MyAccountFormValues } from '../hooks';
import {
  getProducts,
  GetProducts,
  haveErrors,
  initialTools,
  Product,
} from '../utils';
import {
  globalAppService,
  sendManagerEvent,
  sendUserEvent,
} from './app.xstate';
import {
  GLOBAL_APP_SUB_MACHINE_IDS,
  GlobalAppContext,
  sendUserMessage,
  SharedEvents,
} from './app.xstate-utils';
import { UserInterpreter } from './user.xstate';
import { getXstateUtils, MachineStateSchemaPaths } from './xstate.utils';

// @todo: start moving these pre-formatted types into the GraphQL package (API services)
export type Language = NonNullable<
  NonNullable<NonNullable<GetLanguagesQuery['admin_']>>['locales']
>[0];

export type LocalizationSettingsConfig = NonNullable<
  GetLanguagesQuery['admin_']
>['localizationSettingsConfig'];

export type Languages = Language[];

export type Country = NonNullable<
  NonNullable<NonNullable<GetCountriesQuery['admin_']>>['countries']
>[0];

export type Countries = Country[];

export type Timezone = NonNullable<
  NonNullable<NonNullable<GetTimeZonesQuery['admin_']>>['timeZones']
>[0] & {
  searchKeys?: string[];
};

export type Timezones = Timezone[];

const treeUtils = new TreeUtils({
  idProp: 'id', // the key of a unique identifier for an object (source object)
  parentIdProp: 'parentId', // the key of a unique parent identifier (source object)
  childrenProp: 'children', // the key, where child nodes are stored (destination object tree)
});

export type Org = NonNullable<
  NonNullable<
    NonNullable<NonNullable<GetAccountsQuery>['admin_']>['currentUser']
  >['defaultAccount']
> & {
  selected?: boolean;
  children?: Org[];
  depth?: number;
};

export type OrgWithChildren = Org & {
  children: [];
};

export type AgentSettings = {
  enabled: boolean;
  agentAssist?: {
    accountId?: number;
  };
};

type ProjectSettingsUpdated = {
  productId?: string;
  projectId?: string;
};

export type Project = Omit<
  NonNullable<
    NonNullable<
      NonNullable<
        NonNullable<NonNullable<GetProjectsQuery['admin_']>['projects']>['rows']
      >[0]
    >
  >,
  'projectProductSettings'
> & {
  projectProductSettings?: ProjectSettingsUpdated[];
};

type ProductRoles = NonNullable<
  NonNullable<GetUserSettingsQuery['admin_']>['userProductRoles']
>;

type UserProductRoles = {
  hasAgentAssistRoles?: ProductRoles['hasAgentAssistRoles'];
  hasAnalyticsRoles?: ProductRoles['hasAnalyticsRoles'];
  hasOrganizationRoles?: ProductRoles['hasOrganizationRoles'];
  agentAssistRolesSource?: ProductRoles['agentAssistRolesSource'];
  analyticsRolesSource?: ProductRoles['analyticsRolesSource'];
  organizationRolesSource?: ProductRoles['organizationRolesSource'];
};

export type UserAssignedRole = {
  id?: string;
  name?: string;
};

export interface ManagerContext {
  orgs?: Omit<Org, 'allChildAccounts'>[];
  orgTree?: Org[];

  errors?: Error[];

  userInfo: {
    id?: User['id'];
    // kratosId?: string;
    defaultOrgId?: Account['id'];
    email?: User['userName'];
    productRoles?: UserProductRoles;
    recommendedAuthMethod?: string;
    registeredAuthMethods?: string[];
    isPypestreamEmployee?: boolean;
    status?: User['status'];
    canManageAccess?: boolean;
    defaultLanguage?: string;
  };

  products?: NonNullable<GetProductsQuery['admin_']>['allToolProducts'];
  allProducts?: NonNullable<GetProductsQuery['admin_']>['allProducts'];

  selectedOrgId?: Account['id'];
  selectedProjectModal?: Project['projectId'];

  selectedProject?: Project['projectId'];

  tools: Product[];
  projects?: (Project & {
    selectedProjectURL?: string;
    conditionalProducts?: Product[];
    hasAvailableProduct?: boolean;
  })[];

  applets?: NonNullable<
    NonNullable<
      NonNullable<
        NonNullable<GetAppletInstancesByProjectIdQuery['admin_']>['projectById']
      >['projectReleaseChannelConfig']
    >[number]['appletInstances']
  >;

  userProjectIds: string[];
  createProjectTools?: Product[];

  getProducts: GetProducts;

  routes: {
    home: string;
    myAccount: string;
    users: string;
    teams: string;
    roles: string;
    projects: string;
    orgs: string;
    logs: string;
    admin: string;
    superAdmin: string;
    wipUsers: string;
    wipTeams: string;
  };
  appletTemplates: NonNullable<GetAppletTemplatesQuery['admin_']>['applets'];

  countries: Countries;
  languages: Languages;
  timezones: Timezones;
  localizationSettingsConfig: LocalizationSettingsConfig;

  users: NonNullable<NonNullable<GetUsersQuery['admin_']>['users']>['rows'];
  selectedUser?: User['id'];
  teams: (Pick<Team, 'id' | 'name' | 'description' | 'updatedAt'> & {
    assignedMembers?: Pick<
      NonNullable<Team['assignedMembers']>[0],
      'id' | 'firstName' | 'lastName' | 'picture'
    >[];
  })[];
  selectedTeam?: Team['id'];
  featureFlags?: GlobalAppContext['featureFlags'];
  logs: NonNullable<NonNullable<GetAuditLogsQuery['admin_']>['auditLogs']>;
  logsCache: Record<
    string,
    NonNullable<NonNullable<GetAuditLogsQuery['admin_']>['auditLogs']>
  >;
  childOrgs: {
    rows?: NonNullable<
      NonNullable<GetChildAccountsQuery['admin_']>['accounts']
    >['rows'];
    count?: number;
    currentOrg?: NonNullable<GetChildAccountsQuery['admin_']>['accountById'];
  };
  selectedChildOrg?: Account['id'];
}

export interface ManagerState {
  states: {
    orgRelated: {
      states: {
        idle: Record<string, unknown>;
        checkingUserInfo: Record<string, unknown>;
        ready: {
          states: {
            currentOrg: {
              states: {
                notSelected: Record<string, unknown>;
                selected: Record<string, unknown>;
              };
            };
            orgs: {
              states: {
                idle: Record<string, unknown>;
                loading: Record<string, unknown>;
                error: Record<string, unknown>;
                loaded: Record<string, unknown>;
                adding: Record<string, unknown>;
                updating: Record<string, unknown>;
                deleting: Record<string, unknown>;
                added: Record<string, unknown>;
                updated: Record<string, unknown>;
                deleted: Record<string, unknown>;
              };
            };
            projects: {
              states: {
                waitingForOrg: Record<string, unknown>;
                idle: Record<string, unknown>;
                loading: Record<string, unknown>;
                transforming: Record<string, unknown>;
                deleting: Record<string, unknown>;
                adding: Record<string, unknown>;
                transformAfterDelay: Record<string, unknown>;
                updating: Record<string, unknown>;
                error: Record<string, unknown>;
                loaded: Record<string, unknown>;
              };
            };

            applets: {
              states: {
                idle: Record<string, unknown>;
                loading: Record<string, unknown>;
                deleting: Record<string, unknown>;
                adding: Record<string, unknown>;
                updating: Record<string, unknown>;
                error: Record<string, unknown>;
                loaded: Record<string, unknown>;
                transformAfterDelay: Record<string, unknown>;
              };
            };

            userInfo: {
              states: {
                idle: Record<string, unknown>;
                fetching: Record<string, unknown>;
                refetchUserSettings: Record<string, unknown>;
                refetched: Record<string, unknown>;
                updating: Record<string, unknown>;
                updated: Record<string, unknown>;
                error: Record<string, unknown>;
              };
            };

            routes: {
              states: {
                idle: Record<string, unknown>;
                updating: Record<string, unknown>;
                updated: Record<string, unknown>;
              };
            };
            projectDetails: {
              states: {
                idle: Record<string, unknown>;
                name: {
                  states: {
                    loading: Record<string, unknown>;
                    success: Record<string, unknown>;
                    error: Record<string, unknown>;
                  };
                };
                description: {
                  states: {
                    loading: Record<string, unknown>;
                    success: Record<string, unknown>;
                    error: Record<string, unknown>;
                  };
                };
                countryId: {
                  states: {
                    loading: Record<string, unknown>;
                    success: Record<string, unknown>;
                    error: Record<string, unknown>;
                  };
                };
                localeId: {
                  states: {
                    loading: Record<string, unknown>;
                    success: Record<string, unknown>;
                    error: Record<string, unknown>;
                  };
                };
                timeZoneId: {
                  states: {
                    loading: Record<string, unknown>;
                    success: Record<string, unknown>;
                    error: Record<string, unknown>;
                  };
                };
                projectIcon: {
                  states: {
                    loading: Record<string, unknown>;
                    success: Record<string, unknown>;
                    error: Record<string, unknown>;
                  };
                };
              };
            };
            users: {
              states: {
                idle: Record<string, unknown>;
                loading: Record<string, unknown>;
                loaded: Record<string, unknown>;
                error: Record<string, unknown>;
              };
            };
            teams: {
              states: {
                idle: Record<string, unknown>;
                loading: Record<string, unknown>;
                loaded: Record<string, unknown>;
                error: Record<string, unknown>;
              };
            };
            logs: {
              states: {
                idle: Record<string, unknown>;
                loading: Record<string, unknown>;
                loaded: Record<string, unknown>;
                error: Record<string, unknown>;
              };
            };
            childOrgs: {
              states: {
                idle: Record<string, unknown>;
                loading: Record<string, unknown>;
                loaded: Record<string, unknown>;
                error: Record<string, unknown>;
              };
            };
          };
        };
      };
    };
    global: {
      states: {
        countries: {
          states: {
            loading: Record<string, unknown>;
            loaded: Record<string, unknown>;
            error: Record<string, unknown>;
          };
        };
        appletTemplates: {
          states: {
            loading: Record<string, unknown>;
            loaded: Record<string, unknown>;
            error: Record<string, unknown>;
          };
        };
        languages: {
          states: {
            loading: Record<string, unknown>;
            loaded: Record<string, unknown>;
            error: Record<string, unknown>;
          };
        };
        timezones: {
          states: {
            loading: Record<string, unknown>;
            loaded: Record<string, unknown>;
            error: Record<string, unknown>;
          };
        };
        products: {
          states: {
            idle: Record<string, unknown>;
            loading: Record<string, unknown>;
            error: Record<string, unknown>;
            loaded: Record<string, unknown>;
          };
        };
      };
    };
    superAdmin: {
      states: {
        idle: Record<string, unknown>;
        dataBaseSnapshot: {
          states: {
            loading: Record<string, unknown>;
            error: Record<string, unknown>;
            loaded: Record<string, unknown>;
          };
        };
      };
    };
  };
}

export type ManagerEvents =
  | SharedEvents
  | {
      type: 'reset.errors';
    }
  | {
      type: 'loggedIn';
    }
  | {
      type: 'reset.context';
      data: ManagerContext;
    }
  | {
      type: 'manager.updateUserInfo';
      data: ManagerContext['userInfo'] & {
        projectIds: string[];
      };
    }
  | {
      type: 'manager.selectProjectModal';
      id: ManagerContext['selectedProjectModal'];
    }
  | {
      type: 'manager.deselectProjectModal';
    }
  | {
      type: 'manager.deleteProject';
      projectId: UserProject['projectId'];
    }
  | {
      type: 'manager.selectProject';
      id: UserProject['projectId'];
    }
  | ({
      type: 'manager.addProject';
      callback?: (res: boolean) => void;
    } & Omit<CreateProjectMutationVariables, 'accountId'>)
  | ({
      type: 'manager.addOrg';
      callback?: (res: boolean) => void;
    } & Omit<CreateAccountMutationVariables, 'parentAccountId'>)
  | ({
      type: 'manager.updateOrg';
      callback?: (res: boolean) => void;
    } & Omit<UpdateAccountMutationVariables, 'id'>)
  | ({
      type: 'manager.deleteOrg';
      callback?: (res: boolean) => void;
    } & Pick<Account, 'id'>)
  | ({
      type: 'manager.addApplet';
    } & CreateAppletInstanceMutationVariables)
  | ({
      type: 'manager.updateApplet';
    } & UpdateAppletInstanceMutationVariables)
  | ({
      type: 'manager.deleteApplet';
    } & Pick<AppletInstance, 'id'>)
  | {
      type: 'manager.updateProject';
      projectId: UserProject['projectId'];
      name?: string;
      description?: string;
      countryId?: string;
      localeId?: string;
      timeZoneId?: string;
      productIds?: string[];
      projectIcon?: string;
      releaseChannelConfig?: {
        domains: {
          type: DomainTypeEnum;
          url: string;
        }[];
        description: string;
        name: string;
      }[];
    }
  | {
      type:
        | 'manager.updateProject.name'
        | 'manager.updateProject.description'
        | 'manager.updateProject.projectIcon'
        | 'manager.updateProject.productIds'
        | 'manager.updateProject.countryId'
        | 'manager.updateProject.localeId'
        | 'manager.updateProject.timeZoneId';
      projectId: UserProject['projectId'];
      data: AutoSaveReturnValue;
    }
  | {
      type: 'manager.updateProject.releaseChannelConfig';
      projectId: UserProject['projectId'];
      releaseChannelConfig?: {
        domains: {
          type: DomainTypeEnum;
          url: string;
        }[];
        description: string;
        name: string;
      }[];
    }
  | {
      type: 'manager.selectOrgId';
      orgId: ManagerContext['selectedOrgId'];
    }
  | {
      type: 'manager.updateUserPreferences';
      values: MyAccountFormValues;
    }
  | {
      type: 'manager.refetchUserSettings';
      orgId: ManagerContext['selectedOrgId'];
    }
  | {
      type: 'manager.transformProjectsSettings';
    }
  | {
      type: 'manager.createDbSnapshot';
    }
  | {
      type: 'manager.users.loadUsers';
    }
  | {
      type: 'manager.selectUser';
      id: User['id'];
    }
  | {
      type: 'manager.teams.loadTeams';
    }
  | {
      type: 'manager.selectTeam';
      id: Team['id'];
    }
  | {
      type: 'manager.setFeatureFlags';
      values: GlobalAppContext['featureFlags'];
    }
  | { type: 'manager.logs.loadLogs'; queryMetadata: QueryMetadata }
  | {
      type: 'manager.childOrgs.loadChildOrgs';
    }
  | {
      type: 'manager.selectChildOrg';
      id: Account['id'];
    };

export interface ManagerTypestates extends Typestate<ManagerContext> {
  context: ManagerContext;
  value: MachineStateSchemaPaths<ManagerState['states']>;
}

export type ManagerInterpreter = Interpreter<
  ManagerContext,
  ManagerState,
  ManagerEvents,
  ManagerTypestates
>;

const { createInvokablePromise, createXstateHooks: createManagerXstateHooks } =
  getXstateUtils<
    ManagerContext,
    ManagerEvents,
    ManagerTypestates,
    ManagerState
  >();

export { createManagerXstateHooks };

// @todo: figure out why we can't easily (fully) cancel the subscription after running just once
let hasUpdateUserInfoSubRun = false;

export const managerMachine = Machine<
  ManagerContext,
  ManagerState,
  ManagerEvents
>({
  id: 'manager',
  predictableActionArguments: true,
  preserveActionOrder: true,
  invoke: {
    id: 'managerObserver',
    src: (ctx) => async (sendEvent: Sender<ManagerEvents>) => {
      // remove globalAppService subscription and featureFlags from manager context when managerProjectIdForContactCenter will be removed
      globalAppService.subscribe(async (state) => {
        const globalContext = state?.context;

        if (!globalContext) return;

        const { featureFlags } = globalContext;

        sendEvent({
          type: 'manager.setFeatureFlags',
          values: featureFlags,
        });
      });

      const userService = globalAppService.children.get(
        GLOBAL_APP_SUB_MACHINE_IDS.user
      ) as UserInterpreter;

      const userServiceSubscription = userService?.subscribe(async (state) => {
        const userContext = state?.context;

        const id = userContext?.user?.id;
        if (!id) return;

        if (hasUpdateUserInfoSubRun === false) {
          hasUpdateUserInfoSubRun = true;
          const defaultOrgId = userContext?.user?.defaultAccount?.defaultOrgId;
          const email = userContext?.user?.email;
          const status = userContext?.user?.status;
          const projectIdsAssignedThroughTeams = compact(
            userContext?.user?.assignedTeams
              ?.map(({ assignedProjects }) =>
                assignedProjects?.map((assignedProject) => assignedProject.id)
              )
              .flat()
          );

          sendEvent({
            type: 'manager.updateUserInfo',
            data: {
              ...ctx?.userInfo,
              id,
              // kratosId,
              defaultOrgId,
              email,
              status,
              isPypestreamEmployee:
                email?.endsWith('@pypestream.com') ||
                email?.endsWith('@pypestream.mailisk.net'),
              projectIds: projectIdsAssignedThroughTeams,
            },
          });
        }
      });

      if (hasUpdateUserInfoSubRun) {
        userServiceSubscription.unsubscribe();
      }
    },
  },
  context: {
    userInfo: {},
    selectedOrgId: undefined,
    projects: [],
    userProjectIds: [],
    errors: [],
    tools: initialTools,
    getProducts,
    routes: {
      home: '/',
      myAccount: '/my-account',
      users: '/users-management',
      teams: '/teams-management',
      roles: '/roles-management',
      projects: '/projects',
      orgs: '/child-orgs',
      logs: '/audit-logs',
      superAdmin: '/super-admin',
      wipUsers: '/users',
      wipTeams: '/teams',
      admin: import.meta.env.FE_ADMIN_URL,
    },
    appletTemplates: [],
    countries: [],
    languages: [],
    localizationSettingsConfig: undefined,
    timezones: [],
    users: [],
    teams: [],
    logs: {
      count: 0,
      rows: [],
    },
    logsCache: {},
    childOrgs: {
      currentOrg: undefined,
      rows: [],
      count: 0,
    },
  },
  type: 'parallel',
  on: {
    loggedIn: {
      actions: assign((ctx) => {
        console.log('logged in!');
        // ctx.selectedOrgId = orgId;
        return ctx;
      }),
    },
    'manager.selectProject': {
      actions: assign((ctx, { id }) => {
        ctx.selectedProject = id;
        return ctx;
      }),
      cond: (ctx, event) =>
        event.type === 'manager.selectProject' &&
        event.id !== ctx.selectedProject,
    },
    'manager.updateUserInfo': {
      target: '#checkingUserInfo',
      actions: assign((ctx, { data: { projectIds, ...userInfo } }) => {
        ctx.userInfo = userInfo;
        ctx.userProjectIds = projectIds;
        return ctx;
      }),
    },
    'reset.context': {
      actions: assign((ctx, event) => {
        ctx.errors = [];
        return ctx;
      }),
      target: '#idle',
    },
    'manager.transformProjectsSettings': {
      target: '#projects.transforming',
    },

    'manager.createDbSnapshot': {
      target: '#dataBaseSnapshot.loading',
    },
    'manager.updateProject.name': {
      target: '#projectNameUpdating',
    },
    'manager.updateProject.description': {
      target: '#projectDescriptionUpdating',
    },
    'manager.updateProject.countryId': {
      target: '#projectCountryIdUpdating',
    },
    'manager.updateProject.localeId': {
      target: '#projectLocaleIdUpdating',
    },
    'manager.updateProject.timeZoneId': {
      target: '#projectTimeZoneIdUpdating',
    },
    'manager.updateProject.projectIcon': {
      target: '#projectIconUpdating',
    },
    'manager.selectUser': {
      actions: assign((ctx, { id }) => {
        ctx.selectedUser = id;
        return ctx;
      }),
    },
    'manager.selectTeam': {
      actions: assign((ctx, { id }) => {
        ctx.selectedTeam = id;
        return ctx;
      }),
    },
    'manager.setFeatureFlags': {
      actions: assign((ctx, { values }) => {
        ctx.featureFlags = values;
        return ctx;
      }),
    },
    'manager.selectChildOrg': {
      actions: assign((ctx, { id }) => {
        ctx.selectedChildOrg = id;
        return ctx;
      }),
    },
  },
  states: {
    orgRelated: {
      type: 'compound',
      initial: 'idle',
      states: {
        idle: {
          id: 'idle',
        },
        checkingUserInfo: {
          id: 'checkingUserInfo',
          always: [
            {
              target: 'ready',
              cond: (context, event) => context.userInfo?.id !== undefined,
            },
            {
              target: 'idle',
            },
          ],
        },
        ready: {
          id: 'ready',
          type: 'parallel',
          states: {
            currentOrg: {
              id: 'currentOrg',
              initial: 'notSelected',
              on: {
                'manager.selectOrgId': {
                  actions: assign((ctx, { orgId }) => {
                    ctx.selectedOrgId = orgId || ctx.userInfo.defaultOrgId;
                    ctx.selectedProjectModal = undefined;
                    return ctx;
                  }),
                  target: '.selected',
                },
              },
              states: {
                notSelected: {},
                selected: {},
              },
            },
            orgs: {
              initial: 'idle',
              id: 'orgs',
              states: {
                idle: {
                  // entry: () => {
                  //   console.log('ready to load org info!');
                  // },
                  always: {
                    target: 'loading',
                    cond: (context, event) =>
                      context.userInfo?.id !== undefined,
                  },
                },
                loading: {
                  id: 'orgLoading',
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<void | ManagerContext['orgs']> => {
                      const { data, error } = await urqlGql.getAccounts();

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      let orgs: Omit<Org, 'allChildAccounts'>[] = [];

                      const defaultAccount: Org | undefined =
                        data?.admin_?.currentUser?.defaultAccount;

                      if (defaultAccount) {
                        const { allChildAccounts, ...orgProps } =
                          defaultAccount;
                        orgs = [orgProps].concat(allChildAccounts || []);
                      }

                      return orgs;
                    },
                    onDoneTarget: 'loaded',
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.orgs = data;

                        const orgsWithChildren: OrgWithChildren[] =
                          treeUtils.list2Tree(data) as OrgWithChildren[];

                        orgsWithChildren.map((foo) => {
                          if ('id' in foo) {
                            foo.selected = foo?.id === ctx.selectedOrgId;
                          }

                          if ('children' in foo === undefined) {
                            foo.children = [];
                          }

                          return foo;
                        }) as OrgWithChildren[];

                        // console.log(orgsWithChildren);

                        const maxDepth = (root: OrgWithChildren) => {
                          // if root is undefined, depth is 0
                          if (!root) return 0;
                          // variable to store the maximum levels
                          let max = 0;
                          // helper function to traverse the tree
                          // recursively increment the levels by one
                          const dfs = (
                            node: OrgWithChildren,
                            levels: number
                          ) => {
                            // console.log(node.name, levels);

                            node.depth = levels;
                            // compare levels and max to pass the maximum levels
                            if (levels > max) max = levels;
                            // traverse all children of the current node
                            for (const child of node.children) {
                              // increment the levels by one
                              dfs(child, levels + 1);
                            }
                          };
                          // when root is not null, the tree has at least one level,
                          // so we pass down 1
                          dfs(root, 1);

                          // return the maximum levels
                          return max;
                        };

                        maxDepth(orgsWithChildren[0]);

                        ctx.orgTree = orgsWithChildren;
                      }
                    },
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error fetching user orgs: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                error: {},
                loaded: {
                  on: {
                    'manager.selectOrgId': {
                      target: '#orgLoading',
                    },
                    'manager.addOrg': {
                      target: 'adding',
                    },
                    'manager.updateOrg': {
                      target: 'updating',
                    },
                    'manager.deleteOrg': {
                      target: 'deleting',
                    },
                  },
                },
                adding: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<void | {
                      account: NonNullable<
                        CreateAccountMutation['admin_']
                      >['createAccount'];
                      callback: ((res: boolean) => void) | undefined;
                    }> => {
                      if (
                        event.type !== 'manager.addOrg' ||
                        !event.accountName ||
                        !ctx.selectedOrgId
                      )
                        return;

                      const {
                        accountName,
                        picture,
                        accountManagerId,
                        securitySettings,
                      } = event;

                      const { data, error } = await urqlGql.createAccount({
                        parentAccountId: ctx.selectedOrgId,
                        accountName,
                        picture,
                        accountManagerId,
                        securitySettings,
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      return {
                        account: data?.admin_?.createAccount,
                        callback: event.callback,
                      };
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (!data || !data.account) {
                        throw new Error(
                          'Returned data from createAccount is undefined'
                        );
                      }
                      ctx.orgs?.push(data.account);
                      if (data.callback) {
                        data.callback(true);
                      }
                    },
                    onDoneTarget: 'added',
                    onErrorTarget: 'loaded',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error fetching orgs: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                added: {
                  after: {
                    100: 'loaded',
                  },
                },
                updating: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<void | {
                      updateAccount: NonNullable<
                        UpdateAccountMutation['admin_']
                      >['updateAccount'];
                      callback: ((res: boolean) => void) | undefined;
                    }> => {
                      if (
                        event.type !== 'manager.updateOrg' ||
                        !ctx.selectedOrgId
                      )
                        return;

                      const {
                        name,
                        picture,
                        accountManagerId,
                        securitySettings,
                        generalSettings,
                        resourceLimitSettings,
                      } = event;
                      const { data, error } = await urqlGql.updateAccount({
                        id: ctx.selectedOrgId,
                        name,
                        picture,
                        accountManagerId,
                        securitySettings,
                        generalSettings,
                        resourceLimitSettings,
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      return {
                        updateAccount: data?.admin_?.updateAccount,
                        callback: event.callback,
                      };
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined && !!data.updateAccount) {
                        const index = ctx.orgs?.findIndex(
                          (a) => a.id === data.updateAccount?.id
                        );
                        if (index === undefined) {
                          throw new Error(
                            'Returned applet from UpdateAppletInstance Mutation is not found'
                          );
                        }
                        if (index >= 0) {
                          ctx.orgs = ctx.orgs?.with(index, data.updateAccount);
                          if (data.callback) {
                            data.callback(true);
                          }
                        }
                      }
                    },
                    onDoneTarget: 'updated',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error updating org's data: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                    onDoneActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, { data }) {
                          sendUserMessage({
                            type: 'success',
                            text: `Organization ${data?.updateAccount?.name} updated successfully`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                updated: {
                  after: {
                    100: 'loaded',
                  },
                },
                deleting: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<{
                      id: Account['id'];
                      callback: ((res: boolean) => void) | undefined;
                    } | void> => {
                      if (event.type !== 'manager.deleteOrg' || !event.id)
                        return;

                      if (!ctx.selectedOrgId) {
                        throw new Error(
                          'missing info needed to delete project! (projectId or selectedOrgId)'
                        );
                      }

                      if (ctx.userInfo.defaultOrgId === ctx.selectedOrgId) {
                        throw new Error('cannot delete default organization!');
                      }

                      const { data, error } = await urqlGql.deleteAccount({
                        id: event.id,
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }
                      return {
                        id: event.id,
                        callback: event.callback,
                      };
                    },
                    onDoneActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, { data }) {
                          sendUserMessage({
                            type: 'success',
                            text: `The organization deleted successfully`,
                            autoClose: 3000,
                          });
                          if (data && data.callback) {
                            data.callback(true);
                          }
                        },
                      },
                    ],
                    onDoneTarget: 'deleted',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error fetching user orgs: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                deleted: {
                  after: {
                    100: 'loaded',
                  },
                },
              },
            },
            projects: {
              id: 'projects',
              initial: 'waitingForOrg',
              states: {
                waitingForOrg: {
                  on: {
                    'manager.selectOrgId': {
                      target: 'idle',
                    },
                  },
                },
                idle: {
                  entry: (ctx) => {
                    // console.log('ready to load projects info!', ctx.selectedOrgId);
                  },
                  always: [
                    {
                      target: '#loadingProjects',
                      cond: (ctx, event, data) => {
                        const cond = Boolean(
                          ctx.selectedOrgId !== undefined &&
                            ctx.userInfo.id !== undefined &&
                            ctx.userInfo?.productRoles &&
                            ctx.products?.length
                        );

                        return cond;
                      },
                    },
                  ],
                },
                loading: {
                  id: 'loadingProjects',
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      _event
                    ): Promise<{
                      projects: ManagerContext['projects'];
                      userProjectIds: string[];
                    }> => {
                      if (
                        ctx.userInfo.id === undefined ||
                        ctx.selectedOrgId === undefined
                      ) {
                        throw Error('missing info needed to get projects!');
                      }

                      const { data, error } = await urqlGql.getProjects({
                        userId: ctx.userInfo.id,
                        accountId: ctx.selectedOrgId,
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      const allProjects = compact(data?.admin_?.projects?.rows);
                      const userProjectIds = compact(
                        data?.admin_?.userProjects?.rows
                      );

                      return {
                        projects: allProjects,
                        userProjectIds: userProjectIds.length
                          ? uniq(
                              compact(
                                userProjectIds
                                  .map((item) => item.project?.id)
                                  .concat(ctx.userProjectIds)
                              )
                            )
                          : [...ctx.userProjectIds],
                      };
                    },
                    onDoneTarget: '#transforming',
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.projects = data.projects;
                        ctx.userProjectIds = data.userProjectIds;
                      }

                      return ctx;
                    },
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error fetching user projects: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                error: {},
                adding: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<void | {
                      projects: ManagerContext['projects'];
                      userInfo: ManagerContext['userInfo'];
                      newProjectId: string | undefined;
                      callback: ((res: boolean) => void) | undefined;
                    }> => {
                      if (
                        event.type !== 'manager.addProject' ||
                        !event.name ||
                        !ctx.selectedOrgId
                      )
                        return;

                      const {
                        countryId,
                        localeId,
                        timeZoneId,
                        name,
                        productIds,
                        description,
                        projectReleaseChannelConfigs,
                        projectIconId,
                      } = event;

                      const { data, error } = await urqlGql.createProject({
                        name,
                        accountId: ctx.selectedOrgId,
                        productIds: productIds || [],
                        description: description || '',
                        countryId: countryId || undefined,
                        localeId: localeId || undefined,
                        timeZoneId: timeZoneId || undefined,
                        projectReleaseChannelConfigs,
                        projectIconId: projectIconId
                          ? transformProfilePicture(projectIconId)
                          : '',
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      if (data?.admin_?.createProject === undefined) {
                        return {
                          projects: ctx.projects,
                          userInfo: ctx.userInfo,
                          newProjectId: undefined,
                          callback: event.callback,
                        };
                      }

                      const projects: ManagerContext['projects'] = [
                        {
                          // __typename: 'UserProject',
                          // accountId: ctx.selectedOrgId,
                          // project: data.admin_.createProject,
                          projectId: data.admin_.createProject.id,
                          accountId: data.admin_.createProject.accountId,
                          name: data.admin_.createProject.name,
                          description: data.admin_.createProject.description,
                          picture: data.admin_.createProject.picture,
                          updatedAt: new Date().toISOString(),
                          createdAt: new Date().toISOString(),
                          pictureFile: data.admin_.createProject.pictureFile,
                          projectProductSettings:
                            data.admin_.createProject.projectProductSettings,
                          projectReleaseChannelConfig:
                            data.admin_.createProject
                              .projectReleaseChannelConfig,
                          localizationSettings:
                            data.admin_.createProject.localizationSettings,
                          users: data.admin_.createProject.users,
                        },
                        ...(ctx.projects || []),
                      ];

                      const products = ctx.getProducts({
                        ctx,
                      });

                      const projectProducts =
                        products.conditionalProducts.filter((product) =>
                          productIds.includes(product.productId)
                        );

                      let userInformation = ctx.userInfo;
                      const userMainOrgId = ctx.orgTree?.[0]?.id;

                      // update userInfo with new project for agentAssist product
                      if (
                        projectProducts.find(
                          (product) => product.name === 'AGENT_ASSIST'
                        ) &&
                        userInformation.productRoles?.agentAssistRolesSource &&
                        userMainOrgId === ctx.selectedOrgId
                      ) {
                        userInformation = {
                          ...userInformation,
                          productRoles: {
                            ...userInformation.productRoles,
                            agentAssistRolesSource: [
                              ...userInformation.productRoles
                                .agentAssistRolesSource,
                              {
                                project: {
                                  id: data.admin_.createProject.id,
                                  name: data.admin_.createProject.name,
                                },
                              },
                            ],
                          },
                        };
                      }

                      // update userInfo with new project for analytics product
                      if (
                        projectProducts.find(
                          (product) => product.name === 'ANALYTICS'
                        ) &&
                        userInformation.productRoles?.analyticsRolesSource &&
                        userMainOrgId === ctx.selectedOrgId
                      ) {
                        userInformation = {
                          ...userInformation,
                          productRoles: {
                            ...userInformation.productRoles,
                            analyticsRolesSource: [
                              ...userInformation.productRoles
                                .analyticsRolesSource,
                              {
                                project: {
                                  id: data.admin_.createProject.id,
                                  name: data.admin_.createProject.name,
                                },
                              },
                            ],
                          },
                        };
                      }

                      return {
                        projects,
                        userInfo: userInformation,
                        newProjectId: data.admin_.createProject.id,
                        callback: event.callback,
                      };
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.projects = data.projects;
                        ctx.userInfo = data.userInfo;

                        if (
                          data.newProjectId &&
                          data.newProjectId !== undefined
                        ) {
                          ctx.userProjectIds.push(data.newProjectId);
                        }

                        if (data.callback) {
                          data.callback(true);
                        }
                      }
                    },
                    onDoneTarget: '#transformProjectsAfterDelay',
                    onErrorTarget: 'loaded',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error fetching user orgs: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },

                transformAfterDelay: {
                  id: 'transformProjectsAfterDelay',
                  after: {
                    50: {
                      target: 'loading',
                    },
                  },
                },
                deleting: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<void | {
                      projects: ManagerContext['projects'];
                      event: ManagerEvents;
                      project?: Project;
                    }> => {
                      if (event.type !== 'manager.deleteProject') return;

                      if (!event.projectId || !ctx.selectedOrgId) {
                        throw new Error(
                          'missing info needed to delete project! (projectId or selectedOrgId)'
                        );
                      }

                      const { data, error } = await urqlGql.deleteProject({
                        projectId: event.projectId,
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      const deletedProject = ctx.projects?.find(
                        (project) => project.projectId === event.projectId
                      );
                      const filteredProjects = ctx.projects?.filter(
                        (project) => project.projectId !== event.projectId
                      );

                      return {
                        projects: filteredProjects,
                        event,
                        project: deletedProject,
                      };
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.projects = data.projects;
                      }
                    },
                    onDoneActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, { data }) {
                          if (data?.event?.type !== 'manager.deleteProject')
                            return;

                          sendUserMessage({
                            type: 'success',
                            text: `Project ${data.project?.name} deleted successfully`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error fetching user orgs: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                    onDoneTarget: 'transforming',
                    onErrorTarget: 'error',
                  }),
                },
                updating: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<void | {
                      nextProjectsState: ManagerContext['projects'];
                      updatedProject?: Project;
                    }> => {
                      if (event.type !== 'manager.updateProject') return;

                      if (event.projectId === undefined) return;

                      const { data, error } = await urqlGql.updateProject({
                        projectId: event.projectId,
                        description: event.description || '',
                        name: event.name || '',
                        localeId: event.localeId || undefined,
                        countryId: event.countryId || undefined,
                        timeZoneId: event.timeZoneId || undefined,
                        productIds: event.productIds || [],
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      if (
                        ctx.projects?.length === 0 ||
                        ctx.projects === undefined
                      ) {
                        return {
                          nextProjectsState: [],
                          updatedProject: undefined,
                        };
                      }

                      const updateChannels = Promise.all(
                        (event.releaseChannelConfig || []).map(
                          async ({ name, description, domains }) => {
                            const projectReleaseChannelConfigId =
                              ctx.projects
                                ?.find(
                                  ({ projectId }) =>
                                    projectId === event.projectId
                                )
                                ?.projectReleaseChannelConfig?.find(
                                  ({ releaseChannel }) => {
                                    return releaseChannel?.name === name;
                                  }
                                )?.id || '';

                            const result =
                              await urqlGql.updateProjectReleaseChannelConfig({
                                projectId: event.projectId || '',
                                description,
                                domains,
                                projectReleaseChannelConfigId,
                              });

                            return result;
                          }
                        )
                      );

                      const result = await updateChannels;

                      result.map(
                        ({ data: channelsData, error: channelsError }) => {
                          if (haveErrors(channelsData)) {
                            throw new Error(channelsData.errors?.[0]?.message);
                          }

                          if (channelsError) {
                            throw new Error(channelsError.message);
                          }
                        }
                      );

                      const updatedReleaseChannelConfigs = result.map(
                        (r) => r.data?.admin_?.updateProjectReleaseChannelConfig
                      );

                      const updatedProject = data?.admin_?.updateProject;

                      const nextProjectsState = ctx.projects.map((item) => {
                        if (item.projectId === updatedProject?.projectId) {
                          return {
                            ...item,
                            ...updatedProject,
                            ...(event.releaseChannelConfig?.length && {
                              projectReleaseChannelConfig:
                                item.projectReleaseChannelConfig?.map(
                                  (config) => {
                                    const { releaseChannelId, releaseChannel } =
                                      config;
                                    const updatedConfig =
                                      updatedReleaseChannelConfigs?.find(
                                        (c) =>
                                          c?.releaseChannelId ===
                                          releaseChannelId
                                      );

                                    return {
                                      ...updatedConfig,
                                      releaseChannelId,
                                      releaseChannel,
                                    };
                                  }
                                ),
                            }),
                          };
                        }
                        return item;
                      });

                      return { nextProjectsState, updatedProject };
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.projects = data.nextProjectsState;
                      }
                    },
                    onDoneTarget: 'transforming',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error updating project's data: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                    onDoneActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, { data }) {
                          sendUserMessage({
                            type: 'success',
                            text: `Project ${data?.updatedProject?.name} updated successfully`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                transforming: {
                  id: 'transforming',
                  entry: assign((ctx) => {
                    const projectsWithAvailableProduct =
                      ctx.projects?.filter(
                        ({ projectProductSettings }) =>
                          projectProductSettings?.length
                      ) || [];

                    const projectWithAvailableProduct =
                      projectsWithAvailableProduct.length === 1
                        ? projectsWithAvailableProduct[0]
                        : undefined;

                    const projectWithAvailableAgentAssistProduct =
                      ctx.projects?.find(({ projectId }) => {
                        const agentAssistRolesSource =
                          ctx.userInfo?.productRoles?.agentAssistRolesSource ||
                          [];

                        return agentAssistRolesSource.length === 1
                          ? agentAssistRolesSource[0]?.project?.id === projectId
                          : undefined;
                      });

                    const projectWithAvailableAnalyticsProduct =
                      ctx.projects?.find(({ projectId }) => {
                        const analyticsRolesSource =
                          ctx.userInfo?.productRoles?.analyticsRolesSource ||
                          [];

                        return analyticsRolesSource.length === 1
                          ? analyticsRolesSource[0]?.project?.id === projectId
                          : undefined;
                      });

                    const projectWithAvailableStudioProduct =
                      ctx.projects?.find(({ projectId }) => {
                        const studioRolesSource: RoleSource[] = [];
                        // @todo: currently no studioRolesSource in the userProductRoles
                        // probably fuctionality will be different
                        // ctx.userInfo?.productRoles?.studioRolesSource || [];

                        return studioRolesSource.length === 1
                          ? studioRolesSource[0]?.project?.id === projectId
                          : undefined;
                      });

                    const tools = [
                      projectWithAvailableProduct,
                      projectWithAvailableAgentAssistProduct,
                      projectWithAvailableAnalyticsProduct,
                      projectWithAvailableStudioProduct,
                    ].map((projectWithProduct, index) => {
                      const { products: _tools } = ctx.getProducts({
                        project: projectWithProduct,
                        ctx,
                      });

                      return _tools[index];
                    });

                    const projects = ctx.projects?.map((p) => {
                      const {
                        selectedProjectURL,
                        conditionalProducts,
                        hasAvailableProduct,
                      } = ctx.getProducts({
                        project: p,
                        ctx,
                      });

                      return {
                        ...p,
                        selectedProjectURL,
                        conditionalProducts,
                        hasAvailableProduct,
                      };
                    });

                    const { conditionalProducts: createProjectTools } =
                      ctx.getProducts({ ctx });

                    return {
                      ...ctx,
                      tools,
                      projects,
                      createProjectTools,
                    };
                  }),
                  always: {
                    cond: (ctx) => Boolean(ctx.tools && ctx.createProjectTools),
                    target: '#projectsLoaded',
                  },
                },
                loaded: {
                  id: 'projectsLoaded',
                  on: {
                    'manager.deleteProject': 'deleting',
                    'manager.addProject': 'adding',
                    'manager.selectOrgId': {
                      actions: assign((ctx, { orgId }) => {
                        ctx.projects = [];
                        ctx.userProjectIds = [...ctx.userProjectIds];
                        return ctx;
                      }),
                      target: '#loadingProjects',
                    },
                    'manager.updateProject': 'updating',
                    'manager.selectProjectModal': {
                      actions: assign((ctx, { id }) => {
                        ctx.selectedProjectModal = id;
                        return ctx;
                      }),
                    },
                    'manager.deselectProjectModal': {
                      actions: assign((ctx) => {
                        ctx.selectedProjectModal = undefined;
                        return ctx;
                      }),
                    },
                  },
                },
              },
            },

            applets: {
              id: 'applets',
              initial: 'idle',
              states: {
                idle: {
                  entry: (ctx) => {
                    // console.log('ready to load projects info!', ctx.selectedOrgId);
                  },
                  always: [
                    {
                      target: 'loading',
                      cond: (ctx, event, data) => {
                        const cond = Boolean(
                          ctx.selectedProject !== undefined &&
                            ctx.userInfo.id !== undefined &&
                            !ctx.applets
                        );

                        return cond;
                      },
                    },
                  ],
                },
                loading: {
                  id: 'loadingApplets',
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      _event
                    ): Promise<{
                      applets: ManagerContext['applets'];
                    }> => {
                      if (
                        ctx.userInfo.id === undefined ||
                        ctx.selectedProject === undefined
                      ) {
                        throw Error('missing info needed to get projects!');
                      }

                      const { data, error } =
                        await urqlGql.getAppletInstancesByProjectId({
                          projectId: ctx.selectedProject,
                        });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      const allAppletInstances =
                        compact(
                          flatten(
                            compact(
                              data?.admin_?.projectById
                                ?.projectReleaseChannelConfig
                            )?.map((prcc) => prcc.appletInstances)
                          )
                        ) || [];

                      return {
                        applets: allAppletInstances,
                      };
                    },
                    onDoneTarget: 'loaded',
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.applets = data.applets;
                      }

                      return ctx;
                    },
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error fetching user projects: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                error: {},
                adding: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<
                      | void
                      | NonNullable<
                          CreateAppletInstanceMutation['admin_']
                        >['createAppletInstance']
                    > => {
                      if (
                        event.type !== 'manager.addApplet' ||
                        !event.name ||
                        !ctx.selectedOrgId
                      )
                        return;

                      const {
                        description,
                        name,
                        projectReleaseChannelConfigId,
                        appletVersionId,
                      } = event;

                      const { data, error } =
                        await urqlGql.createAppletInstance({
                          name: name,
                          projectReleaseChannelConfigId:
                            projectReleaseChannelConfigId,
                          description: description || '',
                          // appletVersionId: event.latestVersion.id,
                          appletVersionId: appletVersionId,
                        });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }
                      return data?.admin_?.createAppletInstance;
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (!data) {
                        throw new Error(
                          'Returned data from createAppletInstance is undefined'
                        );
                      }
                      ctx.applets?.push(data);
                    },
                    onDoneTarget: 'transformAfterDelay',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error fetching user orgs: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                transformAfterDelay: {
                  id: 'transformAppletsAfterDelay',
                  after: {
                    50: {
                      target: 'loaded',
                    },
                  },
                },
                deleting: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<AppletInstance['id'] | void> => {
                      if (event.type !== 'manager.deleteApplet' || !event.id)
                        return;

                      const { data, error } =
                        await urqlGql.deleteAppletInstance({
                          deleteAppletInstanceId: event.id,
                        });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }
                      return event.id;
                    },
                    onDoneAssignContext({ ctx, data }) {
                      ctx.applets = ctx.applets?.filter((a) => a.id !== data);
                    },
                    onDoneTarget: 'loaded',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error fetching user orgs: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },

                updating: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<
                      | void
                      | NonNullable<
                          UpdateAppletInstanceMutation['admin_']
                        >['updateAppletInstance']
                    > => {
                      if (event.type !== 'manager.updateApplet') return;

                      if (event.updateAppletInstanceId === undefined) return;

                      const { type, ...variables } = event;
                      const { data, error } =
                        await urqlGql.updateAppletInstance(variables);

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      return data?.admin_?.updateAppletInstance;
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        const index = ctx.applets?.findIndex(
                          (a) => a.id === data.id
                        );
                        if (index === undefined) {
                          throw new Error(
                            'Returned applet from UpdateAppletInstance Mutation is not found'
                          );
                        }
                        if (index >= 0) {
                          ctx.applets = ctx.applets?.with(index, data);
                        }
                      }
                    },
                    // onDoneTarget: 'idle',
                    onDoneTarget: 'loaded',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error updating project's data: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                    onDoneActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, { data }) {
                          sendUserMessage({
                            type: 'success',
                            text: `Applet ${data?.name} updated successfully`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                loaded: {
                  id: 'AppletsLoaded',
                  on: {
                    'manager.addApplet': 'adding',
                    'manager.deleteApplet': 'deleting',
                    'manager.updateApplet': 'updating',
                  },
                },
              },
            },

            // Shouldn't live in this state machine (instead under User machine); shouldn't require us to pass in the accountId. Even if it did, it should be the accountId under our user account, not the accountId of the currently selected org
            userInfo: {
              id: 'userInfo',
              initial: 'idle',
              states: {
                idle: {
                  always: [
                    {
                      target: 'fetching',
                      cond: (context) =>
                        Boolean(
                          !context.userInfo?.recommendedAuthMethod &&
                            context.userInfo?.id !== undefined &&
                            context.selectedOrgId
                        ),
                    },
                  ],
                  on: {
                    'manager.updateUserPreferences': {
                      target: '#userInfo.updating',
                    },
                    'manager.refetchUserSettings': {
                      target: '#userInfo.fetching',
                    },
                  },
                },
                fetching: {
                  id: 'fetching',
                  invoke: createInvokablePromise({
                    src: async (ctx, event) => {
                      const {
                        userInfo: { id: userId, email, defaultOrgId },
                        selectedOrgId: accountId,
                      } = ctx;

                      const selectedOrgId =
                        event.type === 'manager.refetchUserSettings'
                          ? event.orgId
                          : accountId;

                      if (!userId || !selectedOrgId || !email) {
                        throw new Error('User id or accountId not provided');
                      }

                      const { data, error } = await urqlGql
                        .getUserSettings({
                          accountId: defaultOrgId || selectedOrgId,
                          userId,
                          email,
                        })
                        .toPromise();

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      const nextUserInfoState: ManagerContext['userInfo'] = {
                        ...ctx.userInfo,
                        status:
                          data?.admin_?.currentUser?.status ||
                          ctx.userInfo.status,
                        productRoles: data?.admin_?.userProductRoles || {},
                        recommendedAuthMethod:
                          data?.admin_?.getLoginInfo?.recommendedAuthMethod,
                        registeredAuthMethods:
                          data?.admin_?.getLoginInfo?.registeredAuthMethods ||
                          [],
                        canManageAccess: Boolean(
                          data?.admin_?.organizationMetadata?.canManageAccess
                        ),
                        defaultLanguage:
                          data?.admin_?.currentUser?.settings?.defaultLanguage?.split(
                            '-'
                          )?.[0],
                      };

                      if (nextUserInfoState.defaultLanguage) {
                        await changeLanguage(nextUserInfoState.defaultLanguage);
                      }

                      return {
                        nextUserInfoState,
                        event,
                      };
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.userInfo = data.nextUserInfoState;
                      }
                    },
                    onDoneTarget: 'idle',
                    onErrorTarget: 'error',
                    onDoneActions: [
                      {
                        type: 'transformProjectsSettings',
                        exec(
                          _ctx,
                          {
                            data: {
                              event: { type },
                            },
                          }
                        ) {
                          if (type === 'manager.refetchUserSettings') {
                            sendManagerEvent({
                              type: 'manager.transformProjectsSettings',
                            });
                          }
                        },
                      },
                    ],
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error loading My Account data via graphql: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                refetchUserSettings: {
                  id: 'refetchUserSettings',
                  invoke: createInvokablePromise({
                    src: async (ctx, event) => {
                      const {
                        userInfo: { id: userId, email },
                        selectedOrgId: accountId,
                      } = ctx;

                      const selectedOrgId =
                        event.type === 'manager.refetchUserSettings'
                          ? event.orgId
                          : accountId;

                      if (!userId || !selectedOrgId || !email) {
                        throw new Error('User id or accountId not provided');
                      }

                      const { data, error } = await urqlGql
                        .getUserSettings({
                          accountId: selectedOrgId,
                          userId,
                          email,
                        })
                        .toPromise();

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      const nextState = {
                        ...ctx.userInfo,
                        selectedOrgId,
                        defaultLanguage:
                          data?.admin_?.currentUser?.settings?.defaultLanguage?.split(
                            '-'
                          )?.[0],
                      };

                      if (nextState.defaultLanguage) {
                        await changeLanguage(nextState.defaultLanguage);
                      }
                      const userService = globalAppService.children.get(
                        GLOBAL_APP_SUB_MACHINE_IDS.user
                      ) as UserInterpreter;
                      userService.send({
                        type: 'update.userPreferences',
                        userActionPayload: {
                          user: data?.admin_?.currentUser,
                        },
                      });

                      return {
                        nextState,
                        event,
                      };
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.userInfo = data.nextState;
                      }
                    },
                    onDoneTarget: 'refetched',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error loading My Account data via graphql: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                refetched: {
                  after: {
                    100: 'idle',
                  },
                },
                updating: {
                  id: 'updating',
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<
                      | void
                      | NonNullable<UpdateUserMutation['admin_']>['updateUser']
                    > => {
                      if (event.type !== 'manager.updateUserPreferences')
                        return;

                      const {
                        firstName,
                        lastName,
                        profilePhoto: picture,
                        jobTitle,
                        status,
                        requiredConsentStatus,
                        optionalConsentStatus,
                        requiredConsentUpdatedAt,
                        optionalConsentUpdatedAt,
                        defaultLanguage,
                        defaultTimezone,
                        country,
                      } = event.values;

                      const {
                        userInfo: { id: userId },
                        selectedOrgId: accountId,
                      } = ctx;

                      if (!userId || !accountId) {
                        throw new Error('User id or accountId not provided');
                      }

                      // we shouldn't have to re-send values that haven't changed
                      const { data, error } = await urqlGql.updateUser({
                        id: userId,
                        accountId,
                        firstName,
                        lastName,
                        picture: picture
                          ? transformProfilePicture(picture)
                          : '',
                        settings: {
                          jobTitle,
                          defaultLanguage,
                          defaultTimezone,
                          country,
                        },
                        status,
                        requiredConsentStatus,
                        optionalConsentStatus,
                        requiredConsentUpdatedAt,
                        optionalConsentUpdatedAt,
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }
                      return data?.admin_?.updateUser;
                    },
                    onDoneTarget: '#refetchUserSettings',
                    onDoneActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, { data }) {
                          sendUserMessage({
                            type: 'success',
                            text: `User ${data?.firstName} ${data?.lastName} updated successfully`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error updating user info via graphql: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                updated: {
                  entry() {
                    setTimeout(
                      () => sendUserEvent({ type: 'user.refetch' }),
                      1
                    );
                  },
                },
                error: {},
              },
            },

            // shouldn't exist
            routes: {
              id: 'routes',
              initial: 'idle',
              on: {
                'manager.selectOrgId': {
                  target: '#updatingRoutes',
                },
              },
              states: {
                idle: {
                  always: {
                    target: 'updating',
                    cond: (context) => context.selectedOrgId !== undefined,
                  },
                },
                updating: {
                  id: 'updatingRoutes',
                  entry: assign((ctx) => {
                    const { selectedOrgId, routes } = ctx;
                    const { admin: adminBaseURL } = routes;
                    const adminURL = `${adminBaseURL}/organization/${selectedOrgId}`;
                    const basePath = selectedOrgId
                      ? `/organization/${selectedOrgId}`
                      : '';

                    ctx.routes = {
                      ...ctx.routes,
                      home: basePath || '/',
                      myAccount: `${basePath}/my-account`,
                      projects: `${basePath}/projects`,
                      users: `${adminURL}/users-management`,
                      teams: `${adminURL}/teams-management`,
                      roles: `${adminURL}/roles-management`,
                      orgs: `${basePath}/child-orgs`,
                      logs: `${basePath}/audit-logs`,
                      superAdmin: `${basePath}/super-admin`,
                      wipUsers: `${basePath}/users`,
                      wipTeams: `${basePath}/teams`,
                    };

                    return ctx;
                  }),
                  onDone: 'updated',
                },
                updated: {
                  on: {
                    'manager.selectOrgId': {
                      target: '#updatingRoutes',
                    },
                  },
                },
              },
            },
            projectDetails: {
              initial: 'idle',
              states: {
                idle: {},
                name: {
                  states: {
                    loading: {
                      id: 'projectNameUpdating',
                      invoke: createInvokablePromise({
                        src: async (
                          ctx,
                          event
                        ): Promise<ManagerContext['projects'] | void> => {
                          if (event.type !== 'manager.updateProject.name')
                            return;

                          if (event.projectId === undefined) return;

                          const { projectId } = event;

                          const { data, error } = await urqlGql.updateProject({
                            projectId,
                            name: event.data.value || '',
                          });

                          if (haveErrors(data)) {
                            throw new Error(data.errors?.[0]?.message);
                          }

                          if (error) {
                            throw new Error(error.message);
                          }

                          const updatedName = data?.admin_?.updateProject?.name;

                          const updatedProjects = ctx.projects?.map(
                            (project) => {
                              if (project.projectId === event.projectId) {
                                return {
                                  ...project,
                                  name: updatedName || '',
                                };
                              }
                              return project;
                            }
                          );

                          return updatedProjects;
                        },
                        onDoneAssignContext({ ctx, data }) {
                          if (data !== undefined) {
                            ctx.projects = data;
                          }
                        },
                        onDoneTarget: '#successNameUpdating',
                        onErrorTarget: '#errorNameUpdating',
                        onErrorActions: [
                          {
                            type: 'sendUserMessage',
                            exec(ctx, event) {
                              sendUserMessage({
                                type: 'error',
                                text: `Error saving project's name: ${event.data.message}`,
                                autoClose: 3000,
                              });
                            },
                          },
                        ],
                      }),
                    },
                    success: {
                      id: 'successNameUpdating',
                    },
                    error: {
                      id: 'errorNameUpdating',
                    },
                  },
                },
                description: {
                  states: {
                    loading: {
                      id: 'projectDescriptionUpdating',
                      invoke: createInvokablePromise({
                        src: async (
                          ctx,
                          event
                        ): Promise<ManagerContext['projects'] | void> => {
                          if (
                            event.type !== 'manager.updateProject.description'
                          )
                            return;

                          if (event.projectId === undefined) return;

                          const projectName = ctx.projects?.find(
                            ({ projectId }) => projectId === event.projectId
                          )?.name;

                          if (projectName === undefined) return;

                          const { data, error } = await urqlGql.updateProject({
                            projectId: event.projectId,
                            name: projectName,
                            description: event.data.value || '',
                          });

                          if (haveErrors(data)) {
                            throw new Error(data.errors?.[0]?.message);
                          }

                          if (error) {
                            throw new Error(error.message);
                          }

                          const updatedDescription =
                            data?.admin_?.updateProject?.description;

                          const updatedProjects = ctx.projects?.map(
                            (project) => {
                              if (project.projectId === event.projectId) {
                                return {
                                  ...project,
                                  description: updatedDescription || '',
                                };
                              }
                              return project;
                            }
                          );

                          return updatedProjects;
                        },
                        onDoneAssignContext({ ctx, data }) {
                          if (data !== undefined) {
                            ctx.projects = data;
                          }
                        },
                        onDoneTarget: '#successDescriptionUpdating',
                        onErrorTarget: '#errorDescriptionUpdating',
                        onErrorActions: [
                          {
                            type: 'sendUserMessage',
                            exec(ctx, event) {
                              sendUserMessage({
                                type: 'error',
                                text: `Error saving project's description: ${event.data.message}`,
                                autoClose: 3000,
                              });
                            },
                          },
                        ],
                      }),
                    },
                    success: {
                      id: 'successDescriptionUpdating',
                    },
                    error: {
                      id: 'errorDescriptionUpdating',
                    },
                  },
                },
                countryId: {
                  states: {
                    loading: {
                      id: 'projectCountryIdUpdating',
                      invoke: createInvokablePromise({
                        src: async (
                          ctx,
                          event
                        ): Promise<ManagerContext['projects'] | void> => {
                          if (event.type !== 'manager.updateProject.countryId')
                            return;

                          if (event.projectId === undefined) return;

                          const projectName = ctx.projects?.find(
                            ({ projectId }) => projectId === event.projectId
                          )?.name;

                          if (projectName === undefined) return;

                          const { data, error } = await urqlGql.updateProject({
                            projectId: event.projectId,
                            name: projectName,
                            countryId: (event.data.value || null)!,
                          });

                          if (haveErrors(data)) {
                            throw new Error(data.errors?.[0]?.message);
                          }

                          if (error) {
                            throw new Error(error.message);
                          }

                          const updatedCountry =
                            data?.admin_?.updateProject?.localizationSettings
                              ?.country;

                          const updatedProjects = ctx.projects?.map(
                            (project) => {
                              if (project.projectId === event.projectId) {
                                return {
                                  ...project,
                                  localizationSettings: {
                                    ...project.localizationSettings,
                                    id: project.localizationSettings?.id || '',
                                    country: {
                                      ...updatedCountry,
                                      code: updatedCountry?.code || '',
                                      name: updatedCountry?.name || '',
                                      id: updatedCountry?.id || '',
                                      iso3: updatedCountry?.iso3 || '',
                                    },
                                  },
                                };
                              }
                              return project;
                            }
                          );

                          return updatedProjects;
                        },
                        onDoneAssignContext({ ctx, data }) {
                          if (data !== undefined) {
                            ctx.projects = data;
                          }
                        },
                        onDoneTarget: '#successCountryIdUpdating',
                        onErrorTarget: '#errorCountryIdUpdating',
                        onErrorActions: [
                          {
                            type: 'sendUserMessage',
                            exec(ctx, event) {
                              sendUserMessage({
                                type: 'error',
                                text: `Error saving project's country: ${event.data.message}`,
                                autoClose: 3000,
                              });
                            },
                          },
                        ],
                      }),
                    },
                    success: {
                      id: 'successCountryIdUpdating',
                    },
                    error: {
                      id: 'errorCountryIdUpdating',
                    },
                  },
                },
                localeId: {
                  states: {
                    loading: {
                      id: 'projectLocaleIdUpdating',
                      invoke: createInvokablePromise({
                        src: async (
                          ctx,
                          event
                        ): Promise<ManagerContext['projects'] | void> => {
                          if (event.type !== 'manager.updateProject.localeId')
                            return;

                          if (event.projectId === undefined) return;

                          const projectName = ctx.projects?.find(
                            ({ projectId }) => projectId === event.projectId
                          )?.name;

                          if (projectName === undefined) return;

                          const { data, error } = await urqlGql.updateProject({
                            projectId: event.projectId,
                            name: projectName,
                            localeId: (event.data.value || null)!,
                          });

                          if (haveErrors(data)) {
                            throw new Error(data.errors?.[0]?.message);
                          }

                          if (error) {
                            throw new Error(error.message);
                          }

                          const updatedLocale =
                            data?.admin_?.updateProject?.localizationSettings
                              ?.locale;

                          const updatedProjects = ctx.projects?.map(
                            (project) => {
                              if (project.projectId === event.projectId) {
                                return {
                                  ...project,
                                  localizationSettings: {
                                    ...project.localizationSettings,
                                    id: project.localizationSettings?.id || '',
                                    locale: {
                                      ...updatedLocale,
                                      countryCode:
                                        updatedLocale?.countryCode || '',
                                      id: updatedLocale?.id || '',
                                      isoCode: updatedLocale?.isoCode || '',
                                      languageCode:
                                        updatedLocale?.languageCode || '',
                                      locale: updatedLocale?.locale || '',
                                      name: updatedLocale?.name || '',
                                    },
                                  },
                                };
                              }
                              return project;
                            }
                          );

                          return updatedProjects;
                        },
                        onDoneAssignContext({ ctx, data }) {
                          if (data !== undefined) {
                            ctx.projects = data;
                          }
                        },
                        onDoneTarget: '#successLocaleIdUpdating',
                        onErrorTarget: '#errorLocaleIdUpdating',
                        onErrorActions: [
                          {
                            type: 'sendUserMessage',
                            exec(ctx, event) {
                              sendUserMessage({
                                type: 'error',
                                text: `Error saving project's locale: ${event.data.message}`,
                                autoClose: 3000,
                              });
                            },
                          },
                        ],
                      }),
                    },
                    success: {
                      id: 'successLocaleIdUpdating',
                    },
                    error: {
                      id: 'errorLocaleIdUpdating',
                    },
                  },
                },
                timeZoneId: {
                  states: {
                    loading: {
                      id: 'projectTimeZoneIdUpdating',
                      invoke: createInvokablePromise({
                        src: async (
                          ctx,
                          event
                        ): Promise<ManagerContext['projects'] | void> => {
                          if (event.type !== 'manager.updateProject.timeZoneId')
                            return;

                          if (event.projectId === undefined) return;

                          const projectName = ctx.projects?.find(
                            ({ projectId }) => projectId === event.projectId
                          )?.name;

                          if (projectName === undefined) return;

                          const { data, error } = await urqlGql.updateProject({
                            projectId: event.projectId,
                            name: projectName,
                            timeZoneId: (event.data.value || null)!,
                          });

                          if (haveErrors(data)) {
                            throw new Error(data.errors?.[0]?.message);
                          }

                          if (error) {
                            throw new Error(error.message);
                          }

                          const updatedTimeZone =
                            data?.admin_?.updateProject?.localizationSettings
                              ?.timeZone;

                          const updatedProjects = ctx.projects?.map(
                            (project) => {
                              if (project.projectId === event.projectId) {
                                return {
                                  ...project,
                                  localizationSettings: {
                                    ...project.localizationSettings,
                                    id: project.localizationSettings?.id || '',
                                    timeZone: {
                                      ...updatedTimeZone,
                                      id: updatedTimeZone?.id || '',
                                      identifier:
                                        updatedTimeZone?.identifier || '',
                                      label: updatedTimeZone?.label || '',
                                    },
                                  },
                                };
                              }
                              return project;
                            }
                          );

                          return updatedProjects;
                        },
                        onDoneAssignContext({ ctx, data }) {
                          if (data !== undefined) {
                            ctx.projects = data;
                          }
                        },
                        onDoneTarget: '#successTimeZoneIdUpdating',
                        onErrorTarget: '#errorTimeZoneIdUpdating',
                        onErrorActions: [
                          {
                            type: 'sendUserMessage',
                            exec(ctx, event) {
                              sendUserMessage({
                                type: 'error',
                                text: `Error saving project's timezone: ${event.data.message}`,
                                autoClose: 3000,
                              });
                            },
                          },
                        ],
                      }),
                    },
                    success: {
                      id: 'successTimeZoneIdUpdating',
                    },
                    error: {
                      id: 'errorTimeZoneIdUpdating',
                    },
                  },
                },
                projectIcon: {
                  states: {
                    loading: {
                      id: 'projectIconUpdating',
                      invoke: createInvokablePromise({
                        src: async (
                          ctx,
                          event
                        ): Promise<ManagerContext['projects'] | void> => {
                          if (
                            event.type !== 'manager.updateProject.projectIcon'
                          )
                            return;

                          if (event.projectId === undefined) return;

                          const updatedProjectIcon = event.data.value;

                          const updatedProjects = ctx.projects?.map(
                            (project) => {
                              if (project.projectId === event.projectId) {
                                return {
                                  ...project,
                                  pictureFile: {
                                    ...project.pictureFile,
                                    url: updatedProjectIcon as string,
                                    publicId: project.pictureFile
                                      ?.publicId as string,
                                  },
                                };
                              }
                              return project;
                            }
                          );

                          return updatedProjects;
                        },
                        onDoneAssignContext({ ctx, data }) {
                          if (data !== undefined) {
                            ctx.projects = data;
                          }
                        },
                        onDoneTarget: '#successProjectIconUpdating',
                      }),
                    },
                    success: {
                      id: 'successProjectIconUpdating',
                    },
                    error: {
                      id: 'errorProjectIconUpdating',
                    },
                  },
                },
              },
            },
            users: {
              id: 'users',
              initial: 'idle',
              on: {
                'manager.users.loadUsers': {
                  target: '.loading',
                },
              },
              states: {
                idle: {},
                loading: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<ManagerContext['users'] | undefined> => {
                      if (ctx.selectedOrgId === undefined) {
                        throw Error('Missing organization to load users!');
                      }

                      const { data, error } = await urqlGql.getUsers({
                        accountId: ctx.selectedOrgId,
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      return data?.admin_?.users?.rows;
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.users = data;
                      }
                    },
                    onDoneTarget: 'loaded',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error loading users: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                loaded: {
                  on: {
                    'manager.selectOrgId': {
                      actions: assign((ctx) => {
                        ctx.users = [];
                        return ctx;
                      }),
                      target: 'loading',
                    },
                  },
                },
                error: {},
              },
            },
            teams: {
              id: 'teams',
              initial: 'idle',
              on: {
                'manager.teams.loadTeams': {
                  target: '.loading',
                },
              },
              states: {
                idle: {},
                loading: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<ManagerContext['teams'] | undefined> => {
                      if (ctx.selectedOrgId === undefined) {
                        throw Error('Missing organization to load teams!');
                      }

                      const { data, error } = await urqlGql.getTeams({
                        accountId: ctx.selectedOrgId,
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      return data?.admin_?.teams?.rows;
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.teams = data;
                      }
                    },
                    onDoneTarget: 'loaded',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error loading teams: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                loaded: {
                  on: {
                    'manager.selectOrgId': {
                      actions: assign((ctx) => {
                        ctx.teams = [];
                        return ctx;
                      }),
                      target: 'loading',
                    },
                  },
                },
                error: {},
              },
            },
            logs: {
              initial: 'idle',
              on: {
                'manager.logs.loadLogs': {
                  target: '#loadLogs',
                },
                'manager.selectOrgId': {
                  actions: assign((ctx) => {
                    ctx.logs = {
                      count: 0,
                      rows: [],
                    };
                    return ctx;
                  }),
                  target: '#loadLogs',
                },
              },
              states: {
                idle: {},
                loading: {
                  id: 'loadLogs',
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<
                      | {
                          data: ManagerContext['logs'] | undefined;
                          queryMetadata: QueryMetadata;
                        }
                      | undefined
                    > => {
                      if (event.type !== 'manager.logs.loadLogs') return;

                      if (ctx.selectedOrgId === undefined) {
                        throw Error('Missing organization to load logs!');
                      }

                      const { queryMetadata } = event;
                      const { logsCache } = ctx;
                      const cacheKey = `${queryMetadata.skipPages}_${queryMetadata.pageSize}`;
                      if (logsCache[cacheKey]) {
                        return {
                          data: logsCache[cacheKey],
                          queryMetadata,
                        };
                      }

                      const { data, error } = await urqlGql.getAuditLogs({
                        accountId: ctx.selectedOrgId,
                        queryMetadata,
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      return {
                        data: data?.admin_?.auditLogs,
                        queryMetadata,
                      };
                    },
                    onDoneTarget: 'loaded',
                    onDoneAssignContext({ ctx, data }) {
                      if (data && data.data !== undefined) {
                        const { queryMetadata } = data;
                        ctx.logs = data.data;
                        ctx.logsCache[
                          `${queryMetadata.skipPages}_${queryMetadata.pageSize}`
                        ] = data.data;
                      }
                    },
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error loading logs: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                loaded: {},
                error: {},
              },
            },
            childOrgs: {
              id: 'childOrgs',
              initial: 'idle',
              on: {
                'manager.childOrgs.loadChildOrgs': {
                  target: '.loading',
                },
              },
              states: {
                idle: {},
                loading: {
                  invoke: createInvokablePromise({
                    src: async (
                      ctx,
                      event
                    ): Promise<GetChildAccountsQuery['admin_'] | undefined> => {
                      if (ctx.selectedOrgId === undefined) {
                        throw Error('Missing organization to load child orgs!');
                      }

                      const { data, error } = await urqlGql.getChildAccounts({
                        accountId: ctx.selectedOrgId,
                      });

                      if (haveErrors(data)) {
                        throw new Error(data.errors?.[0]?.message);
                      }

                      if (error) {
                        throw new Error(error.message);
                      }

                      return data?.admin_;
                    },
                    onDoneAssignContext({ ctx, data }) {
                      if (data !== undefined) {
                        ctx.childOrgs = {
                          rows: data.accounts?.rows,
                          count: data.accounts?.count,
                          currentOrg: data.accountById,
                        };
                      }
                    },
                    onDoneTarget: 'loaded',
                    onErrorTarget: 'error',
                    onErrorActions: [
                      {
                        type: 'sendUserMessage',
                        exec(ctx, event) {
                          sendUserMessage({
                            type: 'error',
                            text: `Error loading child orgs: ${event.data.message}`,
                            autoClose: 3000,
                          });
                        },
                      },
                    ],
                  }),
                },
                loaded: {
                  on: {
                    'manager.selectOrgId': {
                      actions: assign((ctx) => {
                        ctx.childOrgs = {
                          rows: [],
                          count: 0,
                          currentOrg: undefined,
                        };
                        return ctx;
                      }),
                      target: 'loading',
                    },
                  },
                },
                error: {},
              },
            },
          },
        },
      },
    },
    global: {
      type: 'parallel',
      states: {
        // none of these should be nested --> might make sense to have a localization machine
        countries: {
          id: 'countries',
          initial: 'loading',
          states: {
            loading: {
              invoke: createInvokablePromise({
                id: 'getCountries',
                src: async (
                  ctx,
                  event
                ): Promise<ManagerContext['countries']> => {
                  const { data, error } = await urqlGql.getCountries();

                  if (haveErrors(data)) {
                    throw new Error(data.errors?.[0]?.message);
                  }

                  if (error) {
                    throw new Error(error.message);
                  }

                  const countries = data?.admin_?.countries
                    ? sort(data?.admin_?.countries).asc('name')
                    : [];

                  if (countries.length) {
                    countries.unshift({
                      name: 'Select a country',
                      id: '',
                      code: '',
                      __typename: 'Country2',
                    });
                  }

                  const unsupportedCountryCodes = [
                    'RU',
                    'SA',
                    'CA',
                    'CN',
                    'AU',
                    'AE',
                  ];

                  return countries.filter(
                    ({ code }) => !unsupportedCountryCodes.includes(code)
                  );
                },
                onDoneTarget: 'loaded',
                onDoneAssignContext({ ctx, data }) {
                  ctx.countries = data;
                },
                onErrorTarget: 'error',
                onErrorActions: [
                  {
                    type: 'sendUserMessage',
                    exec(ctx, event) {
                      sendUserMessage({
                        type: 'error',
                        text: `Error fetching countries: ${event.data.message}`,
                        autoClose: 3000,
                      });
                    },
                  },
                ],
              }),
            },
            loaded: {},
            error: {},
          },
        },
        appletTemplates: {
          id: 'appletTemplates',
          initial: 'loading',
          states: {
            loading: {
              invoke: createInvokablePromise({
                id: 'getAppletTemplate',
                src: async (
                  ctx,
                  event
                ): Promise<
                  NonNullable<GetAppletTemplatesQuery['admin_']>['applets']
                > => {
                  const { data, error } = await urqlGql.getAppletTemplates();

                  if (haveErrors(data)) {
                    throw new Error(data.errors?.[0]?.message);
                  }

                  if (error) {
                    throw new Error(error.message);
                  }

                  return data?.admin_?.applets;
                },
                onDoneTarget: 'loaded',
                onDoneAssignContext({ ctx, data }) {
                  ctx.appletTemplates = data || [];
                },
                onErrorTarget: 'error',
                onErrorActions: [
                  {
                    type: 'sendUserMessage',
                    exec(ctx, event) {
                      sendUserMessage({
                        type: 'error',
                        text: `Error fetching applet-templates: ${event.data.message}`,
                        autoClose: 3000,
                      });
                    },
                  },
                ],
              }),
            },
            loaded: {},
            error: {},
          },
        },
        languages: {
          id: 'languages',
          initial: 'loading',
          states: {
            loading: {
              invoke: createInvokablePromise({
                id: 'getLanguages',
                src: async (
                  ctx,
                  event
                ): Promise<{
                  languages: ManagerContext['languages'];
                  localizationSettingsConfig: ManagerContext['localizationSettingsConfig'];
                }> => {
                  const { data, error } = await urqlGql.getLanguages();

                  if (haveErrors(data)) {
                    throw new Error(data.errors?.[0]?.message);
                  }

                  if (error) {
                    throw new Error(error.message);
                  }

                  const languages = data?.admin_?.locales
                    ? sort(data?.admin_?.locales).asc('name')
                    : [];

                  if (languages.length) {
                    languages.unshift({
                      name: 'Select a language',
                      id: '',
                      locale: '',
                      __typename: 'Locale',
                      languageCode: '',
                    });
                  }

                  return {
                    languages,
                    localizationSettingsConfig:
                      data?.admin_?.localizationSettingsConfig,
                  };
                },
                onDoneTarget: 'loaded',
                onDoneAssignContext({ ctx, data }) {
                  ctx.languages = data.languages;
                  ctx.localizationSettingsConfig =
                    data.localizationSettingsConfig;
                },
                onErrorTarget: 'error',
                onErrorActions: [
                  {
                    type: 'sendUserMessage',
                    exec(ctx, event) {
                      sendUserMessage({
                        type: 'error',
                        text: `Error fetching languages: ${event.data.message}`,
                        autoClose: 3000,
                      });
                    },
                  },
                ],
              }),
            },
            loaded: {},
            error: {},
          },
        },
        // should only be 24 time zones
        timezones: {
          id: 'timezones',
          initial: 'loading',
          states: {
            loading: {
              invoke: createInvokablePromise({
                id: 'getTimeZones',
                src: async (
                  ctx,
                  event
                ): Promise<ManagerContext['timezones'] | []> => {
                  const { data, error } = await urqlGql.getTimeZones();

                  if (haveErrors(data)) {
                    throw new Error(data.errors?.[0]?.message);
                  }

                  if (error) {
                    throw new Error(error.message);
                  }

                  const timeZoneData = data?.admin_?.timeZones;
                  const timeZones = timeZoneData
                    ? sort(timeZoneData).asc('label')
                    : [];

                  if (timeZoneData?.length) {
                    timeZones.unshift({
                      label: 'Select a time zone',
                      identifier: '',
                      id: '',
                      __typename: 'TimeZone',
                    });
                  }

                  return transformTimezonesData<Timezone>(timeZones);
                },
                onDoneTarget: 'loaded',
                onDoneAssignContext({ ctx, data }) {
                  ctx.timezones = data;
                },
                onErrorTarget: 'error',
                onErrorActions: [
                  {
                    type: 'sendUserMessage',
                    exec(ctx, event) {
                      sendUserMessage({
                        type: 'error',
                        text: `Error fetching TimeZones: ${event.data.message}`,
                        autoClose: 3000,
                      });
                    },
                  },
                ],
              }),
            },
            loaded: {},
            error: {},
          },
        },

        // products isn't affected by current org so this shouldn't live here
        products: {
          initial: 'idle',
          states: {
            idle: {
              // entry: () => {
              // console.log('ready to load product info!');
              // },
              always: {
                target: 'loading',
                cond: (context, event) => {
                  return context.userInfo?.status === UserStatus.Active;
                },
              },
            },
            loading: {
              invoke: createInvokablePromise({
                src: async (
                  ctx,
                  event
                ): Promise<{
                  products: ManagerContext['products'];
                  allProducts: ManagerContext['allProducts'];
                }> => {
                  const { data, error } = await urqlGql.getProducts();

                  if (haveErrors(data)) {
                    throw new Error(data.errors?.[0]?.message);
                  }

                  if (error) {
                    throw new Error(error.message);
                  }

                  return {
                    allProducts: data?.admin_?.allProducts,
                    products: data?.admin_?.allToolProducts,
                  };
                },
                onDoneTarget: 'loaded',
                onDoneAssignContext({ ctx, data }) {
                  if (data !== undefined) {
                    ctx.products = data.products;
                    ctx.allProducts = data.allProducts;
                  }
                },
                onErrorTarget: 'error',
                onErrorActions: [
                  {
                    type: 'sendUserMessage',
                    exec(ctx, event) {
                      sendUserMessage({
                        type: 'error',
                        text: `Error fetching user orgs: ${event.data.message}`,
                        autoClose: 3000,
                      });
                    },
                  },
                ],
              }),
            },
            error: {},
            loaded: {
              // entry: (ctx) => {
              // console.log('loaded products!', ctx.products);
              // },
              always: {
                target: 'loading',
                cond: (context, event) => {
                  // return context.selectedOrgId !== context.
                  return false;
                },
              },
            },
          },
        },
      },
    },
    superAdmin: {
      initial: 'idle',
      states: {
        idle: {},
        dataBaseSnapshot: {
          id: 'dataBaseSnapshot',
          initial: 'loading',
          states: {
            loading: {
              invoke: createInvokablePromise({
                src: async (_ctx, event): Promise<void> => {
                  if (event.type !== 'manager.createDbSnapshot') return;

                  const { data, error } = await urqlGql.makeDbSnapshot();

                  if (haveErrors(data)) {
                    throw new Error(data.errors?.[0]?.message);
                  }

                  if (error) {
                    throw new Error(error.message);
                  }
                },
                onDoneTarget: 'loaded',
                onErrorTarget: 'error',
                onDoneActions: [
                  {
                    type: 'sendUserMessage',
                    exec(_ctx, _event) {
                      sendUserMessage({
                        type: 'success',
                        text: `DB snapshot created successfully`,
                        autoClose: 3000,
                      });
                    },
                  },
                ],
                onErrorActions: [
                  {
                    type: 'sendUserMessage',
                    exec(_ctx, event) {
                      sendUserMessage({
                        type: 'error',
                        text: `Error making db snapshot: ${event.data.message}`,
                        autoClose: 3000,
                      });
                    },
                  },
                ],
              }),
            },
            loaded: {},
            error: {},
          },
        },
      },
    },
  },
});
