import { TextTitle, Spacer, TextBody } from '@pypestream/design-system';

interface ProjectTitlesProps {
  title: string;
  subTitle?: string;
}

export const ProjectTitles = ({ title, subTitle }: ProjectTitlesProps) => {
  return (
    <>
      <TextTitle
        test-id="create-project-form-title"
        size="small"
        textAlign="center"
      >
        {title}
      </TextTitle>
      {subTitle ? (
        <>
          <Spacer size="small"></Spacer>
          <TextBody
            test-id="create-project-form-sub-title"
            variant="secondary"
            textAlign="center"
          >
            {subTitle}
          </TextBody>
        </>
      ) : null}
    </>
  );
};
