import { useParams } from 'react-router-dom';
import { ProjectDetails } from '../pages';

import { IPage } from '../pages/types';
import { WithRouteData } from '../render-routes';

export const SelectedEnvironmentPage = (props: IPage) => {
  const params = useParams();
  const { environment_id: environmentIdParams } = params;

  return (
    <ProjectDetails
      key={environmentIdParams}
      environmentId={environmentIdParams}
      {...props}
    />
  );
};

export default WithRouteData(SelectedEnvironmentPage);
