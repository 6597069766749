import * as React from 'react';
import { createComponent } from '@lit/react';
import { FilepondWC } from './filepond.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

export type FilepondProps = GenerateComponentTypeWithChildren<FilepondWC> & {
  onChange?: ({ url, pictureId }: { url?: string; pictureId?: string }) => void;
};

export type FilepondPropsWithRef = GeneratePropsWithRef<FilepondProps>;

export const Filepond = createComponent({
  tagName: FilepondWC.tagname,
  elementClass: FilepondWC,
  react: React,
}) as FilepondPropsWithRef;
