import React from 'react';
import { ArrayFieldTitleProps, titleId } from '@rjsf/utils';
import { TextTitle } from '@pypestream/design-system';

export function ArrayFieldTitleTemplate(props: ArrayFieldTitleProps) {
  const { title, idSchema } = props;
  const id = titleId(idSchema);

  return <TextTitle id={id}>{title}</TextTitle>;
}
