import {
  ToastContainer,
  Page,
  PageBody,
  Result,
  Stack,
  Button,
} from '@pypestream/design-system';
import { OnboardingModal } from '@pypestream/ui-patterns';
import * as Sentry from '@sentry/react';
import {
  createBrowserRouter,
  RouterProvider as ReactRouterProvider,
  useNavigate,
} from 'react-router-dom';
import { FC, PropsWithChildren } from 'react';

import { PrimaryNav } from './components';
import {
  sendUserEvent,
  sendManagerEvent,
  useManagerCtxSelector,
} from './xstate/app.xstate';
import { ErrorBoundary } from './components/error-boundaries/error-boundary';
import { reactPages } from './render-routes';
import { IPage } from './pages/types';
import { useAuthRole } from './hooks';

import './index.scss';

export const Root: FC<PropsWithChildren> = ({ children }) => {
  const { orgId } = useManagerCtxSelector((ctx) => ({
    orgId: ctx.selectedOrgId,
  }));
  const onSuccess = () => {
    sendUserEvent({ type: 'user.refetch' });
    sendManagerEvent({ type: 'manager.refetchUserSettings', orgId });
  };

  return (
    <ErrorBoundary>
      {children}
      <ToastContainer />
      <OnboardingModal onSuccess={onSuccess} />
    </ErrorBoundary>
  );
};

type MainLayoutProps = PropsWithChildren & {
  background?: IPage['background'];
  accessFor: IPage['accessFor'];
};

export const MainLayout: FC<MainLayoutProps> = ({
  background = 'alt',
  children,
  accessFor,
}) => {
  const haveAccess = useAuthRole(accessFor || []);
  const isProtectedPage = Boolean(accessFor);
  const navigate = useNavigate();
  const { home } = useManagerCtxSelector((ctx) => ctx.routes);
  const isAuthorized = isProtectedPage ? haveAccess : true;

  return (
    <Page background={background}>
      <PrimaryNav />
      {isAuthorized ? (
        children
      ) : (
        <PageBody background="none">
          <Result
            align="center"
            status="error"
            label="You are not authorized to see this page"
            body=""
          >
            <Stack gutter="xsmall" justifyContent="center" slot="footer">
              <Button onClick={() => navigate(home)} size="large">
                Return to Home
              </Button>
            </Stack>
          </Result>
        </PageBody>
      )}
    </Page>
  );
};

// https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/
const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(reactPages);

export const RouterProvider = () => <ReactRouterProvider router={router} />;
