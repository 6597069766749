import { PackageTypeEnum, Trigger } from '@pypestream/api-services';
import { WidgetProps } from '@rjsf/utils';
import React, { useCallback, useMemo } from 'react';

import { SelectWC, Spinner, TextSubtitle } from '@pypestream/design-system';
import { usePackages } from '../../use-packages';
import { TriggerItem } from './trigger-item';
import { TriggerPlaceholder } from './trigger-pacehoder';
import { mapPackageToTrigger } from '../../utils/map-package-to-trigger';

export const TriggersWidget: React.FC<WidgetProps> = (props) => {
  const {
    schema,
    value = [],
    onChange,
    onBlur,
    onFocus,
    registry,
    formContext,
  } = props;
  const { packages, loading } = usePackages(PackageTypeEnum.Trigger);

  const handlePackageVersionChange = useCallback(
    (event: React.ChangeEvent<SelectWC>, index?: number) => {
      if (typeof index !== 'undefined') {
        onChange(
          value.with(index, {
            ...value[index],
            packageVersionId: event.target.value,
            packageVersion: packages
              .find((p) => p.pkgName === value[index].name)
              ?.versions?.find((v) => v.id === event.target.value),
          })
        );
      }
    },
    [onChange, packages, value]
  );
  const handlePackageChange = useCallback(
    (event: React.ChangeEvent<SelectWC>, index?: number) => {
      const pkg = packages.find((p) => p.pkgName === event.target.value);
      if (!pkg) {
        throw new Error('package not found');
      }
      const schemaToAdd = mapPackageToTrigger(pkg);
      if (value?.length && typeof index !== 'undefined') {
        onChange([
          ...value.slice(0, index),
          schemaToAdd,
          ...value.slice(index + 1),
        ]);
      } else {
        onChange([schemaToAdd]);
      }
    },
    [onChange, packages, value]
  );

  const handleRemoveTrigger = useCallback(
    (index: number) => {
      onChange([...value.slice(0, index), ...value.slice(index + 1)]);
    },
    [onChange, value]
  );

  const handleAddTrigger = useCallback(
    (index: number) => {
      onChange([
        ...value.slice(0, index), // items before the index
        mapPackageToTrigger(packages[0]), // the new item
        ...value.slice(index), // items after the index
      ]);
    },
    [onChange, packages, value]
  );

  const renderedTriggers = useMemo(() => {
    if (!value?.length) {
      return (
        <TriggerPlaceholder
          packages={packages}
          handleAddTrigger={() => handleAddTrigger(0)}
          handleChange={(e) => handlePackageChange(e)}
        />
      );
    }
    return value.map((trigger: Trigger, index: number) => {
      return (
        <TriggerItem
          index={index}
          onSchemaChange={(options: unknown) => {
            onChange([
              ...value.slice(0, index),
              { ...value[index], options },
              ...value.slice(index + 1),
            ]);
          }}
          trigger={trigger}
          key={index}
          formContext={formContext}
          onAdd={() => handleAddTrigger(index + 1)}
          onRemove={() => handleRemoveTrigger(index)}
          onPackageChange={(e) => handlePackageChange(e, index)}
          onPackageVersionChange={(e) => handlePackageVersionChange(e, index)}
          onBlur={onBlur}
          onFocus={onFocus}
          registry={registry}
          packages={packages}
        />
      );
    });
  }, [
    handlePackageChange,
    handlePackageVersionChange,
    handleRemoveTrigger,
    onBlur,
    onChange,
    onFocus,
    packages,
    registry,
    value,
  ]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <div>
        <TextSubtitle size="small" variant="secondary">
          {schema.title}
        </TextSubtitle>
        {renderedTriggers}
      </div>
    </>
  );
};
