import React from 'react';
import { FieldTemplateProps } from '@rjsf/utils';
import { TextBody } from '@pypestream/design-system';

export function CustomFieldTemplate(props: FieldTemplateProps) {
  const { classNames, displayLabel, help, errors, children, label } = props;
  return (
    <div className={`c-rjsf-field ${classNames}`}>
      {displayLabel ? <TextBody variant="secondary">{label}</TextBody> : null}
      {children}
      {errors}
      {help}
    </div>
  );
}
