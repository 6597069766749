import * as React from 'react';
import { type ActionProps } from 'react-querybuilder';
import { IconButton } from '../../../buttons';

export const RemoveRuleAction = (props: ActionProps) => {
  const { handleOnClick, title, disabled } = props;

  return (
    <IconButton
      size="small"
      name="close"
      onClick={handleOnClick}
      title={title}
      disabled={disabled}
    />
  );
};
