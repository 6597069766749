import { FormControlMixin } from '@open-wc/form-control';
import { html, unsafeCSS } from 'lit';
import { live } from 'lit-html/directives/live.js';
import { property, query, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import {
  BaseElement,
  customElement,
  FormControlController,
  CheckableFormControl,
  FormControl,
  PSCustomEvent,
} from '../base-element';
import styles from './search.scss?inline';

@customElement('ps-search')
export class SearchWC
  extends FormControlMixin(BaseElement)
  implements CheckableFormControl
{
  static styles = unsafeCSS(styles);

  /** The name of the input, submitted as a name/value pair with form data. */
  @property() name = '';

  @state() invalid = false;

  // without this, we don't get native-like form field submit, reset, or per-input-field validation automatically triggered
  private readonly FormControlController = new FormControlController(this);

  @property() validationMode: FormControl['validationMode'] = 'onSubmit';

  setCustomValidity(message: string) {
    this.input?.setCustomValidity(message);

    this.FormControlController.updateValidity();
  }

  reportValidity() {
    // this is how visible form field show up when a form tries to be submitted
    return this.input?.reportValidity();
  }

  @query('.c-search__input') input: HTMLInputElement;

  @property({ reflect: true, type: String }) value = '';

  /** The search component's size. */
  @property({ reflect: true }) size: 'medium' | 'large' | 'xlarge' = 'medium';

  /** The button's variant. */
  @property({ reflect: true }) variant = 'filled' || 'outlined';

  /** Placeholder text to show as a hint when the input is empty. */
  @property({ reflect: true }) placeholder = 'Search';

  /** Error boolean */
  @property({ reflect: true, type: Boolean }) hasError = false;

  /** Indicates that the input should receive focus on page load. */
  @property({ type: Boolean }) autofocus: boolean;

  /**
   * Specifies what permission the browser has to provide assistance in filling out form field values. Refer to
   * [this page on MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete) for available values.
   */
  @property() autocomplete: string;

  @state() hasFocus = false;

  private handleChange() {
    this.value = this.input.value;
    this.emit('change');
  }

  private handleInput() {
    this.value = this.input.value;
    this.emit('input');
  }

  private focusInput(e: PointerEvent) {
    e.stopPropagation();
    e.preventDefault();
    this.input.focus();
    this.value = '';
  }

  private handleKeyDown(event: KeyboardEvent) {
    const hasModifier =
      event.metaKey || event.ctrlKey || event.shiftKey || event.altKey;

    // Pressing enter when focused on an input should submit the form like a native input, but we wait a tick before
    // submitting to allow users to cancel the keydown event if they need to
    if (event.key === 'Enter' && !hasModifier) {
      this.emit('enter');

      setTimeout(() => {
        //
        // When using an Input Method Editor (IME), pressing enter will cause the form to submit unexpectedly. One way
        // to check for this is to look at event.isComposing, which will be true when the IME is open.
        //
        // See https://github.com/shoelace-style/shoelace/pull/988
        //
        if (!event.defaultPrevented && !event?.isComposing) {
          this.FormControlController.submit();
        }
      }, 10);
    }
  }

  private handleFocus() {
    this.hasFocus = true;
    this.emit('focus');
  }

  private handleBlur() {
    this.hasFocus = false;
    this.emit('blur');
  }

  private handleClear(e: PointerEvent) {
    this.value = '';
    this.emit('input');
    this.focusInput(e);
  }

  focus() {
    this.input.focus();
  }

  blur() {
    this.input.blur();
  }

  render() {
    return html`
      <div
        class="${classMap({
          'c-search': true,
          [`c-search--size-${this.size}`]: this.size,
        })}"
        data-cy="search"
      >
        <ps-icon
          data-cy="search-icon"
          class="c-search__icon"
          name="search"
        ></ps-icon>
        <!-- autocomplete type is correct, reported bug in lit-plugin -->
        <input
          autocomplete=${this.autocomplete}
          class="${classMap({
            'c-search__input': true,
            [`c-search__input--variant-${this.variant}`]: this.variant,
            [`c-search__input--size-${this.size}`]: this.size,
            [`c-search__input--error`]: this.hasError,
          })}"
          placeholder=${this.placeholder}
          type="text"
          .value=${live(this.value)}
          @change=${this.handleChange}
          @keydown=${this.handleKeyDown}
          @input=${this.handleInput}
          data-cy="search-input"
          @blur=${this.handleBlur}
          @focus=${this.handleFocus}
          ?autofocus=${this.autofocus}
        />
        <ps-icon-button
          @click=${this.handleClear}
          @keydown=${this.handleKeyDown}
          size="xsmall"
          name="close"
          class="c-search__clear-icon"
          data-cy="search-reset-button"
        ></ps-icon-button>
        <slot></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-search': SearchWC;
  }
  enum PSElementTagNameMap {
    'ps-search' = 'ps-search',
  }
}

export type SearchInputEvent = PSCustomEvent<SearchWC, Record<string, never>>;
export type SearchChangeEvent = PSCustomEvent<SearchWC, Record<string, never>>;
