import * as React from 'react';
import { createComponent } from '@lit/react';
import { DataTableWC, DataTableRowSelection } from './data-table.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../component-types-generics';

export type DataTableProps<Q> = GenerateComponentTypeWithChildren<
  DataTableWC<Q>
> & {
  onRowSelectionChange?: (event: DataTableRowSelection<Q>) => void;
};

export type DataTablePropsPropsWithRef<R> = GeneratePropsWithRef<
  DataTableProps<R>
>;

function DataTableInput<T>(props: DataTableProps<T>, ref: unknown) {
  const DynamicDataTable = createComponent({
    tagName: DataTableWC.tagname,
    elementClass: DataTableWC<T>,
    react: React,
    events: {
      onRowSelectionChange: 'row-selection-change',
    },
  }) as DataTablePropsPropsWithRef<T>;

  return <DynamicDataTable ref={ref as typeof DynamicDataTable} {...props} />;
}

export const DataTable = React.forwardRef(DataTableInput) as <T>(
  // eslint-disable-next-line no-use-before-define
  p: DataTableProps<T> & { ref?: React.Ref<DataTableWC> }
) => React.ReactElement;
