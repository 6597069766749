/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useRef } from 'react';
import {
  Text,
  Card,
  logos,
  CardWC,
  CardProps,
} from '@pypestream/design-system';
import './product-cta.scss';
import {
  TranslationComponent,
  useTranslation,
  ToolsTranslationMapper,
} from '@pypestream/translations';
import { ProductName } from '@pypestream/api-services';

const sharedStyles = {
  flexGrow: 1,
  flexShrink: 1,
  boxSizing: 'border-box',
  maxWidth: 'clamp(7.5rem, 6.375rem + 6vw, 10.125rem)',
  minWidth: '120px',
  width: '20%',
  height: 'auto',
} as React.CSSProperties;

export interface ProductCTAProps {
  url?: string;
  logo?: keyof typeof logos;
  name?: string;
  disabled?: boolean;
  productName?: ProductName;
  inputName?: string;
  inputValue?: string;
  checked?: boolean;
  required?: boolean;

  onClick?: React.MouseEventHandler<CardWC>;
  onInputChange?: (isChecked: boolean) => void;
  onKeyDown?: React.KeyboardEventHandler<CardWC>;
}

// export const NavLogoWithoutReactRouter = React.forwardRef<
//   NavLogoProps,
//   NavLogoRef
// >(({ onClick, href, children, condensed, name }, ref) => (
//   <NavLogoInner
//     href={href as string}
//     onClick={(event: React.MouseEvent<NavLogoWC, MouseEvent>) => {
//       onClick?.(event);
//     }}
//     ref={ref}
//     condensed={condensed}
//     name={name}
//   >
//     {children}
//   </NavLogoInner>
// ));

export type Ref = ProductCTAProps;

export const ProductCTA = React.forwardRef<ProductCTAProps, Ref>(
  (
    {
      url,
      logo,
      name,
      disabled = true,
      inputName,
      inputValue,
      productName,
      checked,
      required = false,
      onClick,
      onInputChange,
      onKeyDown,
    },
    inputRef
  ) => {
    const cardRef = useRef<CardProps>(null);

    const focusEvent = new Event('focus', {
      bubbles: true,
    });

    const blurEvent = new Event('blur', {
      bubbles: true,
    });

    const CardInner = ({
      extraStyles,
    }: {
      extraStyles?: React.CSSProperties;
    }) => {
      const [t] = useTranslation();

      return (
        <Card
          test-id={`product-cta-${name?.replace(' ', '-').toLowerCase()}`}
          interactive
          display="flex"
          ref={cardRef}
          border={checked ? 'primary' : disabled ? 'default' : 'none'}
          variant="glass"
          style={{
            ...extraStyles,
          }}
          {...(disabled
            ? {
                disabled,
                shadow: 'none',
                // @todo: change this to Tooltip component
                title: t`manager/common:products.youDontHaveAccess` || '',
              }
            : { onKeyDown, onClick, href: url, shadow: 'small' })}
          onFocus={() => {
            // @ts-ignore
            inputRef?.current?.focus();
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              alignSelf: 'center',
              flexGrow: 1,
              justifySelf: 'center',
              aspectRatio: '1/1',
            }}
          >
            {inputName && inputValue && !disabled && (
              <>
                <input
                  ref={inputRef as React.ForwardedRef<HTMLInputElement>}
                  className="c-card__input"
                  type="checkbox"
                  name={inputName}
                  value={inputValue}
                  defaultChecked={checked}
                  required={required}
                  onInput={(event) => {
                    if (cardRef.current) {
                      cardRef.current.border = event.currentTarget.checked
                        ? 'primary'
                        : 'none';
                    }
                    if (onInputChange) {
                      onInputChange(event.currentTarget.checked);
                      event.currentTarget.blur();
                      event.currentTarget.dispatchEvent(blurEvent);
                    }
                  }}
                  onFocus={(event) => {
                    event.currentTarget.dispatchEvent(focusEvent);
                  }}
                />

                <span className={`c-card__checkbox`}>
                  <svg className="c-card__checkbox-svg" viewBox="0 0 12 10">
                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                  </svg>
                </span>
              </>
            )}
            {logo && (
              <img src={logos[logo]} className="u-margin-bottom-xsmall"></img>
            )}
            <Text size="2xsmall" textAlign="center" fontWeight="medium">
              {productName ? (
                <TranslationComponent
                  i18nKey={ToolsTranslationMapper[productName]}
                >
                  {name}
                </TranslationComponent>
              ) : (
                name
              )}
            </Text>
          </div>
        </Card>
      );
    };

    return (
      <>
        {inputName && inputValue ? (
          <label className="c-product-card" style={sharedStyles}>
            <CardInner />
          </label>
        ) : (
          <CardInner extraStyles={sharedStyles} />
        )}
      </>
    );
  }
);

export const FakeProductCTA = () => <span style={sharedStyles}></span>;
