import { Stack, Button } from '@pypestream/design-system';
import { TranslationComponent } from '@pypestream/translations';

interface ProjectFormButtonsProps {
  step: string;
  nextStep?: () => void;
  prevStep?: () => void;
}

export const ProjectFormButtons = ({
  step,
  nextStep,
  prevStep,
}: ProjectFormButtonsProps) => {
  return (
    <Stack justifyContent={prevStep ? 'space-between' : 'end'}>
      {prevStep && (
        <Button
          test-id={`${step}-prev-step`}
          variant="ghost"
          size="large"
          type="button"
          onClick={prevStep}
        >
          <TranslationComponent i18nKey="manager/projects:createProject.backStep">
            Back
          </TranslationComponent>
        </Button>
      )}
      {nextStep && (
        <Button
          test-id={`${step}-next-step`}
          variant="primary"
          size="large"
          type="submit"
          onClick={nextStep}
        >
          <TranslationComponent i18nKey="manager/projects:createProject.nextStep">
            Next
          </TranslationComponent>
        </Button>
      )}
    </Stack>
  );
};
