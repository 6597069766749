import { FC } from 'react';
import {
  Button,
  PageBody,
  PageSection,
  Spacer,
  TextSubtitle,
  TextTitle,
} from '@pypestream/design-system';
import { TranslationComponent } from '@pypestream/translations';

import { IPage } from '../types';
import { sendManagerEvent } from '../../xstate/app.xstate';

export const SuperAdminPage: FC<IPage> = ({ title }) => {
  const makeSnapshot = () => {
    sendManagerEvent({
      type: 'manager.createDbSnapshot',
    });
  };

  return (
    <PageBody background="none">
      <TextTitle>
        <TranslationComponent i18nKey="manager/super-admin:title">
          {title}
        </TranslationComponent>
      </TextTitle>
      <Spacer size="2xlarge" />

      <PageSection>
        <TextSubtitle size="small">
          <TranslationComponent i18nKey="manager/super-admin:section1.title">
            This button is for making a snapshot of DB
          </TranslationComponent>
        </TextSubtitle>
        <Spacer size="large" />
        <Button onClick={() => makeSnapshot()}>
          <TranslationComponent i18nKey="manager/super-admin:section1.button">
            Make Snapshot
          </TranslationComponent>
        </Button>
      </PageSection>
      <PageSection>
        <TextSubtitle size="small">
          <TranslationComponent i18nKey="manager/super-admin:section2.title">
            Link to Analytics Settings Page
          </TranslationComponent>
        </TextSubtitle>
        <Spacer size="large" />
        <Button href="https://analytics.main.pypestream.dev/instance/settings">
          <TranslationComponent i18nKey="manager/super-admin:section2.button">
            Analytics
          </TranslationComponent>
        </Button>
      </PageSection>
      <PageSection>
        <TextSubtitle size="small">
          <TranslationComponent i18nKey="manager/super-admin:section3.title">
            Link to Contact Center Super Admin Page
          </TranslationComponent>
        </TextSubtitle>
        <Spacer size="large" />
        <Button href="https://contactcenter.pypestream.com/super_admin">
          <TranslationComponent i18nKey="manager/super-admin:section3.button">
            Contact Center
          </TranslationComponent>
        </Button>
      </PageSection>
    </PageBody>
  );
};
