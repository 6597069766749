import * as React from 'react';
import { createComponent } from '@lit/react';
import { SearchChangeEvent, SearchInputEvent, SearchWC } from './search.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../component-types-generics';

export type SearchProps = GenerateComponentTypeWithChildren<SearchWC> & {
  onInput: (event: SearchInputEvent) => void;
  onChange: (event: SearchChangeEvent) => void;
};

export type SearchPropsWithRef = GeneratePropsWithRef<SearchProps>;

export const Search = createComponent({
  tagName: SearchWC.tagname,
  elementClass: SearchWC,
  react: React,
  events: {
    onInput: 'input',
    onChange: 'change',
  },
}) as SearchPropsWithRef;
