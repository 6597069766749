/* eslint-disable @typescript-eslint/ban-ts-comment */
export enum ProductName {
  Manager = 'Manager',
  ContactCenter = 'ContactCenter',
  Analytics = 'Analytics',
  Studio = 'Studio',
}

enum Environment {
  Production = 'production',
  Candidate = 'candidate',
  UAT = 'uat',
}

type LowercaseEnum<T> = {
  // @ts-ignore
  [K in keyof T]: Lowercase<T[K]>;
};

const createEnumObject = (
  enumObj: typeof ProductName
): LowercaseEnum<typeof ProductName> => {
  const result: Partial<LowercaseEnum<typeof ProductName>> = {};

  for (const [key, value] of Object.entries(enumObj)) {
    // @ts-ignore
    result[key as keyof typeof enumObj] = (
      value as string
    ).toLowerCase() as Lowercase<typeof value>;
  }

  return result as LowercaseEnum<typeof ProductName>;
};

const managerLocal = 'localhost:5173';
const company = 'pypestream';
const topLevelDomains = {
  production: 'com',
  development: 'dev',
};

export const generateBaseURLsToTools = (
  location: Location
): Record<ProductName, string> => {
  const { host } = location;

  const { Manager, Studio, ContactCenter, Analytics } =
    createEnumObject(ProductName);

  const baseUrls = {
    studioCandidate: `https://${Studio}.${Environment.Candidate}.${company}.${topLevelDomains.development}`,
    contactCenterCandidate: `https://${ContactCenter}.${Environment.Candidate}.${company}.${topLevelDomains.development}`,
    analyticsCandidate: `https://${Analytics}.${Environment.Candidate}.${company}.${topLevelDomains.development}`,
    managerCandidate: `https://${Manager}.${company}.${topLevelDomains.development}`,
    managerLocal: `http://${managerLocal}`,
    managerProd: `https://${Manager}.${company}.${topLevelDomains.production}`,
    analyticsProd: `https://${Analytics}.${company}.${topLevelDomains.production}`,
    studioProd: `https://${Studio}.${company}.${topLevelDomains.production}`,
    contactCenterProd: `https://${ContactCenter}.${company}.${topLevelDomains.production}`,
  };

  const defaultUrls = {
    Studio: baseUrls.studioCandidate,
    ContactCenter: baseUrls.contactCenterCandidate,
    Analytics: baseUrls.analyticsCandidate,
  };

  const prodUrls = {
    Studio: baseUrls.studioProd,
    ContactCenter: baseUrls.contactCenterProd,
    Analytics: baseUrls.analyticsProd,
  };

  const environments = {
    production: {
      Manager: baseUrls.managerProd,
      ...prodUrls,
    },
    candidate: {
      Manager: baseUrls.managerCandidate,
      ...defaultUrls,
    },
    uat: {
      Manager: `https://${Manager}.${Environment.UAT}.${company}.${topLevelDomains.development}`,
      Studio: `https://${Studio}.${Environment.UAT}.${company}.${topLevelDomains.development}`,
      ContactCenter: `https://${ContactCenter}.${Environment.UAT}.${company}.${topLevelDomains.development}`,
      Analytics: `https://${Analytics}.${Environment.UAT}.${company}.${topLevelDomains.development}`,
    },
    preview: (commitHash: string) => ({
      Manager: `https://${commitHash}.${Manager}.${company}.${topLevelDomains.development}`,
      ...defaultUrls,
    }),
    local: {
      Manager: '',
      ...defaultUrls,
    },
  };

  if (host.endsWith(`.${company}.${topLevelDomains.production}`)) {
    return environments.production;
  }
  if (
    host === `${Manager}.${company}.${topLevelDomains.development}` ||
    host.endsWith(
      `.${Environment.Candidate}.${company}.${topLevelDomains.development}`
    )
  ) {
    return environments.candidate;
  }
  if (
    host.endsWith(
      `.${Environment.UAT}.${company}.${topLevelDomains.development}`
    )
  ) {
    return environments.uat;
  }
  if (/^[a-z0-9]{10}\.manager\.pypestream\.dev$/.test(host)) {
    const commitHash = host.split('.')[0];
    return environments.preview(commitHash);
  }
  if (host === managerLocal) {
    return environments.local;
  }
  return environments.candidate;
};
