import {
  Button,
  Image,
  logos,
  Spacer,
  Stack,
  TextTitle,
} from '@pypestream/design-system';
import { TranslationComponent } from '@pypestream/translations';

export type ProjectCreatedPropsType = {
  closeModal: () => void;
};

export const ProjectCreated = ({ closeModal }: ProjectCreatedPropsType) => {
  return (
    <>
      <Stack justifyContent="center">
        <Image src={logos.project}></Image>
      </Stack>
      <Spacer size="xlarge"></Spacer>
      <TextTitle size="small" textAlign="center">
        <TranslationComponent i18nKey="manager/projects:createProject.step5.title">
          Success!
        </TranslationComponent>
      </TextTitle>
      <TextTitle size="small" textAlign="center">
        <TranslationComponent i18nKey="manager/projects:createProject.step5.subTitle">
          New project has been created...
        </TranslationComponent>
      </TextTitle>
      <Spacer size="xlarge"></Spacer>
      <Stack justifyContent="end">
        <Button
          test-id="done-button"
          variant="primary"
          size="large"
          type="button"
          onClick={closeModal}
        >
          <TranslationComponent i18nKey="manager/projects:createProject.step5.button">
            Done
          </TranslationComponent>
        </Button>
      </Stack>
    </>
  );
};
