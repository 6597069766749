import {
  controlElements,
  QueryBuilder,
  type RuleGroupType,
  RuleType,
  Spinner,
  TextBody,
} from '@pypestream/design-system';
import { FieldProps, RJSFSchema } from '@rjsf/utils';
import React, { useEffect } from 'react';
// @todo - add actual backend call for importing availableConditionsList
import { PackageTypeEnum } from '@pypestream/api-services';
import { usePackages } from '../../use-packages';
import { includePrereleaseFilter } from '../../utils/include-prerelease-filter';

const initialQuery: RuleGroupType = {
  id: 'root',
  combinator: 'and',
  rules: [],
};

const MAX_NESTING_DEPTH = 5;

const maxDepth = (rule: RuleGroupType | RuleType | null): number => {
  if (
    !rule ||
    typeof rule === 'string' ||
    !('rules' in rule) ||
    !rule?.rules ||
    rule.rules.length === 0
  ) {
    return 1;
  }
  return 1 + Math.max(...rule.rules.map(maxDepth));
};

export const ConditionQueryBuilder = (
  props: FieldProps
): React.ReactElement => {
  const [conditionSchema, setConditionSchema] =
    React.useState<RJSFSchema | null>(null);

  const { packages, loading } = usePackages(PackageTypeEnum.Condition);

  useEffect(() => {
    const conditionSchemaStructure = {
      type: 'object',
      required: ['pkg'],
      properties: {
        pkg: {
          type: 'string',
          oneOf: packages.map((p) => ({
            const: p.pkgName,
            title: p.displayName,
          })),
        },
      },
      dependencies: {
        pkg: {
          oneOf: [] as RJSFSchema[],
        },
      },
    };

    packages.forEach((condition) => {
      const packageVersions =
        condition.versions
          ?.filter((v) =>
            includePrereleaseFilter(
              v.version,
              props.formContext.includePrerelease
            )
          )
          ?.map((p) => ({
            const: p?.id,
            title: p?.version,
          })) || [];
      const latest = { const: 'latest', title: 'latest' };
      const oneOf = [latest, ...packageVersions];
      conditionSchemaStructure.dependencies?.pkg.oneOf.push({
        properties: {
          pkg: {
            enum: [`${condition.pkgName}`],
          },
          packageVersion: {
            type: 'string',
            oneOf: oneOf,
            default: 'latest',
          },
          options: condition.latestVersion?.schema,
        },
      });
    });
    setConditionSchema(conditionSchemaStructure as RJSFSchema);
  }, [packages]);

  const formData =
    props.formData?.length === 1
      ? props.formData?.[0]?.options
      : props.formData;
  // @todo - Need better handling for loading required schemas and loading state UI/UX
  if (!conditionSchema || loading) return <Spinner />;
  return (
    <>
      <TextBody variant="secondary">Conditions</TextBody>
      {maxDepth(formData) > MAX_NESTING_DEPTH ? (
        <p>
          Warning: Nested conditions will slow app-loader runtime. We recommend
          to keep nested conditions below {MAX_NESTING_DEPTH} levels.
        </p>
      ) : null}
      <QueryBuilder
        query={formData || initialQuery}
        onQueryChange={props.onChange}
        enableDragAndDrop={false}
        disabled={props.disabled}
        showShiftActions={true}
        // @todo - Add validation support for condition widget to avoid saving wrong data
        // validator={defaultValidator}
        context={{
          conditionSchema,
          registry: props.registry,
          idSchema: props.idSchema,
          name: props.name,
          key: props.key,
          disabled: props.disabled,
          readonly: props.readonly,
        }}
        enableMountQueryChange={false}
        controlElements={controlElements}
      />
    </>
  );
};
