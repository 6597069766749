import React, { FC } from 'react';
import { IPage } from '../../types';
import { PageBody, TextTitle } from '@pypestream/design-system';
import { TranslationComponent } from '@pypestream/translations';

export const TeamDetails: FC<IPage> = () => {
  return (
    <PageBody background="none">
      <TextTitle>
        <TranslationComponent i18nKey="manager/teams:teamDetails.title">
          Team Details
        </TranslationComponent>
      </TextTitle>
      Page for team details.
    </PageBody>
  );
};
