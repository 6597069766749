import * as React from 'react';
import { type ActionWithRulesAndAddersProps } from 'react-querybuilder';
import { Button } from '../../../buttons';
import { Icon } from '../../../icon';

export const AddGroupAction = (props: ActionWithRulesAndAddersProps) => {
  const { title, handleOnClick, disabled } = props;

  return (
    <Button
      size="small"
      onClick={handleOnClick}
      disabled={disabled}
      variant="ghost"
      className="c-query-builder__add-group-btn"
    >
      <Icon slot="prefix" name="plus" size="xsmall" />
      {title}
    </Button>
  );
};
