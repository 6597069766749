import { Trigger } from '@pypestream/api-services';
import { PartialDeep } from 'type-fest';
import { GetPackagesByPackageTypeQuery } from '@pypestream/api-services/dist/generated/graphql.urql';

export const mapPackageToTrigger = (
  pkg: NonNullable<
    NonNullable<GetPackagesByPackageTypeQuery['admin_']>['packages']
  >[number]
): PartialDeep<Trigger> => {
  if (!pkg?.latestVersionId) {
    throw new Error('latestVersionId cannot be undefined');
  }
  return {
    ...pkg,
    packageVersion: {
      package: {
        id: pkg.id,
      },
      schema: JSON.stringify(pkg?.latestVersion?.schema),
    },
    name: pkg?.pkgName,
    packageVersionId: pkg?.latestVersionId,
    options: {},
    __typename: 'Trigger',
  };
};
