import { ProductName } from '@pypestream/api-services';
import { TranslationKeys } from './types';

export const ToolsTranslationMapper: {
  [key in ProductName]: TranslationKeys;
} = {
  [ProductName.Organization]: 'manager/common:products.manager',
  [ProductName.AgentAssist]: 'manager/common:products.agentAssist',
  [ProductName.Analytics]: 'manager/common:products.analytics',
  ['Studio' as ProductName]: 'manager/common:products.studio',
};
