import { PageProps } from '@pypestream/design-system';
import { ConsentStatusEnum } from '@pypestream/api-services';
import { usePostHog } from 'posthog-js/react';
import { FC, PropsWithChildren, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import {
  useManagerCtxSelector,
  useManagerStateMatches,
  useUserCtxSelector,
} from '../xstate/app.xstate';
import { syncXstateWithRouteParams } from '../utils';
import { Root, MainLayout } from '../router';
import { IPage } from '../pages/types';

const RouteDataProvider: FC<PropsWithChildren> = ({ children }) => {
  const posthog = usePostHog();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const params = useParams();
  const { org_id: orgIdQueryParam } = params;
  const { orgId } = useManagerCtxSelector((ctx) => ({
    orgId: ctx.selectedOrgId,
  }));
  const currentOrgState = useManagerStateMatches('orgRelated.ready.currentOrg');
  const { optionalConsentStatus } = useUserCtxSelector((ctx) => ({
    optionalConsentStatus: ctx.user?.optionalConsentStatus,
  }));

  useEffect(() => {
    if (currentOrgState) {
      syncXstateWithRouteParams({ params });
    }
  }, [params, currentOrgState]);

  useEffect(() => {
    if (optionalConsentStatus === ConsentStatusEnum.Accepted) {
      posthog.capture('$pageview');
    }

    if (orgId && !orgIdQueryParam) {
      navigate(`/organization/${orgId}${pathname}`, { replace: true });
    }
  }, [
    pathname,
    orgId,
    optionalConsentStatus,
    posthog,
    orgIdQueryParam,
    navigate,
  ]);

  return <>{children}</>;
};

export function WithRouteData<T extends IPage>(
  WrappedComponent: React.ComponentType<T>
) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithRouteData = (props: T) => {
    return (
      <RouteDataProvider>
        <Root>
          <MainLayout
            background={props?.background as IPage['background']}
            accessFor={props?.accessFor as IPage['accessFor']}
          >
            <WrappedComponent {...props} />
          </MainLayout>
        </Root>
      </RouteDataProvider>
    );
  };

  ComponentWithRouteData.displayName = `WithRouteData(${displayName})`;

  return ComponentWithRouteData;
}
