import React from 'react';
import { WidgetProps } from '@rjsf/utils';
import { langs } from '@uiw/codemirror-extensions-langs';
import {
  autocompletion,
  Completion,
  CompletionContext,
  CompletionResult,
} from '@codemirror/autocomplete';
import { CodeEditor } from '@pypestream/design-system';

function getOptionsFromObject(
  obj: object,
  text: string | undefined
): Completion[] {
  const options: Completion[] = [];
  if (!text) {
    return options;
  }
  const stack = [obj];
  while (stack?.length > 0) {
    const currentObj = stack.pop();
    if (!currentObj) continue;
    Object.keys(currentObj).forEach((key) => {
      if (key.includes(text)) {
        options.push({
          label: key,
          // @ts-expect-error unknown index
          type: typeof currentObj[key] === 'function' ? 'function' : 'variable',
        });
      }
      // @ts-expect-error unknown index
      if (typeof currentObj[key] === 'object' && currentObj[key] !== null) {
        // @ts-expect-error unknown index
        stack.push(currentObj[key]);
      }
    });
  }
  return options;
}

function getOptionsFromMethods(text: string | undefined): Completion[] {
  const options: Completion[] = [
    {
      label: 'testMethod',
      type: 'method',
    },
  ];
  if (!text) return options;
  return options.filter((option) => option.label.includes(text));
}

function myCompletions(context: CompletionContext): CompletionResult {
  const word = context.matchBefore(/\w*/);
  const nestedObjects = {
    topLevel: {
      key1: 'value1',
      nestedLevel: {
        key2: 'value2',
        key3: 'value3',
      },
    },
  };
  if (!word?.from || (word?.from == word?.to && !context.explicit))
    return { from: 0, options: [] };
  const objectOptionsFromObject = getOptionsFromObject(
    nestedObjects,
    word?.text
  );
  const objectOptionsFromMethods = getOptionsFromMethods(word?.text);
  return {
    from: word?.from,
    options: [...objectOptionsFromObject, ...objectOptionsFromMethods],
  };
}
export const CodeBlockWidget = function (props: WidgetProps) {
  return (
    <CodeEditor
      value={props.value}
      height="200px"
      extensions={[
        langs.typescript(),
        autocompletion({ override: [myCompletions] }),
      ]}
      onChange={props.onChange}
    />
  );
};
