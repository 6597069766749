import * as React from 'react';
import SchemaField from '@rjsf/core/lib/components/fields/SchemaField';
import { type RuleProps, useRule } from 'react-querybuilder';

export const RuleFieldForm = (
  ruleProps: RuleProps & ReturnType<typeof useRule>
) => {
  const selectedPackage = ruleProps.rule.field;
  const selectedPackageVersion = ruleProps.rule?.value
    ? JSON.parse(ruleProps.rule.value).packageVersion
    : null;
  const defaultPackageVersion = // @ts-expect-error any
    ruleProps.context.conditionSchema.dependencies.pkg.oneOf.find((o) =>
      o.properties.pkg.enum.includes(selectedPackage)
    )?.properties?.packageVersion?.oneOf?.[0]?.const;
  return (
    <SchemaField
      onChange={(formData, _es, id) => {
        if (id?.endsWith('_condition_query_options_operator')) {
          ruleProps.generateOnChangeHandler('operator')(
            formData?.options.operator
          );
        } else if (id?.endsWith('condition_query_packageVersion')) {
          ruleProps.generateOnChangeHandler('value')(
            JSON.stringify({
              ...formData,
              packageVersion: formData?.packageVersion,
            })
          );
        } else if (id?.includes('_condition_query_options_')) {
          const options = { ...formData?.options };
          delete options.operator;
          ruleProps.generateOnChangeHandler('value')(JSON.stringify(options));
        } else if (id?.endsWith('_pkg')) {
          ruleProps.generateOnChangeHandler('field')(formData?.pkg);
        }
      }}
      onBlur={() => {
        //
      }}
      onFocus={() => {
        //
      }}
      readonly={ruleProps.context.readonly}
      disabled={ruleProps.context.disabled}
      registry={ruleProps.context.registry}
      idSchema={ruleProps.context.idSchema}
      name={ruleProps.context.name}
      key={`${ruleProps.id}-${ruleProps.path}`}
      schema={ruleProps.context.conditionSchema}
      formData={{
        pkg: ruleProps.field,
        packageVersion:
          selectedPackageVersion || defaultPackageVersion || 'latest',
        options: {
          operator: ruleProps.operator,
          ...JSON.parse(ruleProps.value || '{}'),
        },
      }}
      uiSchema={
        {
          // pkg: {
          //   'ui:widget': 'CustomWidgetComponent',
          // },
          // options: {
          //   operator: {
          //     'ui:widget': 'PlainTextComponent',
          //   },
          // },
        }
      }
    />
  );
};
