import { Select, SelectOption, SelectWC } from '@pypestream/design-system';
import React from 'react';
import { GetPackagesByPackageTypeQuery } from '@pypestream/api-services/dist/generated/graphql.urql';

interface PackagesSelectProps {
  packages: NonNullable<
    NonNullable<GetPackagesByPackageTypeQuery['admin_']>['packages']
  >;
  onChange: React.ChangeEventHandler<SelectWC> | undefined;
  value?: string | undefined;
  id?: string;
  className?: string;
}

export const PackagesSelect: React.FC<PackagesSelectProps> = ({
  packages,
  onChange,
  value,
  className,
}) => {
  return (
    <Select
      className={className}
      style={{ flexGrow: 1 }}
      id="triggers_select"
      onChange={onChange}
      value={value}
    >
      {packages.map((item) => (
        <SelectOption key={item.pkgName} value={item.pkgName}>
          {item.displayName}
        </SelectOption>
      ))}
    </Select>
  );
};
