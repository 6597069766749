import * as React from 'react';
import { createComponent } from '@lit/react';
import { AppletCardWC } from './applet-card.wc';
import {
  GenerateComponentTypeWithChildren,
  GeneratePropsWithRef,
} from '../../../components/component-types-generics';
import { PSCustomEvent } from '../../../components/base-element';

export type AppletCardProps =
  GenerateComponentTypeWithChildren<AppletCardWC> & {
    onToggleClick?: (
      e: PSCustomEvent<AppletCardWC, { checked: boolean }>
    ) => void;
  };

export type AppletCardPropsWithRef = GeneratePropsWithRef<AppletCardProps>;

export const AppletCard = createComponent({
  tagName: AppletCardWC.tagname,
  elementClass: AppletCardWC,
  react: React,
  events: {
    onToggleClick: 'toggle-click',
  },
}) as AppletCardPropsWithRef;
