import { GraphQLClient } from 'graphql-request';
import {
  getSdk,
  SdkFunctionWrapper,
  UserStatus,
  ConsentStatusEnum,
} from './generated/graphql';

const defaultWrapper: SdkFunctionWrapper = (
  action,
  _operationName,
  _operationType
) => action();

const getHasuraEnvVariables = (): {
  FE_GQL_ENDPOINT: string;
  FE_GQL_ADMIN_SECRET: string;
} => {
  /**
   * Returning empty string in case of test environment as,
   * We will be mocking actual gqlClient call
   * @todo - Need to replace if clause with `process.env.NODE_ENV === 'test'`
   */
  if (!import.meta.env) {
    return {
      FE_GQL_ADMIN_SECRET: '',
      FE_GQL_ENDPOINT: '',
    };
  }
  return {
    FE_GQL_ENDPOINT: import.meta.env.FE_GQL_ENDPOINT,
    FE_GQL_ADMIN_SECRET: import.meta.env.FE_GQL_ADMIN_SECRET,
  };
};
// Default gqlClient with all endpoints configured.
const gqlClient = new GraphQLClient(getHasuraEnvVariables().FE_GQL_ENDPOINT, {
  headers: {
    'x-hasura-admin-secret': getHasuraEnvVariables().FE_GQL_ADMIN_SECRET,
  },
});

// Export all generated types
export * from './generated/graphql';
export { urqlGql } from './urql';
export { UserStatus, ConsentStatusEnum };

export function apiServicesSdk(
  client: GraphQLClient = gqlClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper
) {
  return getSdk(client, withWrapper);
}

export const gql = apiServicesSdk();
