import { useEffect } from 'react';
import { WithRouteData } from '../render-routes';
import { useManagerCtxSelector } from '../xstate/app.xstate';
import { useNavigate } from 'react-router-dom';
import { Loader, ReleaseChannels } from '../components';

export const DefaultEnvironmentPage = () => {
  const navigate = useNavigate();
  const { routes, project } = useManagerCtxSelector((ctx) => {
    return {
      project: ctx.projects?.find(
        ({ projectId }) => projectId === ctx.selectedProject
      ),
      routes: ctx.routes,
    };
  });

  useEffect(() => {
    if (project && project?.projectId) {
      const defaultEnv = project?.projectReleaseChannelConfig?.find(
        (channel) =>
          channel?.releaseChannel?.name === ReleaseChannels.Development
      );
      navigate(
        `${routes.projects}/${project?.projectId}/environments/${defaultEnv?.id}`,
        { replace: true }
      );
    }
  }, [project, routes.projects, navigate]);

  return <Loader />;
};

export default WithRouteData(DefaultEnvironmentPage);
