import React from 'react';
import { ArrayFieldDescriptionProps, descriptionId } from '@rjsf/utils';
import { Spacer, TextBody } from '@pypestream/design-system';

export function ArrayFieldDescriptionTemplate(
  props: ArrayFieldDescriptionProps
) {
  const { description, idSchema } = props;
  const id = descriptionId(idSchema);
  return (
    <>
      <Spacer size="small" />
      <TextBody size="small" variant="secondary" id={id}>
        {description}
      </TextBody>
      <Spacer size="xlarge" />
    </>
  );
}
