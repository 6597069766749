import {
  Button,
  ButtonGroup,
  Icon,
  IconButton,
  Scrollbar,
  Spacer,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
  TextTitle,
  ModalProps,
} from '@pypestream/design-system';
import { TranslationComponent } from '@pypestream/translations';
import React, { useRef } from 'react';

import { HomepageModal } from './homepage-modal';
import { ProjectItem } from '../../components';
import {
  OrganizationRoleNames,
  useAuthRole,
  useLoadingState,
} from '../../hooks';
import {
  useManagerCtxSelector,
  sendManagerEvent,
} from '../../xstate/app.xstate';
import homepageEmptyStateBg from '../../assets/homepage-empty-state.svg?url';
import './homepage.scss';

export const HomepageContent = (): JSX.Element => {
  const loading = useLoadingState();

  const isAdminRole = useAuthRole([
    OrganizationRoleNames.ADMIN,
    OrganizationRoleNames.SUPER_ADMIN,
  ]);

  const { projects, userProjectIds } = useManagerCtxSelector((ctx) => ({
    projects: ctx.projects,
    userProjectIds: ctx.userProjectIds,
    orgId: ctx.selectedOrgId,
  }));

  const modalRef = useRef<ModalProps>(null);

  /*
    the method for defining the list of projects should be identical to the similar method in the Mega Menu:
    design-system/src/smart-components/mega-menu/mega-menu.wc.ts
  */
  const projectsWithAccessibleTools = projects?.filter((project) =>
    userProjectIds.includes(project.projectId)
  );

  const isEmptyState = projectsWithAccessibleTools?.length === 0 || loading;

  return (
    <>
      <HomepageModal modalRef={modalRef} />
      {isEmptyState ? (
        <div
          style={{
            backgroundImage: `url(${homepageEmptyStateBg})`,
            backgroundRepeat: 'no-repeat',
            // backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '250px',
            flexGrow: 1,
            marginLeft: '-3rem',
            marginRight: '-3rem',
          }}
        >
          <Text
            size="medium"
            fontWeight="medium"
            style={
              {
                '--ps-theme-text-color': '#BCB2F2',
              } as React.CSSProperties
            }
          >
            <TranslationComponent i18nKey="manager/common:homepage.projects.nothingToShowHere">
              Nothing to show here...{' '}
            </TranslationComponent>
          </Text>
          {isAdminRole && (
            <>
              <Text size="2xsmall" className="u-margin-bottom-large">
                <TranslationComponent i18nKey="manager/common:homepage.projects.toGetStartedCreateProject">
                  To get started, create your first project.
                </TranslationComponent>
              </Text>

              <Button
                size="large"
                data-cy="new-project"
                onClick={(e) => {
                  if (modalRef.current) {
                    modalRef.current.open = true;
                  }
                }}
              >
                <Icon slot="prefix" name="plus" size="xsmall" />
                <TranslationComponent i18nKey="manager/common:homepage.projects.createProject">
                  Create Project
                </TranslationComponent>
              </Button>
            </>
          )}
        </div>
      ) : (
        <>
          <section>
            <Stack alignItems="center" justifyContent="space-between">
              <TextTitle size="small">
                <TranslationComponent i18nKey="manager/common:homepage.projects.yourProjects">
                  Your Projects
                </TranslationComponent>
              </TextTitle>
              {isAdminRole && (
                <ButtonGroup>
                  <Button
                    size="large"
                    data-cy="new-project"
                    i18nKey="manager/common:homepage.projects.createProject"
                    onClick={(e) => {
                      if (modalRef.current) {
                        modalRef.current.open = true;
                      }
                    }}
                  >
                    <Icon slot="prefix" name="plus" size="xsmall" />
                    <TranslationComponent i18nKey="manager/common:homepage.projects.createProject">
                      Create Project
                    </TranslationComponent>
                  </Button>
                </ButtonGroup>
              )}
            </Stack>
          </section>
          <Spacer />
          <Scrollbar forceVisible disableAutoHide>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell variant="th">
                    <TranslationComponent i18nKey="manager/common:homepage.projects.project">
                      Project
                    </TranslationComponent>
                    <IconButton
                      size="xsmall"
                      name="chevron-down"
                      slot="sorter"
                    />
                  </TableCell>
                  <TableCell variant="th" sortActive>
                    <TranslationComponent i18nKey="manager/common:homepage.projects.tools">
                      Tools
                    </TranslationComponent>
                    <IconButton
                      size="xsmall"
                      name="chevron-down"
                      slot="sorter"
                    />
                  </TableCell>
                  <TableCell variant="th" align="start">
                    <TranslationComponent i18nKey="manager/common:homepage.projects.lastUpdated">
                      Last updated
                    </TranslationComponent>
                    <IconButton
                      size="xsmall"
                      name="chevron-down"
                      slot="sorter"
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projectsWithAccessibleTools?.map((item) => (
                  <ProjectItem
                    key={item.projectId}
                    item={item}
                    onClick={() =>
                      sendManagerEvent({
                        type: 'manager.selectProjectModal',
                        id: item.projectId,
                      })
                    }
                  />
                ))}
              </TableBody>
            </Table>
          </Scrollbar>
        </>
      )}
    </>
  );
};
