import React, { createElement } from 'react';

// Import required methods and styles from the FilePond module, should not need anything else
import {
  create,
  supported,
  registerPlugin,
  FileStatus,
  FilePond as FilePondType,
  FilePondOptions,
} from 'filepond';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import { EntityTypes } from './filepond.types';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.min.css';
import './upload.scss';

// We need to be able to call the registerPlugin method directly so we can add plugins
export { registerPlugin, FileStatus };

registerPlugin(
  FilePondPluginFilePoster,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview
);

// registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

// Do this once
const isSupported = supported();

// filtered methods
const filteredMethods = [
  'setOptions',
  'on',
  'off',
  'onOnce',
  'appendTo',
  'insertAfter',
  'insertBefore',
  'isAttachedTo',
  'replaceElement',
  'restoreElement',
  'destroy',
];

interface FilepondReactAdapterProps extends FilePondOptions {
  acceptedFileTypes?: string[];
  accountId?: string;
  entityId?: string;
  entityType?: EntityTypes;
  pictureSrc?: string;
  name?: string;
  onChange?: (url?: string) => void;
}

// The React <FilePond/> wrapper
export class FilepondReactAdapter extends React.Component {
  private _element: Element | null | undefined;

  private _input: Element | undefined;

  private _inputClone: Node | null | undefined;

  private _pond: FilePondType | undefined;

  private allowFilesSync: boolean = false;

  props: FilepondReactAdapterProps;

  constructor(props: FilepondReactAdapterProps) {
    super(props);
    this.allowFilesSync = true;
    this.props = props;
  }

  // Will setup FilePond instance when mounted
  componentDidMount() {
    // clone the input so we can restore it in unmount
    this._input =
      this._element?.querySelector('input[type="file"]') || undefined;
    this._inputClone = this._input?.cloneNode();

    // exit here if not supported
    if (!isSupported) return;

    const options: FilepondReactAdapterProps = { ...this.props };

    // if onupdate files is defined, make sure setFiles does not cause race condition
    if (options.onupdatefiles) {
      const cb = options.onupdatefiles;
      options.onupdatefiles = (items) => {
        this.allowFilesSync = false;
        cb(items);
      };
    }

    // Create our pond
    // https://github.com/pqina/pintura-example-filepond/blob/main/index.html
    this._pond = create(this._input, {
      ...options,

      allowReorder: true,
      filePosterMaxHeight: 256,
    });

    // Reference pond methods to FilePond component instance
    Object.keys(this._pond)
      .filter((key) => !filteredMethods.includes(key))
      .forEach((key) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this[key] = this._pond?.[key];
      });
  }

  // Will clean up FilePond instance when unmounted
  componentWillUnmount() {
    // exit when no pond defined
    if (!this._pond) return;

    // This fixed <Strict> errors

    // FilePond destroy is async so we have to move FilePond to a bin element so it can no longer affect current element tree as React unmount / mount is sync
    const bin = document.createElement('div');

    if (this._pond.element) {
      bin.append(this._pond.element);
    }

    bin.id = 'bin';

    // now we call destroy so FilePond can start it's destroy logic
    this._pond.destroy();
    this._pond = undefined;

    // we re-add the original file input element so everything is as it was before
    if (this._inputClone) {
      this._element?.append(this._inputClone);
    }
  }

  shouldComponentUpdate() {
    if (!this.allowFilesSync) {
      this.allowFilesSync = true;
      return false;
    }
    return true;
  }

  // Something changed
  componentDidUpdate() {
    // exit when no pond defined
    if (!this._pond) return;

    const options: FilepondReactAdapterProps = { ...this.props };

    // this is only set onces, on didmount
    delete options.onupdatefiles;

    // update pond options based on new props
    this._pond.setOptions(options);
  }

  // Renders basic element hook for FilePond to attach to
  render() {
    const {
      id,
      name,
      className,
      allowMultiple,
      required,
      captureMethod,
      acceptedFileTypes,
    } = this.props;

    return createElement(
      'div',
      {
        className: 'filepond--wrapper',
        ref: (element) => {
          this._element = element;
        },
      },
      createElement('input', {
        type: 'file',
        name,
        id,
        accept: acceptedFileTypes,
        multiple: allowMultiple,
        required,
        className,
        capture: captureMethod,
      })
    );
  }
}
