import { html, PropertyValueMap, PropertyValues, unsafeCSS } from 'lit';
import { unsafeSVG } from 'lit/directives/unsafe-svg.js';
import { property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { BrowserSprite } from '@pypestream/vite-plugin-svg-sprite/es/runtime';
import { BaseElement, customElement } from '../base-element';
import { icons } from './icon.helper';
import iconStyles from './icon.scss?inline';
import type { IconName } from './icon.types';

@customElement('ps-icon')
export class IconWC extends BaseElement {
  static styles = unsafeCSS(iconStyles);

  /** Icon name. */
  @property({ reflect: true }) name: IconName = 'arrow-right';

  /** Icon size. */
  @property({ reflect: true }) size:
    | 'auto'
    | 'xsmall'
    | 'small'
    | 'medium'
    | 'large'
    | 'xlarge'
    | '2xlarge' = 'auto';

  @property({ reflect: true }) weight: 'regular' | 'medium' | 'bold' =
    'regular';

  @property({ reflect: true }) color?:
    | 'red'
    | 'teal'
    | 'gold'
    | 'purple'
    | 'gray';

  @property({ reflect: true }) variant: 'default' | 'outlined' | 'filled' =
    'default';

  @state()
  protected _resolvedName = '';

  @state()
  protected _renderedSvg: ReturnType<typeof html>;

  @property() spritesheet: BrowserSprite;

  async willUpdate(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>
  ) {
    super.willUpdate(changedProperties as PropertyValues<this>);

    if (!this.spritesheet) {
      this.spritesheet = window.svgSpriteSheet;
    }
    if (!this.spritesheet) return;

    if (changedProperties.has('_resolvedName')) {
      setTimeout(async () => {
        const renderedChildren = this?.spritesheet
          ?.find(this._resolvedName)
          ?.render()?.children;

        if (renderedChildren?.length) {
          const innerHTML = Array.from(renderedChildren)
            .map((child) => child.outerHTML)
            .join('');
          const result = html`<svg
            viewBox=${this?.spritesheet?.find(this._resolvedName)?.viewBox}
            xmlns="http://www.w3.org/2000/svg"
            part="icon"
          >
            ${unsafeSVG(innerHTML)}
          </svg>`;
          this._renderedSvg = result;
        }
      }, 1);
    }
  }

  protected updated(_changedProperties: PropertyValues): void {
    if (this.name in icons) {
      if ('default' in icons[this.name]) {
        if (
          !this._resolvedName ||
          this._resolvedName !== icons[this.name].default
        ) {
          this._resolvedName = icons[this.name].default;
        }
      }
    }
  }

  render() {
    return html`
      <span
        data-cy="icon"
        class="${classMap({
          'c-icon': true,
          [`c-icon--size-${this.size}`]: this.size,
          [`c-icon--weight-${this.weight}`]: this.weight,
          [`c-icon--color-${this.color}`]: !!this.color,
          [`c-icon--variant-${this.variant}`]: this.variant,
        })}"
        part="icon"
      >
        ${this._renderedSvg}
      </span>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-icon': IconWC;
  }
  enum PSElementTagNameMap {
    'ps-icon' = 'ps-icon',
  }
}
