/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-console */
import { html, nothing } from 'lit';
import { property, state } from 'lit/decorators.js';
import { ifDefined } from 'lit-html/directives/if-defined.js';
import { i18n } from '@pypestream/translations';

import {
  BaseElement,
  customElement,
  SlotObserver,
  watch,
} from '../../components/base-element';
import { smartService } from '../xstate/smart.xstate';
import { SmartContext } from '../xstate/smart.xstate-utils';
import { generateBaseURLsToTools } from '../utils';
import { Translate } from '../../components/base-element/mixins/translation-mixin';

import '../universal-nav/universal-nav-org-selector.wc';
import '../../components/dropdown/src/dropdown.wc';

export type Env = 'candidate' | 'prod' | 'nonprod';

type UniversalUserDropdownState = {
  firstName?: string;
  lastName?: string;
  profilePhoto?: string;
  email?: string;
  defaultOrg?: string;
  assignedRoles?: {
    id?: string;
    name?: string;
  }[];
  assignedTeams?: {
    id?: string;
    name?: string;
    assignedRoles?: {
      id?: string;
      name?: string;
    }[];
  }[];
};

/**
 * A smart component to switch between orgs + handle common user tasks like logging out
 *
 * @element ps-universal-user-dropdown
 *
 * @slot dropdown-top - content that appears above the main content area
 * @slot dropdown-bottom - content that appears below the `dropdown-top` slot
 */
@customElement('ps-universal-user-dropdown')
export class UniversalUserDropdownWC extends SlotObserver(
  Translate(BaseElement),
  ['[slot="dropdown-top"]', '[slot="dropdown-bottom"]']
) {
  context: SmartContext;

  /**
   * optional setting to hide the "My Account" link that normally shows up
   */
  @property({ reflect: false, type: Boolean }) hideMyAccount?: boolean = false;

  /**
   * method that fires when the user attempts to log out
   */
  private onLogout() {
    smartService.send({
      type: 'logout',
      callback: () => {
        this.emit('logout');
      },
    });
  }

  /**
   * method that fires when the user wants to navigate to the My Account screen
   */
  private onPreferencesClick() {
    this.emit('preferences-click');
  }

  /**
   * a method that fires when the user switches the current organization
   */
  private onSelectionChange(selection: string | undefined) {
    this.emit('selection-change', { detail: { selection } });
  }

  private onSuperAdminClick() {
    this.emit('super-admin-click');
    const basePath = this.context?.accountId
      ? `/organization/${this.context.accountId}`
      : '';
    const redirectURL = `${generateBaseURLsToTools(window.location).Manager}${basePath}/super-admin`;
    document.location.href = redirectURL;
  }

  private isSuperAdmin(): boolean {
    const getCurrentUserRoles = () => {
      const rolesAssignedThroughTeams: {
        label?: string;
        value?: string;
      }[] = [];
      (this.localState.assignedTeams ?? []).forEach(({ assignedRoles }) => {
        (assignedRoles ?? []).forEach(({ id, name }) => {
          rolesAssignedThroughTeams.push({
            label: name,
            value: id,
          });
        });
      });

      const directlyAssignedRoles = (this.localState.assignedRoles ?? []).map(
        ({ name, id }) => ({
          label: name,
          value: id,
        })
      );

      return [...rolesAssignedThroughTeams, ...directlyAssignedRoles];
    };

    return !!getCurrentUserRoles().find((role) => role.label === 'Super Admin');
  }

  @state() private localState: UniversalUserDropdownState = {
    firstName: 'FirstName',
    lastName: 'LastName',
    profilePhoto: undefined,
    email: undefined,
    defaultOrg: undefined,
  };

  @watch('lang')
  async onLangChanged() {
    // @ts-ignore
    await this.reloadLanguage();
  }

  constructor() {
    super();
    this.init = this.init.bind(this);
  }

  async connectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.connectedCallback();

    smartService.subscribe((smartState) => {
      this.context = smartState.context;
      this.init();
    });
  }

  private async init() {
    const { userInfo } = this.context;

    if (userInfo?.settings?.defaultLanguage) {
      this.lang = userInfo.settings.defaultLanguage.split('-')[0];
    }

    const defaultOrg = userInfo?.defaultAccount?.id;

    if (!defaultOrg) {
      return;
    }

    this.localState = {
      ...this.localState,
      email: userInfo?.email,
      firstName: userInfo?.firstName || 'FirstName',
      lastName: userInfo?.lastName || 'LastName',
      profilePhoto: userInfo?.picture,
      defaultOrg: userInfo?.defaultAccount?.id,
      assignedTeams: userInfo?.assignedTeams,
      assignedRoles: userInfo?.assignedRoles,
    };

    this.requestUpdate();
  }

  private renderAvatar(size: 'medium' | 'large' = 'medium') {
    return html`<ps-avatar
      size=${size}
      src=${ifDefined(this.localState.profilePhoto)}
      label=${`${this.localState.firstName} ${this.localState.lastName}`}
    ></ps-avatar>`;
  }

  render() {
    const { firstName, lastName, email, defaultOrg } = this.localState;

    return html`
      <ps-dropdown
        id="profile-dropdown"
        trigger="profile-dropdown-trigger"
        placement="bottom-start"
        .projectedContent=${this}
        keep-mounted
      >
        <div style="padding: 8px" slot="dropdown-top">
          <ps-stack alignItems="center">
            ${this.renderAvatar('large')}
            <div>
              <ps-text-body>${firstName} ${lastName}</ps-text-body>
              <ps-text-caption variant="tertiary">${email}</ps-text-caption>
            </div>
          </ps-stack>
        </div>
        <ps-spacer size="small" slot="dropdown-top"></ps-spacer>
        <ps-divider slot="dropdown-top"></ps-divider>
        <ps-spacer size="small" slot="dropdown-top"></ps-spacer>

        <slot name="dropdown-top" slot="dropdown-top"></slot>

        ${this.hasSlottedContent('[slot="dropdown-top"]')
          ? html`<ps-spacer size="small" slot="dropdown-top"></ps-spacer>
              <ps-divider slot="dropdown-top"></ps-divider>
              <ps-spacer size="small" slot="dropdown-top"></ps-spacer>`
          : nothing}
        ${this.context?.accountId || defaultOrg
          ? html`
              <ps-menu-org-selector
                org=${ifDefined(this.context?.accountId || defaultOrg)}
                .onSelectionChange=${this.onSelectionChange}
              ></ps-menu-org-selector>
              <ps-spacer size="small"></ps-spacer>
              <ps-divider></ps-divider>
              <ps-spacer size="small"></ps-spacer>
            `
          : undefined}

        <slot name="dropdown-bottom"></slot>

        <ps-menu slot="dropdown-bottom">
          ${this.isSuperAdmin()
            ? html`
                <ps-menu-item @click=${this.onSuperAdminClick}>
                  ${i18n.t('navigation:userDropdown.superAdmin') ||
                  'Super Admin'}
                </ps-menu-item>
              `
            : nothing}
          ${this.hideMyAccount
            ? nothing
            : html` <ps-menu-item @click=${this.onPreferencesClick}>
                ${i18n.t('navigation:userDropdown.userAccount') || 'My Account'}
              </ps-menu-item>`}

          <ps-menu-item @click=${this.onLogout}>
            ${i18n.t('navigation:userDropdown.logout') || 'Logout'}
          </ps-menu-item>
        </ps-menu>
      </ps-dropdown>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-universal-user-dropdown': UniversalUserDropdownWC;
  }
  enum PSElementTagNameMap {
    'ps-universal-user-dropdown' = 'ps-universal-user-dropdown',
  }
}
