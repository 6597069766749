/* eslint-disable class-methods-use-this */
/* eslint-disable wc/guard-super-call, wc/no-self-class */
import { html, PropertyValues, unsafeCSS } from 'lit';
import { property, query } from 'lit/decorators.js';
import { Swiper } from 'swiper';
import { IntersectionController } from '@lit-labs/observers/intersection-controller.js';
import { BaseElement, customElement } from '../base-element';
import { FocusTrapController } from '../../internal';
import styles from './stepper-item.scss?inline';

export const tagname = 'ps-stepper-item';
@customElement(tagname)
export class StepperItemWC extends BaseElement {
  static styles = unsafeCSS(styles);

  focusTrapController = new FocusTrapController(this, false);

  resizeObserver: ResizeObserver;

  currentHeight?: number;

  @property({ reflect: true, type: String })
  name: string = '';

  @property({ reflect: true, type: Boolean })
  active? = false;

  @property({ reflect: true, type: Boolean })
  isVisible? = false;

  @query('form') private _formQuery: HTMLFormElement | null;

  private _form?: HTMLFormElement | null;

  private _observer = new IntersectionController(this, {
    skipInitial: false,
    callback: (entries) => {
      setTimeout(() => {
        entries.map((entry) => {
          this.isVisible =
            entry.target.checkVisibility({
              checkVisibilityCSS: true,
            }) && entry.isIntersecting;

          return entry;
        });
      }, 300);
    },
    config: {
      threshold: [0.8],
    },
  });

  connectedCallback() {
    super.connectedCallback();
    this.focusTrapController.hostConnected();
    this.classList.add('swiper-slide');
    this.classList.add('c-stepper__item');

    this._observer.hostConnected();
  }

  firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);

    this.resizeObserver = new ResizeObserver((entry) => {
      const newHeight = entry[0].contentRect.height;
      if (!this.currentHeight || this.currentHeight !== newHeight) {
        this.currentHeight = newHeight;
        // TODO: throw an error if stepper item doesn't have stepper as a parent
        (
          this.parentNode as unknown as { swiper?: Swiper }
        ).swiper?.updateAutoHeight();
      }
    });
    // this.resizeObserver.observe(this);

    if (this._formQuery) {
      this._form = this._formQuery;
    } else {
      this._form =
        this.shadowRoot?.querySelector('form') || this.querySelector('form');
    }
  }

  validateStep(): boolean {
    return !!this._form?.checkValidity();
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.resizeObserver.unobserve(this);
    this.focusTrapController.deactivate();
  }

  async update(changedProps: Map<string, unknown>) {
    super.update(changedProps);

    if (changedProps.has('isVisible')) {
      if (this.isVisible && this.active) {
        this.focusTrapController.activate();
        this.resizeObserver.observe(this);
      } else if (this.hasUpdated) {
        this.focusTrapController.deactivate();
        this.resizeObserver.unobserve(this);
      }
    }
  }

  render() {
    return html` <div class="c-stepper-item">
      <slot></slot>
      ${this.slotted('footer')
        ? html`<div class="c-stepper-item__footer">
            <slot name="footer"></slot>
          </div>`
        : null}
    </div>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-stepper-item': StepperItemWC;
  }
  enum PSElementTagNameMap {
    'ps-stepper-item' = 'ps-stepper-item',
  }
}
