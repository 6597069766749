import React, { ChangeEvent, FocusEvent } from 'react';
import {
  ariaDescribedByIds,
  BaseInputTemplateProps,
  FormContextType,
  labelValue,
  RJSFSchema,
  StrictRJSFSchema,
} from '@rjsf/utils';
import { Input } from '@pypestream/design-system';

/** The `BaseInputTemplate` is the template to use to render the basic `<input>` component for the `core` theme.
 * It is used as the template for rendering many of the <input> based widgets that differ by `type` and callbacks only.
 * It can be customized/overridden for other themes or individual implementations as needed.
 *
 * @param props - The `WidgetProps` for this template
 */
export function BaseInputTemplate<
  T,
  S extends StrictRJSFSchema = RJSFSchema,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  F extends FormContextType = any,
>(props: BaseInputTemplateProps<T, S, F>) {
  const {
    id,
    placeholder,
    required,
    readonly,
    disabled,
    label,
    hideLabel,
    value,
    onChange,
    onChangeOverride,
    onBlur,
    onFocus,
    autofocus,
    options,
    schema,
    rawErrors = [],
  } = props;

  const onChangeHandler = ({
    target: { value: val },
  }: ChangeEvent<HTMLInputElement>) =>
    onChange(val === '' ? options.emptyValue : val);

  const onBlurHandler = ({
    target: { value: val },
  }: FocusEvent<HTMLInputElement>) => onBlur(id, val);

  const onFocusHandler = ({
    target: { value: val },
  }: FocusEvent<HTMLInputElement>) => onFocus(id, val);

  return (
    <Input
      variant="outlined"
      id={id}
      name={id}
      placeholder={
        placeholder || labelValue(label || undefined, hideLabel, undefined)
      }
      autofocus={autofocus}
      required={required}
      disabled={disabled || readonly}
      value={value || ''}
      hasError={rawErrors.length > 0}
      onChange={onChangeOverride || onChangeHandler}
      onBlur={onBlurHandler}
      onFocus={onFocusHandler}
      aria-describedby={ariaDescribedByIds<T>(id, !!schema.examples)}
    />
  );
}
