import React from 'react';
import { TitleFieldProps } from '@rjsf/utils';
import { TextSubtitle } from '@pypestream/design-system';

export function TitleFieldTemplate(props: TitleFieldProps) {
  const { id, required, title } = props;
  return (
    <TextSubtitle id={id} size="small" variant="secondary">
      {title}
      {required && <mark>*</mark>}
    </TextSubtitle>
  );
}
