import { useEffect, useState } from 'react';
import { PackageTypeEnum, urqlGql } from '@pypestream/api-services';
import { GetPackagesByPackageTypeQuery } from '@pypestream/api-services/dist/generated/graphql.urql';

export const usePackages = (packageType: PackageTypeEnum) => {
  // Use the PackageOption type in the state definition
  const [packages, setPackages] = useState<
    NonNullable<
      NonNullable<GetPackagesByPackageTypeQuery['admin_']>['packages']
    >
  >([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    urqlGql
      .getPackagesByPackageType({
        packageType: packageType,
      })
      .then((res) => {
        const mappedPackages = res.data?.admin_?.packages?.map((p) => {
          return {
            ...p,
            latestVersion: p.latestVersion?.id // Check if `id` exists first
              ? {
                  ...p.latestVersion,
                  schema:
                    typeof p.latestVersion?.schema === 'string'
                      ? JSON.parse(p.latestVersion?.schema)
                      : p.latestVersion?.schema,
                }
              : undefined, // If `id` is not present, return `undefined` for `latestVersion`
          };
        });
        if (mappedPackages) {
          setPackages(mappedPackages);
        }
        setLoading(false);
      });
  }, [packageType]);

  return {
    loading,
    packages: packages || [],
  };
};
