import React from 'react';
import { AppletCardAvatar, Toggle, Stack } from '@pypestream/design-system';
import { WidgetProps } from '@rjsf/utils';
import { AppletStatusEnum } from '@pypestream/api-services';
import { AppletInstance } from '@pypestream/api-services/dist/generated/graphql.urql';
import { formatDistance, parseISO } from 'date-fns';
import {
  dateFnsLocales,
  i18n,
  fallbackLanguage,
} from '@pypestream/translations';

export const StatusWidget = function (
  props: WidgetProps & Pick<AppletInstance, 'updatedByUser' | 'updatedAt'>
) {
  return (
    <Stack justifyContent="space-between">
      <Toggle
        value={AppletStatusEnum.Active}
        checked={props.value === AppletStatusEnum.Active}
        onChange={(e) => {
          props.onChange(
            e.target.checked
              ? AppletStatusEnum.Active
              : AppletStatusEnum.Inactive
          );
        }}
      />
      <div>
        <AppletCardAvatar
          date={formatDistance(
            parseISO(props.updatedAt || new Date().toISOString()),
            new Date(),
            {
              addSuffix: true,
              locale: dateFnsLocales[i18n.language || fallbackLanguage],
            }
          )}
          slot="footer"
          label={`${props.updatedByUser?.firstName} ${props.updatedByUser?.lastName}`}
          src={props.updatedByUser?.picture || ''}
        />
      </div>
    </Stack>
  );
};
