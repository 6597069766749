/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import _ from 'lodash';

export const getStateChanges = <T>(object: T, base: T): Partial<T> | null => {
  const changes = (
    obj: Record<string, unknown>,
    bas: Record<string, unknown>
  ): Partial<T> =>
    _.transform(
      obj,
      (result, value, key) => {
        const baseValue = bas ? bas[key] : undefined;
        if (!_.isEqual(value, baseValue)) {
          if (_.isObject(value) && _.isObject(baseValue)) {
            const nestedChanges = changes(
              value as Record<string, unknown>,
              baseValue as Record<string, unknown>
            );
            if (!_.isEmpty(nestedChanges)) {
              // @ts-ignore
              (result as unknown)[key] = nestedChanges;
            }
          } else {
            // @ts-ignore
            (result as unknown)[key] = value;
          }
        }
      },
      {} as Partial<T>
    );

  const result = changes(
    object as Record<string, unknown>,
    base as Record<string, unknown>
  );
  return _.isEmpty(result) ? null : result;
};
