/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  interpret,
  Machine,
  State,
  Interpreter,
  forwardTo,
  assign,
} from 'xstate';
import { inspect } from '@xstate/inspect';
import { isProduction } from '@pypestream/utils';
import { initialize } from 'launchdarkly-js-client-sdk';

import { WindowWithPypestreamGlobals } from './global';
import {
  userMachine,
  UserInterpreter,
  createUserXstateHooks,
  UserContext,
  UserEvents,
  UserState,
  UserTypestates,
} from './user.xstate';
import {
  GlobalAppContext,
  GlobalAppEvents,
  GlobalAppStateSchema,
  GlobalAppTypestates,
  globalAppActions,
  appActivities,
  sendUserMessage,
  createAppXstateHooks,
  GLOBAL_APP_SUB_MACHINE_IDS,
} from './app.xstate-utils';
import {
  createManagerXstateHooks,
  ManagerInterpreter,
  managerMachine,
  ManagerContext,
  ManagerState,
  ManagerEvents,
  ManagerTypestates,
} from './manager.xstate';
import {
  AvailableFeatureFlags,
  FeatureFlagType,
} from '../feature-flags/feature-flags.types';
import { DefaultFeatureFlags } from '../feature-flags/feature-flags.default';
import { isValidFlag } from '../utils/feature-flags';

// @todo: update to support overrides (for debugging) via external super admin controls
const enableStateInspector = false;
if (enableStateInspector) {
  inspect({
    url: 'https://stately.ai/viz?inspect=1',
    iframe: false,
  });
}

const isUnitTesting = false;

/**
 * Note: the placeholder action is the minimum thing needed for an event for it to be registered.
 * This is sometimes used for stubbing out future events, or if something else is listening for those events
 */
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const placeholderAction = (): void => {};

export type { GlobalAppContext, GlobalAppEvents };

const win = window as WindowWithPypestreamGlobals;

export const globalAppMachine = Machine<
  GlobalAppContext,
  GlobalAppStateSchema,
  GlobalAppEvents
>(
  {
    predictableActionArguments: true,
    id: 'app',
    context: {},
    strict: true,
    type: 'parallel',
    invoke: [
      {
        id: 'initializing-feature-flags',
        src: (ctx) => {
          if (Object.keys(DefaultFeatureFlags).length) {
            const client = initialize(
              LAUNCH_DARKLY_API_KEY || '',
              {
                kind: 'user',
                anonymous: true,
              },
              {
                // This is to prevent sending many events from app to launchDarkly.
                // @todo - Need to check how elegantly we can handle this as w.r.t. launch darkly docs, this might break some features on LD side. (features like, reports, ld logs and all) --> not good for AB testing.
                flushInterval: 900000,
              }
            );

            client.on('change', (updatedFlags) => {
              Object.keys(updatedFlags).forEach((updatedFlagKey) => {
                if (isValidFlag(updatedFlagKey)) {
                  sendGlobalAppEvent({
                    type: 'featureFlags.update',
                    // isValidFlag check is making sure that this typecast is correct
                    // @todo - Ideally we should not need this typecast, need to check for proper fix
                    updatedFlag: updatedFlagKey as AvailableFeatureFlags,
                    updatedValue: updatedFlags[updatedFlagKey].current,
                  });
                }
              });
            });

            return client
              .waitForInitialization()
              .then(() => {
                sendGlobalAppEvent({
                  type: 'featureFlags.initialized',
                  flags: Object.fromEntries(
                    Object.entries(client.allFlags()).filter(([key]) =>
                      isValidFlag(key)
                    )
                  ) as FeatureFlagType,
                });
              })
              .catch(() => {
                sendGlobalAppEvent({
                  type: 'featureFlags.initializedWithDefaultValues',
                });
                // @todo - Need to log this with datadog once available.
                console.log(
                  'Initialized application with build-time/default-flags values.'
                );
              });
          }
          // @todo - Need to log this with datadog
          console.warn(
            "Bypassed LD-initialization as we didn't had FFs build time."
          );
          return Promise.resolve();
        },
      },
      {
        id: GLOBAL_APP_SUB_MACHINE_IDS.user,
        src: userMachine,
      },
      {
        id: GLOBAL_APP_SUB_MACHINE_IDS.manager,
        src: managerMachine,
      },
    ],
    states: {
      featureFlags: {
        initial: 'idle',
        states: {
          idle: {
            on: {
              'featureFlags.initialized': {
                target: '#app.featureFlags.loaded.withActualValue',
                actions: [
                  assign((ctx, data) => {
                    ctx.featureFlags = data.flags;
                    return ctx;
                  }),
                ],
              },
              'featureFlags.initializedWithDefaultValues': {
                target: '#app.featureFlags.loaded.withDefaultValues',
                actions: [
                  assign((ctx) => {
                    ctx.featureFlags = DefaultFeatureFlags;
                    return ctx;
                  }),
                ],
              },
            },
          },
          loaded: {
            on: {
              'featureFlags.update': {
                actions: assign((ctx, data) => {
                  let updatedContext = ctx;
                  if (
                    ctx.featureFlags &&
                    data.updatedFlag in DefaultFeatureFlags
                  ) {
                    updatedContext = {
                      ...ctx,
                      featureFlags: {
                        ...ctx.featureFlags,
                        [data.updatedFlag]: data.updatedValue,
                      },
                    };
                  }
                  return updatedContext;
                }),
              },
            },
            states: {
              withActualValue: {},
              withDefaultValues: {},
            },
          },
        },
      },
    },
    on: {
      // globally allow any part of the system to send a Toast message
      'app.sendUserMessage': {
        actions: (ctx, event) => sendUserMessage(event.msg),
      },

      // 'manager.updateContext': {
      //   actions: [
      //     forwardTo(managerMachine.id),
      //     // forwardTo(managerMachine.id),
      //     // forwardTo(uiMachine.id),
      //   ],
      // },

      'user.signIn': {
        actions: [
          forwardTo(userMachine.id),
          // forwardTo(managerMachine.id),
          // forwardTo(uiMachine.id),
        ],
      },
      'user.assignedUserId': {
        actions: [
          forwardTo(managerMachine.id),
          // forwardTo(userMachine.id),
          // forwardTo(uiMachine.id),
        ],
      },
      'user.signOut': {
        actions: forwardTo(userMachine.id),
      },

      // 'user.loggedIn': {
      //   actions: uiMachine.id,
      // },
      // 'user.loggedOut': {
      //   actions: [forwardTo(userMachine.id), forwardTo(managerMachine.id), forwardTo(uiMachine.id)],
      // },

      // 'user.loggedIn': {
      //   actions: forwardTo(managerMachine.id),
      // },
      // 'user.loggedOut': {
      //   actions: forwardTo(managerMachine.id),
      // },

      // 'user.setProfilePhoto': {
      //   actions: forwardTo(userMachine.id),
      // },
      'app.log': {
        actions: [(ctx, event) => console.log(event.msg)],
      },

      noop: {
        actions: placeholderAction,
      },
    },
  },
  {
    activities: Object.fromEntries(
      Object.values(appActivities).map(({ id, exec }) => [id, exec])
    ),
    actions: Object.fromEntries(
      Object.values(globalAppActions).map(({ id, exec }) => [id, exec])
    ),
  }
);

export const globalAppService: GlobalAppServiceInterpreter = interpret<
  GlobalAppContext,
  GlobalAppStateSchema,
  GlobalAppEvents,
  GlobalAppTypestates,
  any
>(globalAppMachine, {
  devTools: enableStateInspector,
});

export const { send: sendGlobalAppEvent } = globalAppService;

export type GlobalAppServiceInterpreter = Interpreter<
  GlobalAppContext,
  GlobalAppStateSchema,
  GlobalAppEvents,
  GlobalAppTypestates,
  any
>;

function log({
  state,
  name,
}: {
  name: string;
  state:
    | State<
        GlobalAppContext,
        GlobalAppEvents,
        GlobalAppStateSchema,
        GlobalAppTypestates,
        any
      >
    | State<UserContext, UserEvents, UserState, UserTypestates, any>
    | State<
        ManagerContext,
        ManagerEvents,
        ManagerState,
        ManagerTypestates,
        any
      >;
}): void {
  if (isUnitTesting) return;

  console.debug(
    `[${name}]: "${state.event.type}" Xstate Event`,
    isProduction ? state.event : state // log less in prod
  );
}
// globalAppService.onTransition((state) => log({ state, name: 'app' }));

globalAppService.start();

win.pypestream = {
  globalAppService,
};

export const managerService = globalAppService.children.get(
  GLOBAL_APP_SUB_MACHINE_IDS.manager
) as ManagerInterpreter;

export const userService = globalAppService.children.get(
  GLOBAL_APP_SUB_MACHINE_IDS.user
) as UserInterpreter;

interface Service {
  name: GLOBAL_APP_SUB_MACHINE_IDS;
  service: UserInterpreter | ManagerInterpreter;
}

const services: Service[] = [
  {
    name: GLOBAL_APP_SUB_MACHINE_IDS.user,
    service: userService,
  },
  {
    name: GLOBAL_APP_SUB_MACHINE_IDS.manager,
    service: managerService,
  },
];

services.forEach(({ name, service }) => {
  if (!service) {
    throw new Error(`Missing XState ${name}Service`);
  }
});

export const { send: sendUserEvent } = userService;
export const { send: sendManagerEvent } = managerService;

userService.subscribe((state) =>
  log({ state, name: GLOBAL_APP_SUB_MACHINE_IDS.user })
);

// managerService.subscribe(async (state) => {
//   log({ state, name: GLOBAL_APP_SUB_MACHINE_IDS.manager });
// });

export const {
  useStateMatches: useGlobalAppStateMatches,
  useStateMatchesOneOf: useGlobalAppStateMatchesOneOf,
  useCtxSelector: useGlobalAppCtxSelector,
  useOnEvent: useGlobalAppOnEvent,
  useIsEventAllowed: useIsGlobalAppEventAllowed,
  waitForEvents: waitForGlobalAppEvents,
  useCurrentState: useGlobalAppCurrentState,
} = createAppXstateHooks(globalAppService);

export const {
  useCtxSelector: useUserCtxSelector,
  useStateMatches: useUserStateMatches,
  useStateMatchesOneOf: useUserStateMatchesOneOf,
  useOnEvent: useOnUserEvent,
  useIsEventAllowed: useIsUserEventAllowed,
  useCurrentState: useUserCurrentState,
} = createUserXstateHooks(userService);

export const {
  useCtxSelector: useManagerCtxSelector,
  useStateMatches: useManagerStateMatches,
  useStateMatchesOneOf: useManagerStateMatchesOneOf,
  useStateMatchesAllOf: useManagerStateMatchesAllOf,
  useOnEvent: useOnManagerEvent,
  useIsEventAllowed: useIsManagerEventAllowed,
  useCurrentState: useManagerCurrentState,
} = createManagerXstateHooks(managerService);

export const useCurrentState = () => {
  const global = useGlobalAppCurrentState();
  const user = useUserCurrentState();
  const manager = useManagerCurrentState();

  return {
    global,
    user,
    manager,
  };
};

// export function useAppCanEdit(): boolean {
//   return useGlobalAppStateMatches('apps.selected');
// }

/**
 * This is just an example of how to create an async function that sends an event, waits for the response event and resolves promise with it.
 */
async function getAppClientData(): Promise<void | { data: unknown }> {
  const event = await waitForGlobalAppEvents({
    eventToSend: {
      type: 'app.sendUserMessage',
      msg: {
        text: 'send toast when log comes through',
      },
    },
    events: ['app.log'],
  });

  if (event.type === 'app.log') {
    const { msg } = event;
    console.log('msg', msg);
  }
}

/**
 * Analytics that require site, user, client data
 */
(() => {
  /**
   * Subscribe to xstate machines (ex. appService and appClientDataService)
   * Once app ready, can be used to fire off events, analytics, etc
   */
  globalAppService.subscribe((globalAppState) => {
    if (globalAppState.matches('user.loggedIn.loaded.userInfo')) {
      globalAppService.subscribe((state) => {
        if (state.matches('apps.selected')) {
          console.log(
            'example of sending analytics when a specific app was selected'
          );
          // const { site } = state.context;
          // const { siteId, appClientMeta } = site;
          // const { user } = appService.state.context;
          // analytics, etc
        }
      });
    }
  });
})();

// userService.subscribe((state) =>
//   log({ state, name: GLOBAL_APP_SUB_MACHINE_IDS.user })
// );

// managerService.subscribe((state) =>
//   log({ state, name: GLOBAL_APP_SUB_MACHINE_IDS.manager })
// );
