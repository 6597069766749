import React, { useEffect } from 'react';
import {
  Hero,
  Background,
  PageBody,
  Page,
  Nav,
  NavLogo,
} from '@pypestream/design-system';
import { ConsentStatusEnum } from '@pypestream/api-services';
import { usePostHog } from 'posthog-js/react';
import { signal } from '@preact/signals-react';
import { Session } from '@ory/client/api';
import { datadogLogs } from '@datadog/browser-logs';
import { RumInitConfiguration, datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';

import { SidebarWrapper } from './components';
import {
  sendGlobalAppEvent,
  useManagerCtxSelector,
  useUserCtxSelector,
} from './xstate/app.xstate';
import { kratosApi } from './kratos.api';
import { removeTrailingSlash } from '@pypestream/utils';

const session = signal<Session | null>(null);

kratosApi
  .toSession()
  .then(({ data }) => {
    session.value = data;

    sendGlobalAppEvent({
      type: 'user.signIn',
      user: {
        // I'm trying to avoid passing in this info, only to have it immediately get overwritten
        // by our getCurrentUser GQL query
        //
        // email: data.identity.traits.email,
        // firstName: data.identity.traits.firstName,
        // lastName: data.identity.traits.lastName,
        // profilePhoto: data.identity.traits.picture,
        kratosId: data.identity.id,
      },
    });
  })
  .catch(() => {
    const returnTo = window.location.href;
    const authApp: string = import.meta.env.FE_AUTH_URL;
    // will replace fallback URL once code is on next branch
    // @todo: include way to use local auth running
    let url = new URL('https://login.pypestream.dev/');
    if (authApp !== undefined && authApp !== '') {
      url = new URL(authApp?.replaceAll('"', ''));
    }
    url.searchParams.append('return_to', returnTo);
    window.location.href = url.toJSON();
  });

export const AuthProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}): JSX.Element => {
  const homePageRoute = useManagerCtxSelector((ctx) => ctx.routes.home);

  const posthog = usePostHog();

  const { pathname } = window.location;

  const pathAsArray = removeTrailingSlash(pathname).split('/').filter(Boolean);
  const isHomePage = pathname === '/' || pathAsArray.length === 2;

  const { optionalConsentStatus, userId, userEmail, userName } =
    useUserCtxSelector((ctx) => ({
      optionalConsentStatus: ctx.user?.optionalConsentStatus,
      userId: ctx.user?.id,
      userEmail: ctx.user?.email,
      userName: `${ctx.user?.firstName} ${ctx.user?.lastName}`,
    }));

  useEffect(() => {
    if (!optionalConsentStatus) return;

    let datadogTrackingConsent: RumInitConfiguration['trackingConsent'] =
      'not-granted';

    if (optionalConsentStatus === ConsentStatusEnum.Accepted) {
      datadogTrackingConsent = 'granted';
      posthog.opt_in_capturing();
      datadogRum.startSessionReplayRecording();
      // not sure if we need this or if we can remove
      posthog.startSessionRecording();
      posthog.identify(userId, { email: userEmail, name: userName });
      console.log('start capturing');
    } else {
      console.log('opting out of posthog');
      posthog.stopSessionRecording();
      datadogRum.stopSessionReplayRecording();
      posthog.opt_out_capturing();
      Sentry.close().then(function () {
        console.log('opting out of Sentry');
      });
    }

    // https://docs.datadoghq.com/logs/log_collection/javascript/#user-tracking-consent
    datadogLogs.setTrackingConsent(datadogTrackingConsent);
    datadogRum.setTrackingConsent(datadogTrackingConsent);
  }, [optionalConsentStatus, posthog, userEmail, userId, userName]);

  if (!session.value) {
    return (
      <Page background="alt">
        <Nav slot="nav">
          <NavLogo
            href={homePageRoute}
            name={isHomePage ? undefined : 'manager'}
          >
            {isHomePage ? 'Pypestream' : 'Manager'}
          </NavLogo>
        </Nav>
        <SidebarWrapper />
        <Hero variant={'minimal'}>
          <div
            style={{
              minHeight: '204px',
            }}
          ></div>
        </Hero>
        <Background />
        <PageBody background="none" />
      </Page>
    );
  }

  // todo: do we need this?
  // const splittedPath = window.location.href.split('?');
  // window.history.pushState('authenticated', '', splittedPath[0]);
  return <>{children}</>;
};
