/* eslint-disable lit-a11y/no-aria-slot */
import { html, unsafeCSS, PropertyValues } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { property, state, query, eventOptions } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { live } from 'lit/directives/live.js';
import { BaseElement, customElement } from '../base-element';
import { Translate } from '../base-element/mixins/translation-mixin';
import styles from './slider.scss?inline';

@customElement('ps-slider')
export class SliderWC extends Translate(BaseElement) {
  static styles = unsafeCSS(styles);

  @query('.c-slider__range-control') input: HTMLInputElement;

  @query('.c-slider__range-tooltip') output: HTMLElement;

  /** The name of the slider, submitted as a name/value pair with form data. */
  @property({ reflect: true, type: String }) name = '';

  /** The slider's label, which will be displayed on the top. If you need to display HTML, use the `label` slot instead. */
  @property({ reflect: true, type: String }) label = '';

  /** The slider's help text. If you need to display HTML, use the help-text slot instead. */
  @property({ reflect: true, type: String, attribute: 'help-text' }) helpText =
    '';

  /** The interval at which the slider will increase and decrease. */
  @property({ reflect: true, type: Number }) step = 1;

  /** The minimum acceptable value of the slider. */
  @property({ reflect: true, type: Number }) min = 0;

  /** The maximum acceptable value of the slider. */
  @property({ reflect: true, type: Number }) max = 100;

  /** The current value of the slider, submitted as a name/value pair with form data. */
  @property({ reflect: true, type: Number }) value = 0;

  /** The default value of the slider control. Primarily used for resetting the slider control. */
  @property({ reflect: true, type: Number }) defaultValue = 0;

  /**
   * A function used to format the tooltip's value. The slider's value is passed as the first and only argument. The
   * function should return a string to display in the tooltip.
   */
  @property({ attribute: false }) tooltipFormatter = null;

  /** The preferred placement of the slider's tooltip. */
  @property({ reflect: true }) tooltip: 'top' | 'none' = 'none';

  /** Disables the slider. */
  @property({ reflect: true, type: Boolean }) disabled = false;

  /**
   * By default, form controls are associated with the nearest containing `<form>` element. This attribute allows you
   * to place the form control outside of a form and associate it with the form that has this `id`. The form must be in
   * the same document or shadow root for this to work.
   */
  @property({ reflect: true, type: Boolean }) form = '';

  /** Show or hide the label of minimum and maximum values of the slider */
  @property({ type: Boolean }) showMinMaxLabels = true;

  @state() currentValue = 0;

  @state() hasFocus = false;

  @state() hasTooltip = false;

  private resizeObserver: ResizeObserver;

  /** Gets the validity state object */
  get validity() {
    return this.input.validity;
  }

  /** Gets the validation message */
  get validationMessage() {
    return this.input.validationMessage;
  }

  connectedCallback(): void {
    // eslint-disable-next-line wc/guard-super-call
    super.connectedCallback();
    this.resizeObserver = new ResizeObserver(() => this.syncRange());
    if (this.value < this.min) {
      this.currentValue = this.min;
    } else if (this.value > this.max) {
      this.currentValue = this.max;
    }
    this.updateComplete.then(() => {
      this.syncRange();
      this.resizeObserver.observe(this.input);
    });
  }

  disconnectedCallback(): void {
    // eslint-disable-next-line wc/guard-super-call
    super.disconnectedCallback();
    this.resizeObserver.unobserve(this.input);
  }

  handleChange() {
    this.emit('change');
  }

  handleInput() {
    this.value = parseFloat(this.input.value);
    this.emit('input');
    this.syncRange();
  }

  handleBlur() {
    this.hasFocus = false;
    this.hasTooltip = false;
    this.emit('blur');
  }

  handleFocus() {
    this.hasFocus = true;
    this.hasTooltip = true;
    this.emit('focus');
  }

  @eventOptions({ passive: true })
  handleThumbDragStart() {
    this.hasTooltip = true;
  }

  handleThumbDragEnd() {
    this.hasTooltip = false;
  }

  handleInvalid(event: Event) {
    this.hasTooltip = false;
    // this.formControlController.setValidity(false);
    // this.formControlController.emitInvalidEvent(event);
  }

  syncRange() {
    const percent = Math.max(
      0,
      (this.value - this.min) / (this.max - this.min)
    );
    this.syncProgress(percent);
    if (this.tooltip !== 'none') {
      this.syncTooltip(percent);
    }
  }

  syncProgress(percent: number) {
    this.input.style.setProperty('--ps-slider-percent', `${percent * 100}%`);
  }

  syncTooltip(percent: number) {
    if (this.output !== null) {
      const inputWidth = this.input.offsetWidth;
      const tooltipWidth = this.output.offsetWidth;
      const thumbSize = getComputedStyle(this.input).getPropertyValue(
        '--ps-slider-thumb-size'
      );
      const isRtl = false;
      const percentAsWidth = inputWidth * percent;
      if (isRtl) {
        const x = `${
          inputWidth - percentAsWidth
        }px + ${percent} * ${thumbSize}`;
        this.output.style.translate = `calc((${x} - ${
          tooltipWidth / 2
        }px - ${thumbSize} / 2))`;
      } else {
        const x = `${percentAsWidth}px - ${percent} * ${thumbSize}`;
        this.output.style.translate = `calc(${x} - ${
          tooltipWidth / 2
        }px + ${thumbSize} / 2)`;
      }
    }
  }

  handleValueChange() {
    // this.formControlController.updateValidity();
    this.input.value = this.value.toString();
    this.value = parseFloat(this.input.value);
    this.syncRange();
  }

  /** Sets focus on the range. */
  focus(options: FocusOptions) {
    this.input.focus(options);
  }

  /** Removes focus from the range. */
  blur() {
    this.input.blur();
  }

  /** Increments the value of the range by the value of the step attribute. */
  stepUp() {
    this.input.stepUp();
    if (this.value !== Number(this.input.value)) {
      this.value = Number(this.input.value);
    }
  }

  /** Decrements the value of the range by the value of the step attribute. */
  stepDown() {
    this.input.stepDown();
    if (this.value !== Number(this.input.value)) {
      this.value = Number(this.input.value);
    }
  }

  /** Checks for validity but does not show a validation message. Returns `true` when valid and `false` when invalid. */
  checkValidity() {
    return this.input?.checkValidity();
  }

  /** Checks for validity and shows the browser's validation message if the control is invalid. */
  reportValidity() {
    return this.input?.reportValidity();
  }

  /** Sets a custom validation message. Pass an empty string to restore validity. */
  setCustomValidity(message: string) {
    this.input.setCustomValidity(message);
    // this.formControlController.updateValidity();
  }

  renderMarkLabel(val: number) {
    const position = val === 0 ? 0 : ((this.max - this.min) / val) * 100;

    return html`<label
      class="c-slider__range-ruler-label"
      style="--ps-slider-rule-label-position: ${position}%;"
      >${val}</label
    >`;
  }

  render() {
    const hasLabelSlot = this.slotted('label');
    const hasHelpTextSlot = this.slotted('help-text');
    const hasLabel = this.label ? true : !!hasLabelSlot;
    const hasHelpText = this.helpText ? true : !!hasHelpTextSlot;

    return html`
      <div
        class=${classMap({
          'c-slider': true,
          // range only has one size
          'c-slider--has-label': hasLabel,
          'c-slider--has-help-text': hasHelpText,
        })}
        data-cy="slider"
      >
        <label
          class="c-slider__label"
          for="input"
          aria-hidden=${hasLabel ? 'false' : 'true'}
          data-cy="slider-label"
        >
          <slot name="label">${this.label}</slot>
        </label>

        <div class="c-slider__input">
          <div
            class=${classMap({
              'c-slider__range': true,
              'c-slider__range--disabled': this.disabled,
              'c-slider__range--focused': this.hasFocus,
              'c-slider__range--rtl': false, // Skipped for now.
              'c-slider__range--tooltip-visible': this.hasTooltip,
              'c-slider__range--tooltip-top': this.tooltip === 'top',
            })}
            @mousedown=${this.handleThumbDragStart}
            @mouseup=${this.handleThumbDragEnd}
            @touchstart=${this.handleThumbDragStart}
            @touchend=${this.handleThumbDragEnd}
          >
            <input
              id="input"
              class="c-slider__range-control"
              title=${this.title}
              type="range"
              name=${ifDefined(this.name)}
              ?disabled=${this.disabled}
              min=${ifDefined(this.min)}
              max=${ifDefined(this.max)}
              step=${ifDefined(this.step)}
              .value=${live(this.value.toString())}
              aria-describedby="help-text"
              @change=${this.handleChange}
              @focus=${this.handleFocus}
              @input=${this.handleInput}
              @invalid=${this.handleInvalid}
              @blur=${this.handleBlur}
              data-cy="slider-input"
            />
            ${this.tooltip !== 'none' && !this.disabled
              ? html`
                  <output
                    class="c-slider__range-tooltip"
                    data-cy="slider-range-tooltip"
                  >
                    ${typeof this.tooltipFormatter === 'function'
                      ? (this.tooltipFormatter as (value: number) => string)(
                          this.value
                        )
                      : this.value}
                  </output>
                `
              : ''}
          </div>
          ${this.showMinMaxLabels
            ? html`
                <div class="c-slider__range-ruler" data-cy="slider-range-ruler">
                  ${[this.min, this.max].map((val) =>
                    this.renderMarkLabel(val)
                  )}
                </div>
              `
            : ''}
        </div>

        <slot
          name="help-text"
          id="help-text"
          class="c-slider__help-text"
          data-cy="slider-help-text"
          aria-hidden=${hasHelpText ? 'false' : 'true'}
        >
          ${this.helpText}
        </slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-slider': SliderWC;
  }
}
