/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-console */
import { html, nothing, PropertyValueMap } from 'lit';
import { property, state } from 'lit/decorators.js';
import { map } from 'lit/directives/map.js';
import { ProductName } from '@pypestream/api-services';
import { i18n } from '@pypestream/translations';

import type { MenuItemWC } from '../../components';
import { logos } from '../../assets/logos';
import megaMenuEmptyStateIcon from '../../assets/mega-menu-empty-state.svg?url';
import {
  BaseElement,
  customElement,
  watch,
} from '../../components/base-element';
import { ScrollbarWC } from '../../components/scrollbar/scrollbar.wc';
import { scrollIntoView } from '../../components/base-element/utilities/scroll';
import { SmartContext } from '../xstate/smart.xstate-utils';
import { smartService } from '../xstate/smart.xstate';
import { CommonProject } from '../universal-nav/types';
import { handleGoHome } from '../utils';
import { Translate } from '../../components/base-element/mixins/translation-mixin';

interface MegaMenuState {
  projects: CommonProject[];
  selectedProject?: CommonProject;
}

@customElement('ps-mega-menu')
export class MegaMenuWC extends Translate(BaseElement) {
  context: SmartContext;

  @watch('lang')
  async onLangChanged() {
    // @ts-ignore
    await this.reloadLanguage();
  }

  @property({ reflect: false }) project?: string;

  @property({ reflect: false, type: Boolean }) hideGoHome?: boolean = false;

  @state() private megaMenuState: MegaMenuState = {
    projects: [],
  };

  @state() onProjectSelect: (projectId: string | undefined) => void;

  @property() onManagerToolClick?: (route: string) => void;

  @state() private chatLink: string = '';

  @watch('project', { waitUntilFirstUpdate: true })
  handleResetSelectedProject() {
    this.megaMenuState.selectedProject = this.megaMenuState.projects.find(
      ({ id }) => id === this.project
    );
  }

  private chatTimerId: NodeJS.Timeout;

  constructor() {
    super();

    this.init = this.init.bind(this);
  }

  async connectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.connectedCallback();

    smartService.subscribe((smartState) => {
      this.context = smartState.context;
      this.init();
    });

    const check = () => {
      const chatHolder =
        document.getElementsByClassName('woot-widget-holder')?.[0];
      const iframeUrl = chatHolder?.firstElementChild?.getAttribute('src');

      if (!iframeUrl) {
        this.chatTimerId = setTimeout(check, 100);
      } else {
        this.chatLink = iframeUrl;
        clearInterval(this.chatTimerId);
      }
    };

    this.chatTimerId = setTimeout(check, 100);
  }

  protected updated(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>
  ): void {
    if (changedProperties.has('env') || changedProperties.has('org')) {
      this.init();
    }
  }

  disconnectedCallback() {
    // eslint-disable-next-line wc/guard-super-call
    super.disconnectedCallback();

    clearTimeout(this.chatTimerId);
  }

  init() {
    const { commonProjects, userInfo } = this.context;

    if (commonProjects) {
      this.megaMenuState.projects = commonProjects;
      this.megaMenuState.selectedProject =
        this.megaMenuState.projects.length > 0
          ? this.megaMenuState.projects.find(({ id }) => id === this.project)
          : undefined;
      this.requestUpdate();
      this.handleClickMegaMenuIcon();
    }

    if (userInfo?.settings?.defaultLanguage) {
      this.lang = userInfo.settings.defaultLanguage.split('-')[0];
    }
  }

  // Auto-scroll to the selected item.
  // eslint-disable-next-line class-methods-use-this
  handleClickMegaMenuIcon() {
    setTimeout(() => {
      // target the popover itself vs whatever is initially rendered WITHIN this component --> needs to work with new portal placement
      const popoverElem = document.querySelector('#megamenu-popover');

      if (popoverElem) {
        const selectedItems =
          popoverElem.querySelectorAll<MenuItemWC>('ps-menu-item');
        const selectedItem = Array.from(selectedItems).filter(
          (item) => item.selected === true
        );

        const scrollBarContainer = popoverElem.querySelector(
          '.c-mega-menu__scrollbar'
        ) as ScrollbarWC;

        const scrollableContainer =
          scrollBarContainer?.simplebar?.getScrollElement();

        if (selectedItem[0] && scrollableContainer) {
          scrollIntoView(
            selectedItem[0],
            scrollableContainer,
            'vertical',
            'auto'
          );
        }
      }
    }, 0);
  }

  handleManagerToolClick(route?: string) {
    if (!route || !this.onManagerToolClick) return;
    // To avoid bug that mega menu still open after clicking and during route reloading
    document
      .querySelector('ps-universal-nav')
      ?.shadowRoot?.querySelector('ps-mega-menu')
      ?.shadowRoot?.querySelector('#megamenu-trigger')
      ?.dispatchEvent(new Event('click', { bubbles: true }));
    this.onManagerToolClick(route);
  }

  renderTools() {
    return html`
      <ps-grid>
        ${map(
          this.megaMenuState.selectedProject?.availableProducts || [],
          (product) => html`
            <ps-grid-cell xsmall="6" medium="4">
              <ps-card
                test-id=${`mega-menu-product-cta-${product.label?.replace(' ', '-').toLowerCase()}`}
                size="large"
                interactive
                ?disabled=${product.disabled}
                .href=${product.name === ProductName.Organization &&
                this.onManagerToolClick
                  ? undefined
                  : product.url}
                @click=${product.name === ProductName.Organization &&
                this.onManagerToolClick
                  ? () => this.handleManagerToolClick(product.url)
                  : undefined}
              >
                <ps-stack
                  gutter="xsmall"
                  direction="column"
                  alignItems="center"
                >
                  <ps-image
                    src=${logos[product.logo]}
                    width="40px"
                    height="40px"
                  ></ps-image>
                  <ps-text-body size="small" truncate
                    >${product.label}</ps-text-body
                  >
                </ps-stack>
              </ps-card>
            </ps-grid-cell>
          `
        )}
        ${!this.megaMenuState.selectedProject
          ? html`
              <ps-grid-cell xsmall="12" medium="12">
                <ps-stack gutter="large" direction="column" alignItems="center">
                  <ps-image
                    src=${megaMenuEmptyStateIcon}
                    width="110px"
                    height="110px"
                  ></ps-image>
                  <ps-text
                    size="3xsmall"
                    variant="tertiary"
                    font-weight="medium"
                  >
                    ${i18n.t('navigation:mega.content.empty.part1') ||
                    'Select a'}
                    <span class="c-mega-menu__project-word-color"
                      >${i18n.t('navigation:mega.content.empty.part2') ||
                      'Project'}</span
                    >
                    ${i18n.t('navigation:mega.content.empty.part3') ||
                    'from the menu to get started'}
                  </ps-text>
                </ps-stack>
              </ps-grid-cell>
            `
          : nothing}
      </ps-grid>
    `;
  }

  handleProjectSelect(event: MouseEvent, project: CommonProject) {
    event.preventDefault();
    event.stopImmediatePropagation();

    const selectedProject =
      this.megaMenuState.selectedProject?.id === project.id
        ? undefined
        : project;

    this.megaMenuState.selectedProject = selectedProject;
    this.requestUpdate();
    this.onProjectSelect?.(selectedProject?.id);
  }

  renderProjects() {
    const { projects, selectedProject } = this.megaMenuState;

    if (projects.length) {
      return html`
        ${map(
          projects,
          (project) => html`
            <ps-menu-item
              ?selected=${selectedProject?.id === project.id}
              @click=${(event: MouseEvent) =>
                this.handleProjectSelect(event, project)}
            >
              ${project?.name}
            </ps-menu-item>
          `
        )}
      `;
    }

    return html`<ps-menu-item disabled
      >${i18n.t('navigation:mega.sidebar.empty') ||
      'No available projects'}</ps-menu-item
    >`;
  }

  // eslint-disable-next-line class-methods-use-this
  renderGoToHome() {
    if (this.hideGoHome) return nothing;

    return html`
      <ps-menu-item
        variant="ghost"
        @click=${() => handleGoHome(this.context?.accountId)}
        class="c-mega-menu__home-button"
      >
        ${i18n.t('navigation:mega.sidebar.backToHome') || 'Go Home'}
        <ps-icon name="arrow-left" slot="prefix"></ps-icon>
      </ps-menu-item>
    `;
  }

  startChat() {
    if (!this.chatLink) return;

    window.open(
      this.chatLink,
      '_blank',
      'width=600,height=400,right=0,bottom=0,toolbar=no,status=no,menubar=no,location=no'
    );
  }

  protected render() {
    return html`
      <ps-icon-button
        name="grid-menu"
        id="megamenu-trigger"
        @click=${this.handleClickMegaMenuIcon}
      ></ps-icon-button>
      <ps-popover
        id="megamenu-popover"
        trigger="megamenu-trigger"
        placement="bottom-start"
        width="auto"
      >
        <style>
          .c-mega-menu {
            width: 719px;
            max-width: calc(100vw - 82px);
          }

          .c-mega-menu__menu-header {
            --ps-theme-text-secondary-color: #8573e7;
          }

          .c-mega-menu__scrollbar-wrapper::after {
            content: '';
            position: absolute;
            display: block;
            left: -12px;
            right: -12px;
            bottom: 0;
            border-bottom: 1px solid #dfe1e5;
          }

          .c-mega-menu__project-word-color {
            color: #62697c;
          }

          .c-mega-menu__home-button {
            margin: 4px 12px 4px 0;
          }

          @media (min-width: 768px) {
            .c-mega-menu {
              padding: 16px 0;
            }

            .c-mega-menu__scrollbar-wrapper {
              margin-right: -12px;
              height: 100%;
              max-height: 300px;
              display: flex;
              flex-direction: column;
            }

            .c-mega-menu__menu {
              padding-right: 12px;
            }

            .c-mega-menu__scrollbar-wrapper::after {
              top: -28px;
              right: 0;
              left: auto;
              bottom: -28px;
              border-bottom: 0;
              border-right: 1px solid #dfe1e5;
            }

            .c-mega-menu__content {
              display: flex;
              flex-direction: column;
              height: 300px;
              overflow: scroll;
              padding-left: 28px;
              padding-right: 16px;
            }

            .c-mega-menu__helper {
              margin-top: auto;
            }

            .c-mega-menu__callout {
              padding-left: 0.75rem;
            }
          }
        </style>
        <div class="c-mega-menu">
          <ps-grid gutter="large" rowGutter="large" alignItems="stretch">
            <ps-grid-cell xsmall="12" medium="4">
              <div class="c-mega-menu__scrollbar-wrapper">
                <ps-scrollbar
                  force-visible
                  disable-auto-hide
                  class="c-mega-menu__scrollbar"
                >
                  <ps-menu class="c-mega-menu__menu">
                    <ps-text
                      class="c-mega-menu__menu-header"
                      size="3xsmall"
                      variant="secondary"
                      font-weight="medium"
                    >
                      ${i18n.t('navigation:mega.sidebar.title') || 'Projects'}
                    </ps-text>
                    ${this.renderProjects()}
                  </ps-menu>
                </ps-scrollbar>
                ${this.renderGoToHome()}
              </div>
            </ps-grid-cell>
            <ps-grid-cell xsmall="12" medium="8">
              <div class="c-mega-menu__content">
                <ps-text size="3xsmall" variant="tertiary" font-weight="medium">
                  ${i18n.t('navigation:mega.content.title') || 'Shortcuts'}
                </ps-text>
                <ps-spacer></ps-spacer>
                ${this.renderTools()}
                ${this.chatLink
                  ? html`
                      <div class="c-mega-menu__helper">
                        <ps-callout theme="light" size="small">
                          <div class="c-mega-menu__callout">
                            <ps-text
                              size="3xsmall"
                              variant="secondary"
                              font-weight="medium"
                            >
                              ${i18n.t('navigation:mega.content.help.part1') ||
                              'Need help with something? Contact a specialist'}
                              <a href="#" @click=${this.startChat}>
                                ${i18n.t(
                                  'navigation:mega.content.help.part2'
                                ) || 'here'} </a
                              >.
                            </ps-text>
                          </div>
                        </ps-callout>
                      </div>
                    `
                  : nothing}
              </div>
            </ps-grid-cell>
          </ps-grid>
        </div>
      </ps-popover>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-mega-menu': MegaMenuWC;
  }

  enum PSElementTagNameMap {
    'ps-mega-menu' = 'ps-mega-menu',
  }
}
